.share_img{
  width: 0.75vw;
  height: 0.75vw;
}


.share_menu_img{
  width: 1.0417vw;
  height: 1.0417vw;
  margin-right: 0.5208vw;
}

.mshare_menu_img{
  width: 4.8vw;
  height: 4.2667vw;
  margin-right: 0.5208vw;
}


.share_menu_name{
  font-size: 0.625vw;
font-family: Roboto;
font-weight: 400;
color: #808080;
}




.mshare_dropdown{
    width: 26.6667vw;
}

.mshare_menu{
  width: 40.6667vw;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.share_menu{
  height: 1.8229vw;
  width: 8.4375vw;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}



