.person_body {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    background-color: #F4F7F3;
  }



.person_menu{
    width: 18.75vw;
    height: 82.0313vw;
    background: #FFFFFF;
    box-shadow: 1px 0px 0px 0px rgba(201,218,196,0.52);
    padding-top: 1.4583vw;
    padding-left: 1.0417vw;
    padding-right: 1.0417vw;
}

.person_logo_img_div{
    display: flex;
    justify-content: center;


}
.person_name{
    display: flex;
    justify-content: center;
    font-size: 0.8333vw;
    font-family: Roboto;
    font-weight: 400;
    color: #222222;
    margin-top: 0.9375vw;
    margin-bottom: 15px;
}
.person_name_show{
    display: flex;
    justify-content: center;
    font-size: 0.8333vw;
    font-family: Roboto;
    font-weight: 400;
    color: #222222;
    margin-top: 0.9375vw;
    margin-bottom: .9167vw;
}


.person_menu_item{
    display: flex;
    align-items: center;
    padding-left:3.5937vw;
    height: 2.6563vw;
    border-bottom: 1px solid #E0E0E0;
    cursor: pointer;
}


.person_menu_item_acive{
    background-color: #F4F7F3;
}



.person_menu_item_name{
    font-size: 0.9375vw;
    font-family: Roboto;
    font-weight: 400;
    color: #BEBEBE;
    margin-left: 0.8854vw;
    }

 .person_menu_item_name_active{
    font-size: 0.9375vw;
    font-family: Roboto;
    font-weight: 400;
    color: #161515;
    margin-left: 0.8854vw;
    background-color: #F4F7F3;
    }



    .person_content{
        padding-top: 2.8125vw;
        padding-left: 2.0313vw;
    }

    .person_nft_badge_name{
        font-size: 1.25vw;
        font-family: Roboto;
        font-weight: bold;
        color: #222222;
        margin-bottom: 2.1354vw;
    }

.person_nft_badge{
    width: 64.5833vw;
    min-height: 28.6458vw;
    background: #FFFFFF;
    border-radius: 0.5208vw;
    padding-top: 2.1875vw;
    padding-left:2.0833vw;
    padding-bottom: 1.0417vw;
}



.person_nft_badge_img{
    width: 13.5417vw;
    border-radius: 0.5208vw;
    margin-right: 2.0833vw;
    margin-top: 1.0208vw;
}


.person_reading{
    width: 60.4167vw;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 0.5208vw;
    margin-top: 1.0417vw;
}


.person_donation{
    width: 64.5833vw;
    background: #FFFFFF;
    border-radius: 0.5208vw;
    padding-top: 2.1875vw;
    padding-left:2.0833vw;
    padding-bottom: 1.5625vw;
}

.person_view_but{
    width: 6.4583vw;
    height: 2.5vw;
    background: #F5F5F5;
    border-radius: .625rem;
    font-size: 0.9375vw;
    font-family: Roboto;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2.0833vw;
    cursor: pointer;
    color: #161515;
}


.person_reading_text{
    font-size: 1.25vw;
font-family: Roboto;
font-weight: bold;
margin-top: 1.0938vw;
margin-left: 1.0938vw;
}


.person_reading_eth{
    font-size: 0.9375vw;
font-family: Roboto;
font-weight: bold;
color: #222222;
margin-left: 1.0938vw;
margin-top: 1.1979vw;
}


.person_reading_list{
    height: 3.125vw;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.person_reading_item{
    height: 3.125vw;
    display: flex;
    width: 100%;
    border-top: 1px solid #E0E0E0  ;
    align-items: center;
}

.person_reading_item_1{
    width: 2.6042vw;
    font-size: 0.8333vw;
font-family: DIN;
font-weight: 500;
color: #000101;
display: flex;
justify-content: center;
}

.person_reading_item_img{
    width: 1.5875vw;
    height:1.5875vw;
    border-radius: 100%;
}

.person_reading_item_2{
 margin-left:0.6771vw ;
 width: 13.6979vw;
 font-size: 0.625vw;
font-family: Roboto;
font-weight: 400;
color: #222222;
}

.person_reading_item_3{
    width: 14.8229vw;
    font-size: 0.625vw;
    font-family: Roboto;
    font-weight: 400;
    color: #222222;
}

.person_reading_item_4{
    width:9.25vw;
    font-size: 0.625vw;
font-family: Roboto;
font-weight: 400;
color: #222222;
}

.person_reading_item_5{
    font-size: 0.625vw;
    font-family: Roboto;
    font-weight: 400;
    color: #0784c3;
    width: 10.4167vw;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    cursor: pointer;

}

.person_reading_item_6{
    width: 12.7708vw;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #52A553;
    display: flex;
    font-size: 1.5625vw;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    cursor: pointer;

}

.person_bio{
    font-size: 0.7292vw;
    font-family: Roboto;
    font-weight: 400;
    color: #222222;
    width: 17.083vw;
    height: 6.25vw;
    background: #F4F7F3;
    border-radius: 0.625vw;
    padding: 1.0417vw;
}


.person_nft_mint{
    width: 13.5417vw;
    height: 19.8438vw;
    border: 1px dashed #dad2d2 ;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}



.person_nft_mint_text{
    width: 8.6458vw;
    height: 2.0833vw;
    font-size: 0.8333vw;
    font-family: Roboto;
    font-weight: 400;
    color: #52A553;
    line-height: 1.25vw;text-align: center;
    margin-top: 7.5521vw;
}

.person_nft_box{
    display: flex;
    flex-wrap: wrap;
}

.person_nft_mint_but{
    width: 9.375vw;
height: 2.5vw;
background: #52A553;
border-radius: 0.5208vw;
display: flex;
align-items: center;
justify-content: center;
font-size: 0.9375vw;
font-family: Roboto;
font-weight: bold;
color: #FFFFFF;
margin-top: 3.0729vw;
}



.person_upload_file{
    width: 7.4479vw;
height: 1.7188vw;
background: #52A553;
border-radius: 0.3125vw;
font-size: 0.7292vw;
font-family: Roboto;
font-weight: 400;
color: #FFFFFF;
display: flex;
justify-content: center;
align-items: center;
margin-left: 1.1458vw;
cursor: pointer;
}


.person_from_item{
    display: flex;
    align-items: center;
}


.person_basic_from{
    width: 64.5833vw;
    background: #FFFFFF;
    border-radius: 0.5208vw;
    padding-top: 2.1875vw;
    padding-left:2.0833vw;
    padding-bottom: 1.875rem;
}


.person_basc_text{
    font-size: 0.625vw;
font-family: Roboto;
font-weight: 400;
color: #ADADAD;
margin-top: 0.5208vw;
}

.pserson_from_title{
    font-size: 0.8333vw;
font-family: Roboto;
font-weight: 400;
color: #222222;
margin-top: 1.5625vw;
}


.mperson_basc_text{
    font-size: 2.3vw;
font-family: Roboto;
font-weight: 400;
color: #ADADAD;
margin-top: 0.5208vw;
}


.mpserson_from_title{
    font-size: 3.2vw;
font-family: Roboto;
font-weight: 400;
color: #222222;
margin-top: 1.5625vw;
}

.person_from_item_but{
display: flex;
justify-content: flex-end;
width: 31.25vw;
margin-top:2.0313vw ;
}



.person_from_item_but_1{
    width: 12.5vw;
height: 2.5vw;
background: #52A553;
border-radius: 0.5208vw;
font-size: 1.25vw;
font-family: Roboto;
font-weight: bold;
color: #FFFFFF;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
}


.person_post_item{
    width: 23.9583vw;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 0.5208vw;
    padding-top: 0.7813vw;
    margin-right: 2.0833vw;
    margin-bottom: 2.0833vw;

}

.person_post_item_1{
    font-size: 0.9375vw;
font-family: Roboto;
font-weight: bold;
color: #222222;
margin-left: 1.0938vw;
}


.person_post_item_2{
    font-size: 0.7292vw;
font-family: Roboto;
font-weight: 400;
color: #4D4D4D;
margin-top: 1.0417vw;
margin-left: 1.0938vw;
}

.person_post_item_3{
    margin-top: 1.5104vw;
    border-top: 1px solid  #E0E0E0;
    display: flex;
    height: 2.2917vw;
    padding-left: 1.0938vw;
    align-items: center;
    font-size: 0.9375vw;
font-family: Roboto;
font-weight: bold;
color: #222222;
}


.person_post_item_3_1{
    display: flex;
    align-items: center;
    height: 2.2917vw;
    width: 17.7083vw;
    border-right: 1px solid #E0E0E0 ;
}

.person_post_item_3_2{
    font-size: 1.25vw;
    width: 6.25vw;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #52A553;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}



.person_my_post{
    width: 64.5833vw;
    background: #FFFFFF;
    border-radius: 0.5208vw;
    padding-top: 2.1875vw;
    padding-left:2.0833vw;
    padding-bottom: 2.6042vw ;
}


.person_post_list{
    display: flex;
    flex-wrap: wrap;
}


.person_people{
    width: 64.5833vw;
    background: #FFFFFF;
    border-radius: 0.5208vw;
    padding-top: 2.1875vw;
    padding-left:2.0833vw;
    padding-right: 1.9792vw;
    padding-bottom: 1.5625vw;
}


.person_people_img{
    width: 1.2344vw;
    height: 1.2344vw;
    margin-right: 0.9375vw;
}

.person_people_Social{
    display: flex;
    font-size: 0.8333vw;
    font-family: Roboto;
    font-weight: 400;
    color: #222222;
    margin-top: 0.8333vw;
    cursor: pointer;
}

.person_people_text{
    font-size: 0.7292vw;
font-family: Roboto;
font-weight: 400;
color: #808080;
display: flex;
align-items: center;

}

.person_people_name{
    font-size: 0.9375vw;
    font-family: Roboto;
    font-weight: bold;
    color: #222222;
    display: flex;
    align-items: center;
}

.person_people_box{
    display: flex;
    margin-top: 1.8229vw;
    width: 100%;
    height: 7.1354vw;
    border-bottom: 1px dashed #dad2d2 ;
}


.person_Projects_box{
    display: flex;
    align-items: center;
    width: 100%;
    height: 7.1354vw;
    border-bottom: 1px dashed #dad2d2 ;
}


.person_projects{
    width: 64.5833vw;
    background: #FFFFFF;
    border-radius: 0.5208vw;
    padding-top: 2.1875vw;
    padding-left:2.0833vw;
    padding-right: 1.9792vw;
    padding-bottom: 1.5625vw;
}





.person_people_logo{
    width: 2.5vw;
    height: 2.5vw;
    margin-right: 0.5208vw;
    border-radius: 100%;
}


.person_people_badge_name{
    font-size: 1.25vw;
    font-family: Roboto;
    font-weight: bold;
    color: #222222;
}

.person_people_follow{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8333vw;
    font-family: Roboto;
    font-weight: 500;
    color: #222222;width: 5.1042vw;
    height: 1.4583vw;
    background: #F5F5F5;
    border-radius: 0.2083vw;
    cursor: pointer;

}


.person_people_Unfollow{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8333vw;
    font-family: Roboto;
    font-weight: 500;
    color: #fff;width: 5.1042vw;
    height: 1.4583vw;
    background: #cc4848;
    border-radius: 0.2083vw;
    cursor: pointer;

}


.person_people_text_1{
    width: 21.7188vw
}

.person_people_text_2{
margin-left:11.4583vw;
font-size: 0.9375vw;
font-family: Roboto;
font-weight: bold;
color: #222222;
}

.person_people_name_2{
    font-size: 1.25vw;
font-family: Adobe Heiti Std;
font-weight: normal;
color: #52A553;
margin-left: 2.9688vw;
}


.min_step{
width: 180px;
height: 0.5208vw;
background: #F5F5F5;
border-radius: 5px;
margin-left: 10px;
}

.min_step_1{
    height: 0.5208vw;
    background: #52A553;
    border-radius: 0.2604vw;
}


.person_sign_but{
    width: 3.6458vw;
    height: 2.5vw;
    background: #52A553;
    border-radius: 0.5208vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 0.8333vw;
    font-family: Roboto;
    font-weight: bold;
    color: #FFFFFF;
    margin-left: 1vw;
}


.cpersion_box{
    padding-right: 3.2vw;
    padding-left: 3.2vw;
    padding-top: 5.3333vw;
}



.cperson_edit{
    width: 28.2667vw;
    height: 8.5333vw;
    background: #FAFDFD;
    border: 2px solid #CFE5E4;
    border-radius: 3.2vw;
    display: flex;
    align-items: center;
    justify-content: center;
}


.cperson_name{
    font-family: Roboto;
    font-weight: 400;
    color: #222222;
    margin-left:2.6667vw
}

.cperson_logo_img{
    width: 16.9333vw;
    height: 17.0667vw;
    background: #1A1A1A;
    border-radius: 8.5333vw;
}

.cpersion_boi{
    width: 93.6vw;
    height: 17.3333vw;
    background: #FFFFFF;
    border: 2px solid #CFE5E4;
    border-radius: 1.6vw;
    margin-top: 4.2667vw;
}



.mperson_projects{
    width: 100%;
    padding-left: 2vw;
    padding-right: 2vw;
}

.cperson_people_name{
    font-size: 4vw;
font-family: Roboto;
font-weight: bold;
color: #222222;
}
.cperson_people_text{
    font-size: 2.9333vw;
    font-family: Roboto;
    font-weight: 400;
    color: #4D4D4D;
    margin-top: 3.3333vw;
}

.cperson_people_step{
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 2.6667vw;
}

.cmin_step{
    width: 42.6667vw;
    height: 1.3333vw;
    background: #E0E3E0;
    border-radius: 0.6667vw;
    margin-left: 2.6667vw;
}



.cperson_Projects_box{
    width: 100%;
    border-bottom: 1px dashed #dad2d2 ;
    padding-bottom: 2.6667vw;
}


.cperson_people_text_2{
    font-size: 3.7333vw;
font-family: Roboto;
font-weight: bold;
color: #222222;
    }



    .cperson_projects{
        width:100%;
        background: #FFFFFF;
        border-radius: 0.5208vw;
    }


    #person[mode="web"] .dis_tab{
        width:100%;
        display: flex;
      }
      #person[mode="web"] .disflex{
        display: flex;
        width: 100%;
      }

      #person[mode="web"]  .dis_status_active{
        font-size: 16px;
      font-family: Roboto;
      font-weight: 400;
      text-decoration: underline;
      color: #222222 !important;
      cursor: pointer;
      }


      #person[mode="web"] .dis_status{
        font-size: 16px;
        font-family: Roboto;
        font-weight: 400;
        color: #ADADAD;
      }


      .followBut{
        width: 100%;
        padding: 1.25vw;
        display: flex;
        align-items: center;
      }
      .followsel{
        width: 28.2667vw;
        height: 8.5333vw;
        border-radius: 3.2vw;
        background: #279D8E;
        border: 1px solid #279D8E;
        font-size: 4vw;
        font-family: Roboto;
        font-weight: bold;
        color: #FFFFFF;
        margin-right: 4.5333vw;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .followNoSel{
        width: 28.2667vw;
        height: 8.5333vw;
        border-radius: 3.2vw;
        border: 0.0521vw solid #CFE5E4;
        font-size: 4vw;
        font-family: Roboto;
        font-weight: bold;
        margin-right: 4.5333vw;
        color: #808080;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .followList{
        padding: 0 2.1333vw;
      }
      .followList_item{
        width: 100%;
        padding: 4vw 0;
        border-bottom: 0.1333vw dashed #F5F5F5;
        display: flex;
        justify-content: space-between;
      }
      .followList_item_imgAndContent{
        display: flex;
      }
      .followList_item_center{
        flex: 1;
        margin-left: 2.4vw;
        margin-right: 8.5333vw;
      }
      .followList_item_img{
        width: 8.2667vw;
        height: 8.2667vw;
        background-color: #0784c3;
        border-radius: 8.2667vw;
      }
      .followList_item_title{
        font-size: 4vw;
        font-family: Roboto;
        font-weight: bold;
        color: #222222;
      }
      .followList_item_content{
        font-size: 2.9333vw;
        font-family: Roboto;
        font-weight: 400;
        color: #808080;
      }
      .followList_item_but{
        height: 4.8vw;
        background: #279D8E;
        padding: 0 1.3333vw;
        opacity: 0.2;
        border-radius: 0.5333vw;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3.4667vw;
        color: #fff;
      }



      
      .followList_item_but_OK{
        height: 4.8vw;
        background: #279D8E;
        padding: 0 1.3333vw;
        border-radius: 0.5333vw;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3.4667vw;
        color: #fff;
      }


.mmy_post_project_but{
    width: 28.2667vw;
    height: 8.5333vw;
    border: 2px solid #CFE5E4;
    border-radius: 3.2vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4vw;
    font-family: Roboto;
    font-weight: bold;
    color: #808080;
  }
  
  .mmy_post_project_but_active{
    width: 28.2667vw;
    height: 8.5333vw;
    border: 2px solid #CFE5E4;
    border-radius: 3.2vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4vw;
    font-family: Roboto;
    font-weight: bold;
    background: #279D8E;
    color: #FFFFFF;
  }
  

  .no_list{
    width: 32.2396vw;
    height: 12.8646vw;
    background: #FFFFFF;
    border: 2px solid #EBEBEB;
    border-radius: 0.625vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25vw;
    font-family: Roboto;
    font-weight: 400;
    color: #4D4D4D;
    }

    .mno_list{
        width: 100%;
        height: 26.6667vw;
        background: #FFFFFF;
        border: 2px solid #EBEBEB;
        border-radius: 0.625vw;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 3.2vw;
        font-family: Roboto;
        font-weight: 400;
        color: #4D4D4D;
        margin-top: 2.6667vw;
    }


    .persion_edit_profile{
        width: 98px;
        height: 28px;
        background: #F5F5F5;
        border-radius: 4px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
    }


    .person_but{
        width: 100%;
        justify-content: center;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        cursor: pointer;
    }
    
    .persion_share_but{
        width: 5.1042vw;
        height: 1.4583vw;
        font-size: 0.8333vw;
        background: #F5F5F5;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        cursor: pointer;
    }



.share_modal{
    background: #FFFFFF;
    border-radius: 24px;
    padding-top: 59px ;
    display: flex;
    justify-content: center; 
    flex-wrap: wrap;
}

.share_but{
    margin-top: 30px;
    width: 320px;
height: 48px;
background: #279D8E;
border-radius: 16px;
font-size: 20px;
font-family: Roboto;
font-weight: 400;
color: #FFFFFF;
line-height: 60px;
cursor: pointer;
display: flex;
justify-content: center;
align-items:center;

}