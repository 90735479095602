#home-page[mode=web] .home-content {
    width: 100%;
    display: flex;
    background: linear-gradient(-45deg, #E8F4F2 0%, #DFEBD9 100%);
  }

  #home-page[mode=web] .home-bg {
    background-image: url("../../assets/impactopia/bg.png");
    background-size:100% 100%;  
    width: 550px;
    height: 470px;
    margin-top: 186px;
  }


  #home-page[mode=web] .home-bg-center {
    background-image: url("../../assets/impactopia/big_center.png");
    background-size:100% 100%;  
    width: 71px;
    height: 811.5px;
    margin-left: 19px;
  }


  #home-page[mode=web] a {
    color: #2E2E2E;
    }


    .home_bg_project{
      display: flex;
      justify-content: flex-end;
      margin-top: 3.9063vw;
    }

    
  #home-page[mode=web] .home-bg-left_img {
    width: 496px;
  }


  #home-page[mode=web] .home-bg-left {
    /* background-image: url("../../assets/impactopia/card.png");
    background-size:100% 100%;   */
    width: 496px;
    height: 811px;
    display: flex;
    align-items: center;
    margin-left: 200px;
  }


  #home-page[mode=web] .home-bg-left-text{
    margin-top: 20px;
    width: 379px;
    margin-left: 215px;
    opacity: 0.89;
    font-size: 16px;
    font-family: DIN;
    cursor: pointer;
    color: #2E2E2E;
    display: flex;
    justify-items: center;
    flex-wrap: wrap;
    line-height: 30px;
  }

  #home-page[mode=web] .home-bg-flex{
    display: flex;
    justify-items: center;
    width: 100%;
    align-items: center;
  }
 
  #home-page[mode=web] .marg-left-20{
    margin-left: 50px; 
  }

  

  #home-page[mode=web] .home-bg-left-m{
    display: flex;
   justify-content: center;
   color: #fff;
    width: 56px;
    height: 22px;
    background: #419F41;
    align-items: center;
    margin-right: 5px;
  }


  #home-page[mode=web] .home-bg-left-c{
    display: flex;
   justify-content: center;
   color: #fff;
    width: 56px;
    height: 22px;
    background: #419F41;
    align-items: center;
    margin-left: 5px;
  }

  
  

  #home-page[mode=web] .home-flex {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  #home-page[mode=web] .home-soon {
    width: 100%;
    font-size: 17px;
    font-family: DIN;
    font-weight: 300;
    margin-top: 245px;
    color: #47B247;
    margin-left: 163px;
  }





  




  
 


  



  #home-page[mode=mobile] .home-content {
    width: 100%;
  }


  #home-page[mode=mobile] .mbg {
    width: 100%;
    display: flex;
    justify-content: center;
    background: #FFFFFF;
  }



  #home-page[mode=mobile] .mbg-img {
    background-image: url("../../assets/impactopia/mcard.png");
    background-size:100% 100%;  
    width: 174px;
    height: 207px;
    margin-top: 30px;
  }

  #home-page[mode=mobile] .mbg-one {
    width: 310px;
    height: 54px;
    background: #FFFFFF;
    border: 1px solid #DFEBD9;
    border-radius: 5px;
    margin-top: 4px;
    margin-bottom: 15px;
  }

  #home-page[mode=mobile] .mbg-two {
    width: 310px;
    height: 54px;
    background: #FFFFFF;
    border: 1px solid #DFEBD9;
    border-radius: 5px;
    display: flex;
  }
  #home-page[mode=mobile] .mbg-two-left {
    width: 248px;
    height: 38px;
    font-size: 12px;
    font-family: DIN;
    font-weight: 500;
    color: #1A1A1A;
    line-height: 19px;
    margin-left: 10px;
    margin-top: 9px;
    margin-bottom: 8px;
  }

  #home-page[mode=mobile] .mbg-two-left-item {
    width: 248px;
    height: 19px;
  }

  #home-page[mode=mobile] .mbg-two-left-green-one {
    width: 35px;
    height: 4px;
    background: #419F41;
    border-radius: 2px;
    position: absolute;
    margin-top: 12px;
  }

  #home-page[mode=mobile] .mbg-two-left-green-two {
    width: 32px;
    height: 4px;
    background: #419F41;
    border-radius: 2px;
    position: absolute;
    margin-top: 12px;
  }

  #home-page[mode=mobile] .mbg-two-right {
    width: 36px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #DFEBD9;
    border-radius: 5px;
    display: flex;
    margin-top: 9px;
  }

  #home-page[mode=mobile] .mbg-two-right-arrow {
    width: 22px;
    height: 10px;
    font-size: 24px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #419F41;
    line-height: 24px;
    margin-top: 6px;
    margin-left: 4px;
  }

  #home-page[mode=mobile] .mbg-span-break {
    white-space: nowrap; 
    display: inline-block;
    position: absolute;
  }


  #home-page[mode=mobile] .mmbg-descrbe {
    width:100%;
    background: linear-gradient(-45deg, #C4CFD2 0%, #C4CFD2 100%);
    opacity: 0.9;
    padding-bottom: 20px;
  }

  #home-page[mode=mobile] .mmbg-descrbe-title {
    width: 91px;
    height: 26px;
    font-size: 24px;
    font-family: Impact;
    font-weight: 400;
    text-decoration: underline;
    color: #1A1A1A;
    margin-left: 12px;
    margin-top: 14px;
  }

  #home-page[mode=mobile] .mmbg-descrbe-text1 {
    width: 97%;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 400;
    color: #3F4140;
    line-height: 24px;
    margin-top: 20px;
    margin-left: 13px;
    margin-right: 13px;
  }

  #home-page[mode=mobile] .mmbg-descrbe-text2 {
    width: 160px;
    height: 1px;
    border: 1px dashed #3F4140;
    border-radius: 1px;
    margin-left: 13px;
    margin-top: 20px;
  }

  #home-page[mode=mobile] .mmbg-descrbe-text3 {
    width: 348px;
    height: 111px;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 400;
    color: #3F4140;
    line-height: 24px;
    margin-left: 13px;
    margin-top: 13px;
  }

  #home-page[mode=mobile] .mmbg-descrbe2 {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 5px 10px 0px rgba(201,218,196,0.77);
    margin-top: 15px;
  }

  #home-page[mode=mobile] .mmbg-descrbe2-title {
    width: 150px;
    height: 44px;
    background: #1F1F1F;
    border: 2px solid #000000;
    border-radius: 8px;
    margin-top: 25px;
  }

  #home-page[mode=mobile] .mmbg-descrbe2-title-text {
    width: 130px;
    height: 25px;
    font-size: 23px;
    font-family: Impact;
    font-weight: 400;
    text-decoration: underline;
    color: #FFFFFF;
    margin-left: 11px;
  }

  #home-page[mode=mobile] .mmbg-descrbe2-content {
    width: 351px;
    height: 204px;
    background: #FFFFFF;
    box-shadow: 0px 2px 5px 0px rgba(201,218,196,0.77);
  }

  #home-page[mode=mobile] .mmbg-descrbe2-content-item {
    margin-top: 30px;
    display: flex;
  }

  #home-page[mode=mobile] .mmbg-descrbe2-content-dot {
    width: 10px;
    height: 10px;
    background: #419F41;
    margin-left: 11px;
    margin-top: 3px;
    margin-right: 9px;
    
  }

  #home-page[mode=mobile] .mmbg-descrbe2-content-text {
    width: 300px;
    height: 13px;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
    color: #1F1F1F;
    line-height: 18px;
  }

  #home-page[mode=mobile] .mmbg-descrbe3 {
    width: 343px;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 500;
    color: #3F4140;
    line-height: 30px;
    margin-top: 33px;
  }

  #home-page[mode=mobile] .mmbg-descrbe3-img {
    background-image: url("../../assets/mobile/sdgs-icons_sp@2x.png");
    background-size:100% 100%;  
    width: 323px;
    height: 720px;
    margin-top: 24px;
    margin-bottom: 20px;
  }

  #home-page[mode=mobile] .mmbg-descrbe4 {
    width: 100%;
    display: flex;
    justify-content: center;
    background: #f6f8f8
  }

  #home-page[mode=mobile] .mmbg-descrbe4-but {
    width: 155px;
    height: 44px;
    background: #1F1F1F;
    border: 2px solid #000000;
    border-radius: 8px;
    justify-items: center;
    margin-top: 21px;
  }

  #home-page[mode=mobile] .mmbg-descrbe4-img {
    background-image: url("../../assets/mobile/members@2x.png");
    background-size:100% 100%;  
    width: 100%;
    height: 145px;
    margin-top: 24px;
  }

  #home-page[mode=mobile] .mmbg-descrbe5 {
    width: 100%;
    display: flex;
    justify-content: center;
    background: linear-gradient(-45deg, #E8F4F2 0%, #DFEBD9 100%);
  }

  #home-page[mode=mobile] .mmbg-descrbe5-img {
    background-image: url("../../assets/mobile/road@2x.png");
    background-size:100% 100%;  
    width: 351px;
    height: 175px;
    margin-top: 30px;
    margin-bottom: 30px;
  }


  #home-page[mode=mobile] .mbg--botttom{
    width: 100%;
    height: 54px;
    background: linear-gradient(-45deg, #C4CFD2 0%, #C4CFD2 100%);
    opacity: 0.78;
    margin-top: 20px;
    display: flex;
  }

  #home-page[mode=mobile] .mbg--botttom-imp{
    width: 78px;
    height: 16px;
    font-size: 15px;
    font-family: Impact;
    font-weight: 400;
    text-decoration: underline;
    color: #647577;
    margin-top: 18px;
    margin-left: 16px;
  }
  
  #home-page[mode=mobile] .mbg--botttom-line{
    width: 1px;
    height: 42px;
    background: #718183;
    margin-top: 6px;
    margin-left: 28px;
  }
  
  
  #home-page[mode=mobile] .mbg--botttom-img-tg {
    background-image: url("../../assets/impactopia/tg.png");
    background-size:100% 100%;  
    width: 35px;
    height: 35px;
    margin-top: 12px;
    margin-left: 25px;
  }
  
  #home-page[mode=mobile] .mbg--botttom-img-discode {
    background-image: url("../../assets/impactopia/discode.png");
    background-size:100% 100%;  
    width: 35px;
    height: 35px;
    margin-top: 12px;
    margin-left: 25px;
  }
  
  #home-page[mode=mobile] .mbg--botttom-img-mail {
    background-image: url("../../assets/impactopia/mail.png");
    background-size:100% 100%;  
    width: 35px;
    height: 35px;
    margin-top: 12px;
    margin-left: 25px;
  }
  


  #home-page[mode=mobile] .top_box {
    background-image: url("../../assets/mobile/home_top.png");
    background-size:100% 100%;  
    width: 100%;
    height: 100vw;
  }

  #home-page[mode=mobile] .top_box_1 {
    padding-top: 34.6667vw;
    padding-left: 3.4667vw;
    font-size: 3.7333vw;
    font-family: Roboto;
    font-weight: 400;
    color: #202020;
    width: 76.9333vw;
  }


  #home-page[mode=mobile] .top_box_img {
   width: 4.8vw;
   height:5.4667vw;
  }

  #home-page[mode=mobile] .top_box_img_rt {
    width: 6vw;
    height:3.7867vw;
   }


   .top_box_item{
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    padding-left: 6.4vw;
    padding-right: 6.4vw;
    width: 93.6vw;
    height: 11.7333vw;
    background: linear-gradient(2deg, #F7FCFC 0%, #F2FBFA 100%);
    box-shadow: 0px 2px 4px 0px rgba(162,201,201,0.71);
    border-radius: 3.2vw;
    margin-bottom: 1.3333vw;

   }
 
   .top_box_item_name{
    font-size: 4.2667vw;
    font-family: Roboto;
    font-weight: 500;
    text-decoration: underline;
    color: #000101;
    width: 50.2667vw;
   }

.top_box_banner{
  width: 35.4167vw;
  height:20.9375vw ; 
  object-fit: cover;
}
.ctop_box{
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 3.5417vw;
  background: linear-gradient(0deg, #CBEDEA 0%, #FFFFFF 100%);
 
}
.ctop_box_left{
  width: 52.6vw;
  padding-left: 6.6667vw;
  padding-right: 8.4896vw;
}

.ctop_box_right{
  width: 47.4vw;
}


.ctop_box_left_platform{
  font-size: 3.75vw;
  font-family: Roboto;
  font-weight: 500;
  color: #000101;
}

.ctop_box_left_make{
  font-size: 1.2625vw;
  font-family: Roboto;
  color: #202020;
}

.ctop_box_bottom{
  width: 100%;
  height:5.625vw ;
  display: flex;
  align-items: center;
  border-top: 1px solid #AFCCCB;
}


.ctop_box_item{
  width: 31.1979vw;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


.ctop_box_item_x{
   border-right: 1px solid  rgba(0, 0, 0, 0.3);
   border-left: 1px solid  rgba(0, 0, 0, 0.3);
}



.ctop_box_img{
  width: 1.3021vw;
  height: 1.4583vw;
}

.ctop_box_item_name{
  font-size: 1.25vw;
  font-family: Roboto;
  font-weight: 500;
  text-decoration: underline;
  color: #000101;
  margin-right: 0.7813vw;
  margin-left: 0.7813vw;

}

.ctop_box_img_rt{
  width: 1.4063vw;
  height: 0.8854vw;
}


.dis_home_box{
  height: 36.8229vw ;
  width: 100%;
  cursor: pointer;
  padding: 2.3438vw 0px 1.8229vw 6.3542vw;
}


.dis_home_box_img{
  width: 100%;
  height:34.4792vw;
  object-fit: fill;
}

.project_home_box_img{
  width: 90%;
  height:43.75vw;
  object-fit: fill;
}



.project_home_box{
  cursor: pointer;
  height:43.75vw;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5208vw;
}


.channel_home_box{
  height:44.4792vw;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5208vw;
  cursor: pointer;
}

.channel_home_box_img{
  width: 85.8854vw;
  height:42.5vw ;
}



.m_dis_top{
  width: 87.2vw;
  height: 27.2vw;
}

.m_dis_content{
  width: 93.6vw;
  height: 118.6667vw;
}

.project_content{
  width: 100%;
  height:198.4vw ;
}

.channel_content{
  width: 100%;
  height:222.4vw;
}