.mproject_bg{
	width: 100vw;
	height:50.9333vw ;
  }
  
  
  .mproject_box{
	width: 100%;
	display: flex;
	padding-left: 3.3vw;
	padding-right: 3.3vw;
	flex-wrap: wrap;
	justify-content: space-between;
  }
  
  .mproject_box_item{
	width: 45.3333vw;
	height: 46.4vw;
	background: #FFFFFF;
	box-shadow: 0px 4px 6px 0px rgba(207,209,208,0.35);
	border-radius: 1.3333vw;
	margin-bottom: 1.3333vw;
  }
  
  .mproject_box_item_name{
	font-size: 4vw;
	font-family: Roboto;
	font-weight: bold;
	color: #222222;
  }
  
  .mproject_box_item_num{
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
	color: black;
	font-size: 2.9333vw;
	font-family: Roboto;
	font-weight: bold;
	color: #222222;
  }
  
  .mproject_box_item_step{
	width: 42.6667vw;
  height: 1.3333vw;
  background: #F5F5F5;
  border-radius: 0.6667vw;
  padding-left: 2vw;
  }
  
  .mproject_box_item_step_bule{
	height: 1.3333vw;
	background: #52A553;
	border-radius: 0.6667vw;
  }
  
  .mchannel_bg{
	width: 100%;
	height: 70.6667vw;
  }
  
  
  
  .mchannel_box_item{
	width: 100%;
	height: 17.0667vw;
	background: #FFFFFF;
	box-shadow: 0px 4px 6px 0px rgba(207,209,208,0.35);
	border-radius: 1.3333vw;
	display: flex;
  }
  
  
  .mchannel_box_item_img{
	width: 11.7333vw !important;
  height: 11.7333vw !important;
  background: #52A553;
  border: 1px solid #EBEBEB;
  border-radius: 5.8667vw;
  object-fit: contain;
  }
  
  .mchannel_member_img{
	width: 4.9333vw;
  height: 4.9333vw;
  }
  
  .mchannel_box_item_1{
	width: 85%;
	display: flex;
	height: 5.3333vw;
	margin-left: 2.6667vw;
  }
  .mmchannel_name{
	width: 90%;
	padding-right: 10px;
  }
  