
#discover_list[mode="web"] {
  width: 100%;
  display: flex;
  background: #FFFFFF;
  padding: 0px 0px 1.5625vw 0px ;
}



#discover_list[mode="web"] .disContent {
  display: flex;
}

#discover_list[mode="web"] .disTrending{
  border-right: 0.0521vw solid #DFEBD9;
  min-height: 59.5833vw;
  width: 33.3333vw ;
  padding-left: 9.4271vw;
  padding-top: 1.5625vw
}



#discover_list[mode="web"] .disTitle{
  font-size: 2.5vw;
font-family: Impact;
font-weight: 400;
text-decoration: underline;
color: #222222;
}

#discover_list[mode="web"] .dis_tab{
  width:"50%";
  display: flex;
  align-items:flex-end
}
#discover_list[mode="web"] .disflex{
  display: flex;
  width: 100%;
}

#discover_list[mode="web"] .disflex-left{
  width: 50%;
  font-size: 0.8333vw;
  font-family: DIN;
  font-weight: 300;
  color: #3F4140;
  margin-left: 5.2083vw;
}

#discover_list[mode="web"] .disText{
  max-width: 31.7708vw;
  font-size: 0.9375vw;
  font-family: Roboto;
  font-weight: 400;
  color: #3F4140;
  margin-left: 3.9063vw;
}



#discover_list[mode="web"] .dis_status{
  font-size: 0.8333vw;
  font-family: Roboto;
  font-weight: 400;
  color: #ADADAD;
  cursor: pointer;

}


#discover_list[mode="web"] .dis_status_active{
  font-size: 1.875vw;
  font-family: Roboto;
  font-weight: 900;
  text-decoration: underline;
  color: #222222;
}



#discover_list[mode="web"] .dis_list_one{
  width: 50%;
  margin-top: 2.0833vw;
}


#discover_list[mode="web"] .dis_but{
  background: #419D41;
  border: 1px solid #54A554;
  border-radius: 0.2083vw;
  width: 1.7708vw;
  height: 0.8333vw;
  font-size: 0.625vw;
  font-family: Roboto;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

#discover_list[mode="web"] .dis_but_y{
  background: #D79A2E;
  border: 1px solid #D79A2E;
  border-radius: 0.2083vw;
  width: 1.7708vw;
  height: 0.8333vw;
  font-size: 0.625vw;
  font-family: Roboto;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.3125vw;
}


 .dis_time{
  font-size: 0.625vw;
font-family: DIN;
font-weight: 400;
color: #B3B3B3;
margin-left: 1.0417vw;
width: 8.4375vw;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
}

#discover_list[mode="web"] .dis_list_name{
  font-size: 1.25vw;
  font-family: Roboto;
  font-weight: bold;
  color: #222222 !important;
  width: 49.8438vw;

}


 .dis_list_content{
  font-size: 1.05vw;
  font-family: Roboto;
  font-weight: 300;
  color: #595959;
  width: 49.8438vw;
  min-height: 3.1667vw;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}


#discover_list[mode="web"] .dis_sc{
  cursor: pointer;
  width: 8.4062vw;
  height: 1.4583vw;
  padding-left: 0.2604vw;
  padding-right: 0.2604vw;
  background: rgba(240, 240, 240, .3);
  border-radius: 0.2083vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.7292vw;
  font-family: DIN;
  font-weight: 400;
  color: #292929;
}

#discover_list[mode="web"] .dis_Reading{
  font-family: Roboto;
font-weight: 400;
text-decoration: underline;
color: #419D41;
}


 .dis_sc_1{
  font-size: 0.7292vw;
  font-family: DIN;
  font-weight: 400;
  color: #292929;
  margin-left: 0.3208vw;
}



#discover_list[mode="web"] .dis_tren_title{
  font-size: 1.1458vw;
font-family: Roboto;
font-weight: 400;
color: #222222;
}

#discover_list[mode="web"] .dis_tren_text{
  font-size: 0.5208vw;
font-family: Roboto;
font-weight: 300;
color: #595959;
}


#discover_list[mode="web"] .disForum{
  width: 66.6146vw;
  padding-left: 3.0729vw ;
  padding-right: 13.6458vw;
}


#discover_list[mode="web"] .dis_list_item{
  min-height: 10.2083vw;
  width: 100%;
  border-bottom:  0.0521vw solid #DFEBD9;
  padding-top: 1.0938vw;
  padding-bottom: 0.5208vw;

}


#discover_list[mode="web"]  .options_box{
  width: 8.0729vw;
  height: 1.4583vw;
  background: rgba(240, 240, 240,0.5);
  border-radius: 0.2083vw;
  display: flex;
  align-items: center;
  justify-content: space-between;

}


#discover_list[mode="web"]  .options_box_flex{
  cursor: pointer;
  width: 8.0729vw;
  height: 1.0583vw;
  align-items: center;
  display: flex;
  align-items: center;
  color: rgb(107, 104, 104);
  font-size: 0.7292vw;
  font-family: DIN;
  justify-content: center;
}

#discover_list[mode="web"] .options_box_num{
  margin-left: 0.204vw;

}


#discover_list[mode="web"] .options_box_flex_border{
  cursor: pointer;
  border-right: 1px solid rgba(240, 240, 240,1);
}





.mdiscover_list_box {
  background-image: url("../../assets/mobile/discover_list.png");
  background-size:100% 100%;
  width: 100%;
  height:67.7333vw;
}



.mch_tab{
  display: flex;
  padding-left: 4vw;
  align-items: flex-end;
}




.mch_title_tab{
  display: flex;
  align-items: flex-end;
  font-size: 4.8vw;
  font-family: Roboto;
  font-weight: 500;
  text-decoration: underline;
  color: #808080;
}

.mch_status_active{
  font-size: 4.8vw;
  font-family: Roboto;
  font-weight: 500;
  text-decoration: underline;
  color: #222222 !important;
 }



 .mdiscover_list_tag_box{
  width: 93.6vw;
height: 8.5333vw;
border: 2px solid #D1D1D1;
border-radius: 3.2vw;
}


.mdiscover_list_flex{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5.3333vw;
  margin-bottom: 4vw;
}

.mpost_forum_but{
  width: 11.7333vw;
  height: 11.7333vw;
  background: #279D8E;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 999;
  top: 120vw;
  right: 10px;
}
.mpost_forum_but_img{
  width: 5.3733vw;
  height: 5.3733vw;
}


.projectListContentTabs{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.moreIcon {
  margin: 0 5px;
  /* transform: translateY(-2px); */
  font-weight: 700;
}


.mdiscover_live_new{
  width: 50px;
  height: 50px;
  position: fixed;
  top: 600px;
  right: 10px;
  z-index: 999;
}
