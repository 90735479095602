.mdisContent{
width: 100%;
}

.mdis_list_item{
    width: 100%;
    min-height: 43.3333vw;
    background: #FFFFFF;
    border-radius: 3.2vw;
}

.mdis_list_name{
    width: 96%;
    font-size: 4vw;
    font-family: Roboto;
    font-weight: bold;
    color: #222222;  
}

.mdisflex{
    display: flex;
    align-items: center;
    width: 100%;
}

.mdis_list_content_show {
    display: flex;
    flex-wrap:wrap;
    margin-top: 1.3333vw;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 300;
    color: #595959;
    width:100%;
}


.mdis_list_content {
    display: flex;
    flex-wrap:wrap;
    margin-top: 1.3333vw;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 300;
    color: #595959;
    width:100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.mdis_list_img_bander{
    height: 20vw !important; 
    width: 42vw !important; 
    border-radius: 1.0417vw;
     object-fit: cover;
     margin-left: 1.3333vw;
     margin-top: 1.3333vw;
}


.mforum_but {
    border-radius: 0.6667vw;
    font-size: 2.9333vw;
    font-family: Roboto;
    font-weight: 400;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.6667vw;
    padding-left: 0.6667vw;
    padding-right: 0.6667vw;
}



.mcoptions_box{
    width: 100%;
    height: 13.3333vw;
    display:flex;
    padding-right: 3.6667vw;
    align-items: center;
    justify-content: space-between;
}

.mdis_list_img{
    width: 4.8vw;
    height: 4.2667vw;
}

.right_op{
    width: 1.5467vw;
    height: 6.16vw;
}


.moptions_name_box_flex{
    display: flex;
    align-items: center;
}



.mdis_list_popup{
width: 100%;
height: 48.8vw;
background: #FFFFFF;
border-radius: 3.2vw 3.2vw 0vw 0vw;
}


.mdis_list_popup_but{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15.3333vw;
    padding-right: 15.3333vw;
    padding-top: 12.6667vw;
}


.mdis_list_popup_but_img{
    width: 5.3733vw;
    height: 41.5px;
}


.mdis_list_popup_but_img_box{
    width: 16vw;
    height: 16vw;
    background: #F5F5F5;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mdis_list_popup_but_text{
    font-size: 4vw;
font-family: Roboto;
font-weight: 400;
color: #595959;
margin-top: 1.3333vw;
}


.mdis_time{
    font-size: 2.9333vw;
    font-family: DIN;
    font-weight: 400;  
}

.muser_name{
    font-size: 3.4667vw;
font-family: Roboto;
font-weight: 500;
color: #595959;
}