#donate[mode="web"] .donateUsContent{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}


.rc-virtual-list-holders{
  max-height: 31.25vw !important;
}

.donateUsSelect{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65%;
  height: 2.6042vw;
  border-radius:2.6042vw;
  border: 1px solid  gainsboro ;
  padding-left: 20px;
}

.donateOption{
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 0.6333vw;
}

.donateUsprofileUrl{
  width: 2.1667vw;
  height:2.1667vw;
  border-radius: 0.5208vw;
}


.donateUsDescription{
  width:42.0833vw;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}


.donateUsName{
  display: flex;
  font-size: 1.0417vw;
  font-weight: bold;
}

.dropdownStyle{
  height: 31.25vw;
}



.mdonateus_box{
  width: 100%;
  padding-top: 11.4667vw;
  padding-bottom: 5.6667vw;
}

.mdonateus_text{
  width:100%;
  height: 18.6667vw;
  font-size: 4.8vw;
  text-align: center;
  font-family: Roboto;
  font-weight: bold;
  color: #222222; 
}

.mdonateus_flex{
  width: 100%;
  justify-content: center;
  display: flex;
}

.mdonateus_flex_no{
  width: 100%;
  display: flex;
  padding-left: 3.3333vw;
}

.donateus_flex_no{
  width: 100%;
  display: flex;
  align-items: center;
}



.mdonateus_img{
  width: 35.3333vw;
  height: 35.3333vw;
}

.mdonateus_input_box{
  width: 90.6667vw;
height: 13.0667vw;
background: #FFFFFF;
border: 2px solid #F5F5F5;
box-shadow: 0px 6px 8px 0px rgba(207,209,208,0.35);
border-radius: 6.5333vw;
display: flex;
align-items: center;
padding-left: 3.3333vw;
}

.mdonateus_search_img{
  width: 7.4vw;
  height: 7.4vw;
}


.mdonateus_list{
  width: 100%;
  padding-left: 3.2vw;
  padding-right: 3.2vw;
}


.donateus_list{
  width: 100%;
  padding-left: 3.2vw;
  padding-right: 3.2vw;
  display: flex;
  flex-wrap: wrap;
}

.mdonateus_item{
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  box-shadow: 0px 0.6vw 0.8vw 0px rgba(207,209,208,0.99);
  border-radius: 1.3333vw;
  padding: 3.3333vw  3.3333vw 0vw  0vw;
  margin-top: 2.6667vw;

}



.mdonateUsprofileUrl{
  width: 6.4vw;
height: 6.4vw;
border-radius: 100%;
}

.mdonateUsName{
  font-size: 4vw;
  font-family: Roboto;
  font-weight: bold;
  color: #222222;
  margin-left: 2.6667vw;
}

.mdonateUsDescription{
  width: 70.9333vw;
  font-size: 3.2vw;
  font-family: Roboto;
  font-weight: 400;
  color: #808080;
  margin-top: 4vw;
  padding-left: 3.3333vw;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp:2;
  -webkit-box-orient: vertical;
}


.mdonateus_but{
  width: 17.6vw;
  height: 6.4vw;
  background: #279D8E;
  border-radius: 2.4vw;
  font-size: 3.2vw;
  font-family: Roboto;
  font-weight: 500;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.donate_search_img{
  width: 1.25vw;
  height: 1.25vw;
}


.donationUs{
  width: 38.0729vw;
  height: 6.7708vw;
}

.donateUsContentReading{
  font-size: 0.8333vw;
font-family: Roboto;
font-weight: bold;
color: #222222;
margin-top: 2.1875vw;
}



.donateus_item{
  width: 17.4479vw;
  height: 11.25vw;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  box-shadow: 0px 4px 6px 0px rgba(207,209,208,0.99);
  border-radius: 0.5208vw;
  padding: 0.5208vw 0.5208vw 0.5208vw 0.5208vw;
  margin-right: 0.5208vw;
  margin-top: 0.5208vw;
}


.donateUsprofileUrl{
  width: 2.5vw;
height: 2.4479vw;
border-radius: 100%;
object-fit: contain;
}

.donateUsName{
  font-size: 0.8333vw;
  font-family: Roboto;
  font-weight: bold;
  color: #222222;
}

.donateUsDescriptionlist{
  width: 16.0938vw;
  font-size: 0.625vw;
  font-family: Roboto;
  font-weight: 400;
  color: #808080;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space:normal;
  word-break:break-all;
  word-wrap:break-word; 
  height: 5.6292vw;
}

.donateus_but{
  width: 4.2708vw;
  height: 1.5625vw;
  background: #279D8E;
  border-radius: 0.3125vw;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}