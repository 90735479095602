#nft[mode=mobile] {
  width: 100%;
  display: flex;
  justify-content: center;
}

#nft[mode=mobile] .nft-list{
  width: 100%;
}

#nft[mode=mobile] .nft-mbg{
  width: 100%;
  background: #E0EDDC;
  border: 1px solid #C1E1C1;
}

#nft[mode=mobile] .nft-mbg2{
  width: 100%;
  display: flex;
  justify-content: center;
  background: #FFFFFF;
  height: 24px;
}

#nft[mode=mobile] .nft-mbg3{
  width: 100%;
  display: flex;
  justify-content: center;
  background: #FFFFFF;
}

#nft[mode=mobile] .nft-mbg-dis{
  width: 100%;
  display: flex;
  justify-content: center;
}


#nft[mode=mobile] .nft-mbg-img-gold {
  background-image: url("../../assets/mobile/card-gold@2x.png");
  background-size:100% 100%;  
  width: 351px;
  height: 510px;
  margin-top: 50px;
  margin-bottom: 16px;
}

#nft[mode=mobile] .nft-mbg-img-silver {
  background-image: url("../../assets/mobile/card-silver@2x.png");
  background-size:100% 100%;  
  width: 351px;
  height: 510px;
  margin-top: 30px;
  margin-bottom: 16px;
}

#nft[mode=mobile] .nft-mbg-img-bronze {
  background-image: url("../../assets/mobile/card-bron@2x.png");
  background-size:100% 100%;  
  width: 351px;
  height: 510px;
  margin-top: 30px;
  margin-bottom: 16px;
}

#nft[mode=mobile] .nft-mbg-img-other {
  background-image: url("../../assets/mobile/card-@2x.png");
  background-size:100% 100%;  
  width: 351px;
  height: 510px;
  margin-top: 30px;
  margin-bottom: 16px;
}

#nft[mode=mobile] .nft-mbg-title {
  font-size: 24px;
  font-family: Roboto;
  font-weight: 900;
  text-decoration: underline;
  color: #2C2C2C;
  margin-left: 12px;
  margin-top: 11px;
}

#nft[mode=mobile] .nft-mbg-text {
  width: 338px;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  color: #2B2F29;
  line-height: 24px;
  margin-left: 13px;
  margin-top: 8px;
}

#nft[mode=mobile] .nft-mint-bt {
  width: 351px;
  height: 44px;
  background: #CDE4C6;
  border: 1px solid #419F41;
  border-radius: 3px;
  margin-top: 30px;
  display: flex;
}

#nft[mode=mobile] .nft-mint-bt-img {
  background-image: url("../../assets/impactopia/plo.png");
  background-size:100% 100%;  
  width: 28px;
  height: 24px;
  margin-top: 10px;
  margin-left: 54px;
}

#nft[mode=mobile] .nft-mint-bt-text {
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  color: #419F41;
  line-height: 6px;
  margin-top: 18px;
  margin-left: 29px;
}

#nft[mode=mobile] .nft-mint-tw {
  width: 351px;
  height: 44px;
  background: #419F41;
  border: 1px solid #CDE4C6;
  border-radius: 3px;
  margin-top: 30px;
  display: flex;
  margin-bottom: 30px;
}

#nft[mode=mobile] .nft-mint-tw-img {
  background-image: url("../../assets/impactopia/tw-g.png");
  background-size:100% 100%;  
  width: 32px;
  height: 32px;
  margin-top: 6px;
  margin-left: 50px;
}

#nft[mode=mobile] .nft-mint-tw-text {
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 15px;
  margin-top: 15px;
  margin-left: 29px;
}


#nft[mode=mobile] .nft-bot{
  width: 100%;
  height: 54px;
  background: linear-gradient(-45deg, #C4CFD2 0%, #C4CFD2 100%);
  opacity: 0.78;
  margin-top: 20px;
  display: flex;
}

#nft[mode=mobile] .nft-bot-imp{
  width: 78px;
  height: 16px;
  font-size: 15px;
  font-family: Impact;
  font-weight: 400;
  text-decoration: underline;
  color: #647577;
  margin-top: 18px;
  margin-left: 16px;
}

#nft[mode=mobile] .nft-bot-line{
  width: 1px;
  height: 42px;
  background: #718183;
  margin-top: 6px;
  margin-left: 28px;
}


#nft[mode=mobile] .nft-bot-img-tg {
  background-image: url("../../assets/impactopia/tg.png");
  background-size:100% 100%;  
  width: 35px;
  height: 35px;
  margin-top: 12px;
  margin-left: 25px;
}

#nft[mode=mobile] .nft-bot-img-discode {
  background-image: url("../../assets/impactopia/discode.png");
  background-size:100% 100%;  
  width: 35px;
  height: 35px;
  margin-top: 12px;
  margin-left: 25px;
}

#nft[mode=mobile] .nft-bot-img-mail {
  background-image: url("../../assets/impactopia/mail.png");
  background-size:100% 100%;  
  width: 35px;
  height: 35px;
  margin-top: 12px;
  margin-left: 25px;
}





#nft[mode=mobile] .mynft-list{
  width: 100%;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

#nft[mode=mobile] .mynft-item{
  width: 100%;
  background: #E0EDDC;
  border: 1px solid #C1E1C1;
  display: flex;
  justify-content: space-between;
  padding-top: 36px;
  padding-bottom: 15px;
}


#nft[mode=mobile] .mynft-item-img-text{
   width: 49%;
   margin-left: 8px;
}

#nft[mode=mobile] .mynft-item-img-gold{
  background-image: url("../../assets/mobile/card-gold@3x.png");
  background-size:100% 100%;  
  width: 179px;
  height: 261px;
  justify-content: left;
  margin-left: 13px;
}

#nft[mode=mobile] .mynft-item-img-bronze{
  background-image: url("../../assets/mobile/card-bionze@3x.png");
  background-size:100% 100%;  
  width: 179px;
  height: 261px;
  justify-content: left;
  margin-left: 13px;
}

#nft[mode=mobile] .mynft-item-img-silver{
  background-image: url("../../assets/mobile/card-silver@3x.png");
  background-size:100% 100%;  
  width: 179px;
  height: 261px;
  justify-content: left;
  margin-left: 13px;
}

#nft[mode=mobile] .mynft-item-img-other{
  background-image: url("../../assets/mobile/card-@3x.png");
  background-size:100% 100%;  
  width: 179px;
  height: 261px;
  justify-content: left;
  margin-left: 13px;
}

#nft[mode=mobile] .mynft-item-title{
  width: 100%;
  font-size: 18px;
  font-family: Roboto;
  font-weight: 900;
  text-decoration: underline;
  color: #2C2C2C;
  line-height: 28px;
}

#nft[mode=mobile] .mynft-item-text{
  width: 80%;
  font-size: 12px;
  font-family: Roboto;
  font-weight: 400;
  color: #2B2F29;
  line-height: 18px;
  margin-top: 14px;
}

#nft[mode=mobile] .mynft-item-but{
  width: 161px;
  height: 44px;
  background: #419F41;
  border: 1px solid #CDE4C6;
  border-radius: 3px;
  margin-top: 62px;
  display: flex;

}

#nft[mode=mobile] .mynft-item-img-tw{
  background-image: url("../../assets/impactopia/tw-g.png");
  background-size:100% 100%;  
  width: 32px;
  height: 32px;
  margin-top: 6px;
  margin-left: 6px;
}

#nft[mode=mobile] .mynft-item-but-text{
  font-size: 15px;
  font-family: Roboto;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 15px;
  margin-top: 14px;
  margin-left: 3px;
}

#nft[mode=mobile] .card-black {
  background-image: url("../../assets/impactopia/card-black.png");
  background-size:100% 100%;  
  width: 240px;
  height: 211px;
  display: flex;justify-content:center;
  align-items: center;
  font-size: .75rem;
  font-family: Roboto;
  font-weight: 900;
  color: #B5BDB3;
}



#nft[mode=mobile] .card-black {
  background-image: url("../../assets/impactopia/card-black.png");
  background-size:100% 100%;  
  width: 240px;
  height: 211px;
  display: flex;justify-content:center;
  align-items: center;
  font-size: .75rem;
  font-family: Roboto;
  font-weight: 900;
  color: #B5BDB3;
}


#nft[mode=mobile] .nftTab_div {
   position: fixed;
   display: flex;
   width: 100%;
   justify-content: center;
}


#nft[mode=mobile] .nftTab {
  width: 351px;
  height: 60px;
  background: #FFFFFF;
  box-shadow: 0px 5px 10px 0px rgba(201,218,196,0.77);
  border-radius: 3px;
  display: flex;
  align-items: center;
}


#nft[mode=mobile] .title-bg {
  width: 50%;
  font-size: 18px;
  font-family: Roboto;
  font-weight: 500;
  color: #1A1A1A;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
 
}

#nft[mode=mobile] .img-cut{
  width: 20px;
  height: 20px;
}

/* #nft[mode=mobile] .nftTab_line {
  width: 1px;
  height: 51px;
  background: #D8E6D3;
  border-radius: 1px;
} */




#nft[mode=mobile] .title-bg-no {
  color: #DAE8D6 !important;
  width: 50%;
  font-size: 18px;
  font-family: Roboto;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#nft[mode=mobile] .nav-Popup{
  background: linear-gradient(-45deg, #E8F4F2 0%, #DFEBD9 100%);
  width: 100px;
}


.Popup_content{
  display: flex;
  justify-content: center;
  align-items: center;
 margin-top: 131px;
}

.Popup_nft-text-legend{
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
}

.Popup_nft-text-legend-one{
  font-size: 18px;
  font-family: Roboto;
  font-weight: bold;
  width: 100%;
  color: rgba(65, 159, 65, 1);
}

.Popup_nft-text-legend-three{
  font-size: 18px;
  width: 100%;
  color: rgba(65, 159, 65, 1);
  font-weight: bold;
}

.Popup_nft-mint-bt {
  width: 266px;
  height: 44px;
  background: #CDE4C6;
  border: 1px solid #419F41;
  border-radius: 3px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-weight: 400;
  color: #419F41;
  line-height: 6px;
  font-size: 16px;
}




.Popup_nft-text-legend-four{
  font-size: 18px;
  width: 100%;
}


.Popup_nft-text-legend-two{
  font-size: 18px;
  width: 100%;
}

.Popup_nft-text-legend-but{
  font-size: 18px;
  width: 100%;
}



 .Popup_nft-mint-bt-img {
  background-image: url("../../assets/impactopia/plo.png");
  background-size:100% 100%;  
  width: 28px;
  height: 24px;
  margin-right: 20px;
}

.Popup_mentuShare_td{
  text-align: center;
}








 