.share_img{
  width: 0.75vw;
  height: 0.75vw;
}



.share_menu_name{
  font-size: 0.625vw;
font-family: Roboto;
font-weight: 400;
color: #808080;
}

.share_menu{
  height: 1.8229vw;
  width: 8.4375vw;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.p_u_add_img{
  width: 0.7292vw;
  height: 0.7292vw;
  margin-right: .5417vw;
}


.p_user_detail_Follow{
  font-size: 0.8333vw;
  font-family: Roboto;
  font-weight: 500;
  color: #222222;
  width: 5.625vw;
  height: 1.5458vw;
  background: #F5F5F5;
  border-radius: 0.2083vw;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 2.0646vw;
}


.p_user_detail_unfollow{
  font-size: 0.8333vw;
  font-family: Roboto;
  font-weight: 500;
  color: #fff;
  width: 5.625vw;
  height: 1.5458vw;
  background: #cc4848;
  border-radius: 0.2083vw;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 2.0646vw;
}

.p_user_detail_chat{
  font-size: 0.8333vw;
  font-family: Roboto;
  font-weight: 500;
  color: #222222;
  width: 3.625vw;
  height: 1.5458vw;
  background: #279d8e;
  border-radius: 0.2083vw;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left:0.5646vw;
} 


.m_nft_badge_img{
  width: 10.4vw;
  height:10.4vw;margin-top: 1.3333vw;
}


.u_nft_badge_img{
  width: 4.25vw;
  height: 4.25vw;
  margin-right: 0.2604vw;
}

.p_user_name{
  font-size: 0.8333vw;
font-family: Roboto;
font-weight: bold;
}


.dis_cover_name {
  font-size: 16px;
  font-family: Roboto;
  font-weight: bold;
  color: #222222;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 15.2083vw;
}


/* .p_user_detail_2{
  height: 4.7396vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.7292vw;
 font-family: Roboto;
 font-weight: 400;
 color: #222222;
 cursor: pointer;
 padding: 0.2604vw;
 margin-top: 0.5208vw;
}
 */
