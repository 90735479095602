.live_centent{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 0.6944vw;
    flex-wrap: wrap;
    padding-bottom: 5.2083vw;
}

.live_iframe{
    width: 62.5vw;
    height: 35.4167vw;
}

.live_iframe_dis{
    width: 100%;
    height: 25.4167vw;
}


.live_flex{
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
}


.live_title{
    display: flex;
    width: 62.5vw;
    font-size: 1.25vw;
    font-family: Roboto;
    font-weight: 500;
}

.live_user_img{
    width: 2.1875vw;
    height: 2.1875vw;
    border-radius: 100%;
}

.live_user{
    display: flex;
    width: 62.5vw;height: 3.9063vw;
    align-items: center;
}

.live_detail{
    width: 62.5vw;
    background: #FAFAFA;
    border: 1px solid #EBEBEB;
    border-radius: 0.5208vw;
    padding: 0.9375vw;
}

.live_user_1{
    display: flex;

}

.live_user_1_1{
    display: flex;
    margin-top: 0.5208vw;
}

.live_user_name{
    font-size: 0.625vw;
    font-family: Roboto;
    font-weight: 400;
    color: #808080;
    /* width: 2.3438vw;
    height: 0.8333vw;
    background: #D1D1D1;
    border-radius: 0.3125vw;
    display: flex;
    align-items: center;
    justify-content: center; */
}

.live_user_1_tag{
    height: 0.8333vw;
    background: #D1D1D1;
    border-radius: 0.3125vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    color: #FFFFFF;
    padding-left: 0.7813vw;
    padding-right: 0.7813vw;
}
.live_user_1_num{
    font-size: 0.625vw;
font-family: Roboto;
font-weight: 400;
color: #212121;
margin-left: 1.0417vw;
}
.live_user_1_0{
    margin-left: 0.5729vw;
}

.member_logo{
    border-radius: 50%;
}

.live_comment_box{
    width: 62.5vw;
    background: #FFFFFF;
    border: 0.0521vw solid #E0E0E0;
    border-radius: 0.5208vw;
    padding-top: 1.3542vw;
    padding-left: 1.25vw;
    margin-top: 1.5625vw;
    padding-right: 1.25vw;
}

.live_comment_name{
    font-size: 1.875vw;
    font-family: Roboto;
    font-weight: 900;
    text-decoration: underline;
    color: #222222;
    margin-bottom: 1.0417vw;
}

.project_comment_input{
    width: 100%;
    height: 8.6458vw;
    background: #FAFAFA;
    border: 0.0521vw solid #E0E0E0;
    border-radius: 0.4167vw;
}




.mlive_centent{
    width: 100%;
}

.mlive_iframe{
    width: 100%;
    height: 53.3333vw;
}

.mlive_title{
    font-size: 4.2667vw;
    font-family: Roboto;
    font-weight: bold;
    color: #222222; 
    padding-left: 3.2vw;
}


.mlive_but{
    background: #D1D1D1;
    border-radius: 0.8vw;
    font-size: 2.0667vw;
    font-family: Roboto;
    font-weight: 500;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2.6667vw;
    padding: 2px 5px 2px  5px;
}


.mlive_tr{
    font-size: 22px;
    font-family: Roboto;
    font-weight: 400;
    color: #808080;
}

.mlive_row{
    display: flex;
    align-items: center;
    padding-left: 3.2vw;
}

.mlive_detail{
    width: 100%;
    min-height: 8.6667vw;
    font-size: 3.4667vw;
    font-family: Roboto;
    font-weight: 400;
    color: #D1D1D1;
    padding-left: 10px;
    padding-right: 10px;
}


.mlive_img{
    width: 4.8vw;
    height: 4.2667vw;
    margin-left: 4vw;
}