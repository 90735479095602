
#trendingcover_list[mode=web] .trendingTrending {
  display: flex;
}

.trendingContent{
  border-right: 1px solid #DFEBD9;
}

.trendingflex{
  display: flex;
  width: 100%;
}

.trending_status_active{
  font-size: 1.875vw;
  font-family: Roboto;
  font-weight: 900;
  text-decoration: underline;
  color: #222222;
}

 .trendingTitle{
  font-size: 48px;
font-family: Impact;
font-weight: 400;
text-decoration: underline;
color: #222222;
}

.trending_tren_title{
  font-size: 1.1458vw;
font-family: Roboto;
font-weight: 400;
color: #222222;
}







.trending_Reading{
  font-family: Roboto;
font-weight: 400;
text-decoration: underline;
color: #419D41;
}




.img_fit{
  object-fit: cover;
}


