.mperson_nft_box{
  width: 100%;
}


.mperson_nft_item{
  width: 100%;
  height: 115.8667vw;
  background: #FFFFFF;
  border: 2px solid #EBEBEB;
  box-shadow: 0px 2px 6px 0px rgba(246,248,248,0.19);
  border-radius: 3.2vw;
  margin-bottom: 2vw;
}

.mperson_nft_item_img{
  width: 100%;
  height: 78vw;

  object-fit: contain;
}

.mperson_nft_name{
  width: 100%;
  font-size: 4.8vw;
  font-family: Roboto;
  font-weight: bold;
  color: #222222;
  padding-left: 4.8vw;
  margin-top: 2.6667vw;
}

.mperson_nft_description{
  width: 100%;
  font-size: 3.4667vw;
  font-family: Roboto;
  font-weight: 400;
  color: #808080;
  padding-left: 4.8vw;
  margin-top: 2.6667vw;
}


.mperson_nft_tw{
  width: 6.4vw;
  height: 5.4667vw;
}

.mperson_nft_but{
  margin-top: 5.7333vw;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5.6vw;
  padding-right: 13.2vw;
}

.mperson_nft_project{
  width: 44.8vw;
height: 8.5333vw;
background: #F7FAFA;
border: 2px solid #EBEBEB;
border-radius: 1.6vw;
font-size: 4vw;
font-family: Roboto;
font-weight: 400;
color: #279D8E;
display: flex;
align-items: center;
justify-content: center;
}