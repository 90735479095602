
.SendForum_body{
  width: 99%;
  border: 1px solid #EBEBEB;
  border-radius: 2px;
  padding: 10px;
}



.SendForum_input{
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  width:100%;
  height: 2.3438vw;
  font-size: 16px;
font-family: Roboto;
font-weight: bold;
color: #222222;

}

.SendForum_content{
border-radius: 2px;
margin-top: 0.5208vw;
}

.SendForum_img{
  margin-top: 10px;
}

.SendForum_tags{
  display: flex;
  align-items: center;
}

.SendForum_submit{
  width: 3.4375vw;
  height: 1.6667vw;
  background: #419D41;
  border-radius: 0.3125vw;
  font-size: 0.8333vw;
  font-family: Roboto;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1vw;
  cursor: pointer;
}



.mproject_pendForum_popup{
  width: 100%;
  height: 100%;
  overflow: auto;
}

