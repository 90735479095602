body {
  margin: 0;
  padding: 0;
  /* background: linear-gradient(-45deg, #E8F4F2 0%, #DFEBD9 100%); */
  /* overflow: scroll;
  scrollbar-color: transparent transparent;
  scrollbar-track-color: transparent;
  -ms-scrollbar-track-color: transparent; */
min-width: 75vw !important;
/* overflow: hidden; */
}
/* ::-webkit-scrollbar {
  width: 0.2rem;
  background-color: #f5f5f5;
  }
  ::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;
  border-radius: 1rem;
  } */

.bule{
  color: #66C966;
}


.flex-width{
  width: 100%;
  display: flex;
}


.flex-center{
  width: 100%;
  display: flex;
  justify-content: center;
}

.flex-align-center{
  width: 100%;
  display: flex;
  justify-content: center;
}



@media   (max-width: 1440) {
  html {
    transform: scale(0.8);
  }
}


@media   (max-width: 1280) {
  html {
    transform: scale(0.6);
  }
}


@media   (max-width: 1180) {
  html {
    transform: scale(0.4);
  }
}



.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: black !important;
}
.ant-tabs-ink-bar{
  color: black !important;
}


.ant-input-number-input{
  height: 100%;
}


.ant-modal-body{
  padding: 0px !important;
}

img{
  object-fit: contain;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color:  #3DA16B !important;
  border-color:  #3DA16B !important;
}

.ant-modal-content{
  border-radius: 20px !important;
}

.text_my{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}


.adm-tabs{
   --content-padding: 0px !important;
}


.but-shadow:hover{
  cursor: pointer;
}

.but-shadow:hover{
  box-shadow: 0 0.225vw 0.2333vw 0 rgba(0,0,0,0.24), 0 0.4854vw 0.6042vw 0 rgba(0,0,0,0.1);
}

.ant-select-arrow{
  font-size: 1.125rem !important;
}

.shou{
  cursor: pointer;
}