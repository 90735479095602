
#road-map[mode="web"] {
  width: 100%;
  display: flex;
  justify-content: center;
  background: linear-gradient(-45deg, #E8F4F2 0%, #DFEBD9 100%);
  padding-bottom: 60px;
}

@media (min-width: 1920px) {
  #road-map[mode=web] .road-map-content {
     width: 1920px !important;
     display: flex;
     flex-wrap: wrap;
     margin-left: 152px;
  }
}
@media (max-width: 1920px) {
  #road-map[mode=web] .road-map-content {
     width: 1920px !important;
     display: flex;
      flex-wrap: wrap;
      color: #3F4140;
      margin-left: 152px;

  }
}


#road-map[mode=web] .title-bg {
  background-image: url("../../assets/impactopia/black.png");
  background-size:100% 100%;  
  width: 310px;
  height: 98px;
  background: #1F1F1F;
  border: 4px solid #000000;
  border-radius: 16px;
  position:relative;
  top: -25px;
  display: flex;justify-content:center;
}


#road-map[mode=web] .title-text {
font-size: 46px;
font-family: Impact;
text-decoration: underline;
color: #F7F7F7;
}

#road-map[mode=web] .road-flex {
   display: flex;
   margin-top: 60px;
  }




  #road-map[mode=web] .read-step-text {
    font-size: 16px;
    font-family: DIN;
    font-weight: 500;
    color: #7C807E;
    line-height: 36px;
    width: 278px;
    height: 197px;

   }

   #road-map[mode=web] .road-step {
      margin-left:170px;
      display: flex;
      flex-wrap: wrap;
      width: 750px;
      align-items: center;
   }

   #road-map[mode=web] .step-line-bg{
    background-image: url("../../assets/impactopia/road.png");
    background-size:100% 100%;  
    width: 958px;
    height: 96px;
  }


  #road-map[mode=web] .step-line-green{
    background-image: url("../../assets/impactopia/line-green.png");
    background-size:100% 100%;  
    width: 280px;
    height: 16px;
    position: relative;
    left: -10px;
  }
  


 #road-map[mode=web] .step-img-bg-div{
   display: flex;
   align-items: center;
}


 #road-map[mode=web] .step-img-bg {
  background: #66C966;
  border: 3px solid #DFEBD9;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  z-index: 999;
}

#road-map[mode=web] .step-img-rv {
  background: #DFEBD9;
  border: 2px solid #EFF6F2;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
  left: -65px;
}


#road-map[mode=web] .step-img-rv-end {
  background: #DFEBD9;
  border: 2px solid #EFF6F2;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 45px;
}


#road-map[mode=web] .step-img-rv-border {
  border: 2px solid #66C966;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  z-index: 999;
}


#road-map[mode=web] .step-img-rv-border-gay {
  border: 2px solid #CEE2DC;
  border-radius: 50%;
  background-color:  #EFF6F1;
  width: 38px;
  height: 38px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  z-index: 999;
}








#road-map[mode=web] .step-img {
   width: 22px;
   height: 22px;
}


#road-map[mode=web] .step-text {
  font-size: 14px;
  font-family: DIN;
  font-weight: 500;
  color: #505351;
}


#road-map[mode=web] .step-time {
  font-size: 14px;
  font-family: DIN;
  font-weight: 500;
  color: #74CD74
}


#road-map[mode=web] .step-img-line {
  width: 180px;
  height: 16px;
  background: #66C966;
  border-radius: 8px;
}




   #road-map[mode=web] .step-tile {
     display: flex;
  }

  #road-map[mode=web] .step-line {
     margin-top: 20px;
 }

 #road-map[mode=web] .step-tile-text {
    width: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: DIN;
    font-weight: 500;
}


#road-map[mode=web] .step-tile-text-g {
  width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-family: DIN;
  font-weight: 500;
  color: #505351;
}

 

 
  





