.forum_body {
    width: 100%;
    display: flex;
    background: #FFFFFF;
    padding: 0px 0px 30px 0px ;
  }

  .forum_left{
    width: 65.1042vw;
    padding-left: 15.625vw;
    padding-top: 30px;
  }
  
  .forum_right{
    width: 34.8958vw;
  }

  .forumflex{
    display: flex;
    width: 100%;
  }

  .forum_list_content{
    font-size: 12px;
    font-family: Roboto;
    font-weight: 300;
    color: #595959;
    width: 957px;
    min-height: 3.1667vw;
    padding: 0.5208vw;
  }
  

  .forum_sc{
    width: 8.4062vw;
    height: 1.4583vw;
    padding-left: 0.2604vw;
    padding-right: 0.2604vw;
    background: #F0F0F0;
    border-radius: 0.2083vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  
  

  .forum_list_name{
    font-size: 24px;
    font-family: Roboto;
    font-weight: bold;
    color: #222222;
  }


  .forum_list_content{
    font-size: 0.825vw;
    font-family: Roboto;
    font-weight: 300;
    color: #595959;
    width: 46.875vw;
    word-break: break-all;
  }


  .forum_list_item{
    min-height: 10.2083vw;
    width: 49.4792vw;
    padding:10px;
    margin-top: 0.5208vw;
    box-shadow: 0px 3px 7px 0px rgba(181,192,191,0.35);
  }


  .forum_time{
    font-size: 12px;
  font-family: DIN;
  font-weight: 400;
  color: #B3B3B3;
  margin-left: 20px;
  }

  .forum_but{
    border-radius: 0.2083vw;
    font-size: 0.625vw;
    font-family: Roboto;
    font-weight: 400;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5vw;
    padding-left: 0.2083vw;
    padding-right: 0.2083vw;
  }
  
  .forum_flex_one{
    display: flex;
    flex-wrap: wrap;
  }


 .forum_img{
  margin-right: 1.0417vw;
  margin-top: 1.0417vw;
 }
 

 .forum_tools_but{
  width: 3.2292vw;
  height: 1.4583vw;
  background: #52A553;
  border-radius: 0.2083vw;
  display: flex;
align-items: center;
  justify-content: space-between;
  padding-left: 0.2604vw;
  padding-right: 0.2604vw;
  cursor: pointer;
 }

 .forum_but_line{
  width: 0.0521vw;
  height: 0.9375vw;
  background: #FFFFFF;
 }

 .forum_but_img{
  width: 0.8333vw;
  height: 0.8333vw;
 }


 .dis_list_img{
  width: 0.825vw;
  height: 0.9292vw;
}