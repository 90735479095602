#navigation[mode=web] {
  width: 100%;
  display: flex;
  height: 3.125vw;
  align-items: center;
  background: linear-gradient(-45deg, #E8F4F2 0%, #DFEBD9 100%);
  position: fixed;
  z-index: 999;
  top: 0px;
}

#navigation[mode=web] .nav-content {
  display: flex;
  flex-wrap: wrap;
   align-items: center;
   color: #3F4140;
   font-family: DIN;
}



#navigation[mode=web] .nav-left{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 52.1875vw;
  padding-left: 5.2083vw;
}


.nav-center{
  width: 23.8021vw;
  }
  

#navigation[mode=web] .nav-right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 24.0104vw;
}

#navigation[mode=web] .title {
  font-size: 1.1458vw;
  font-weight: 600;
}





#navigation[mode=web] .nav-items {
  display: flex;
  margin-left: 2.8646vw;
}

#navigation[mode=web] .nav-items .nav-item {
  display: flex;
  align-items: center;
  margin-left: 1.5625vw;
  font-size: 0.8333vw;
  font-family: Roboto;
  font-weight: 500;
  color: #A8ADAA;
  cursor: pointer;
  overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
}




#navigation[mode=web] .nav-items .nav-active {
  color: #1A1A1A;
  font-weight: 900;
  font-size: 1.0417vw;
  font-family: Roboto;
}




#navigation[mode=web]  .nav-back {
  color: #1A1A1A !important;
}


#navigation[mode=web] .connector-box {
    border: 2px solid #3F4140;
    border-radius: 0.2604vw;
    margin-left: 0.5208vw;

}

#navigation[mode=web] .connector {
  position: relative;
  font-size: 1.2vmin;
  color: black;
  padding: 6px 4vmin;
  letter-spacing: 2px;
  font-weight: 600;
  align-self: flex-end;
}

#navigation[mode=web] .connector img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


#navigation[mode=mobile] .connector {
  position: absolute;
  width: 50vw;
  left: 4vw;
}





#navigation[mode=web]  .img-tg {
  width: 1.4583vw;
  height: 1.4583vw;
}

#navigation[mode=web]  .img-marg {
  margin-left: 0.7292vw;
}


#navigation[mode=web]  .bule{
  color: #419F41 !important;
}

#navigation[mode=web]  .nav-logo{
  width: 9.1667vw;
  height: 1.4583vw;
  cursor: pointer;
}




#navigation[mode=mobile]  .nav-bar{
  width: 100%;
  display: flex;
  height: 55px;
  background: linear-gradient(-45deg, #E8F4F2 0%, #DFEBD9 100%);
}

#navigation[mode=mobile]  .nav-bar-img{
  background-image: url("../../assets/mobile/Impactopia@2x.png");
  background-size:100% 100%;  
  height: 17px;
  width: 103px;
}

#navigation[mode=mobile]  .nav-bar-img2{
  background-image: url("../../assets/mobile/share@2x.png");
  background-size:100% 100%;  
  height: 17px;
}

#navigation[mode=mobile]  .nav-bar-img3{
width: 18px;
  height: 17px;
}

.nav-bar-right{
  display: flex;
  justify-content: flex-end;
}

#navigation[mode=mobile]  .nav-bar-bet1{
  width: 34px;
  height: 21px;
  background: #1A1A1A;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

#navigation[mode=mobile]  .nav-bar-bet2{
  width: 34px;
  height: 21px;
  border: 1px solid #1A1A1A;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

#navigation[mode=mobile]  .nav-bar-bet3{
  width: 50px;
  height: 21px;
  border: 1px solid #1A1A1A;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#navigation[mode=mobile]  .nav-bar-bet3-span{
  max-width: 165px; 
  overflow: hidden; 
  white-space: nowrap; 
  text-overflow: ellipsis;
}

.nav-Popup{
  background: linear-gradient(-45deg, #E8F4F2 0%, #DFEBD9 100%);
}


 .Popup_nav-items{
  width: 100%;
}

 .Popup_nav-item{
  width: 100%;
  font-size: 30px;
  font-family: Roboto;
  color: #3F4140;
  height: 80px;
  padding-left: 23px;
  display: flex;
}

.Popup_nav-item2{
  width: 100%;
  font-size: 22px;
  font-family: Roboto;
  color: #A8ADAA;
  height: 80px;
  padding-left: 23px;
  display: flex;
}

.Popup_nav-item-impactopia {
  font-size: 30px;
  font-family: Impact;
  font-weight: 400;
  color: #222222;
  margin-top: 28px;
}

.Popup_nav-item-img-home{
  background-image: url("../../assets/mobile/Home-g@2x.png");
  background-size:100% 100%;  
  width: 40px;
  height: 36px;
  margin-top: 2px;
  margin-right: 10px;
}

.Popup_nav-item-img{
  background-size:100% 100%;  
  width: 40px;
  height: 36px;
  margin-top: 2px;
  margin-right: 10px;
}

.Popup_nav-item-img-discover{
  background-image: url("../../assets/mobile/Discover@2x.png");
  background-size:100% 100%;  
  width: 40px;
  height: 36px;
  margin-top: 2px;
  margin-right: 10px;
}

.Popup_nav-item-img-donate{
  background-image: url("../../assets/mobile/Donate@2x.png");
  background-size:100% 100%;  
  width: 40px;
  height: 36px;
  margin-top: 2px;
  margin-right: 10px;
}

.Popup_nav-item-img-impact{
  background-image: url("../../assets/mobile/ImpactBadge@2x.png");
  background-size:100% 100%;  
  width: 40px;
  height: 36px;
  margin-top: 2px;
  margin-right: 10px;
}

.Popup_nav-item-img-friends{
  background-image: url("../../assets/mobile/Friends@2x.png");
  background-size:100% 100%;  
  width: 40px;
  height: 36px;
  margin-top: 2px;
  margin-right: 10px;
}

.Popup_nav-item-img-community{
  background-image: url("../../assets/mobile/Community@2x.png");
  background-size:100% 100%;  
  width: 40px;
  height: 36px;
  margin-top: 2px;
   margin-right: 10px;
}




.Popup_mentuShare_rud{
  width: 2.8125vw;
  height: 2.8125vw;
  background: #E7ECED;
  border: 1px solid #DADFE0;
  opacity: 0.68;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Popup_mentuShare{
  display: flex;
  height: 30vh;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 3.1771vw;
  padding-right: 3.1771vw;
  justify-content: space-between;
}

.pushMint{
  cursor: pointer;
  height: 2.1875vw;
  padding-left: 0.5208vw;
  padding-right: 0.5208vw;
  background: #52A553;
  border-radius: 0.3125vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9375vw;
font-family: Roboto;
font-weight: 500;
color: #F2F8F1;
margin-left: 0.5208vw;
}




.pushMint:hover{
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

.pushMint_m{
  height:30px;
  padding-left: 1.3333vw;
  padding-right: 1.3333vw;
  background: #279D8E;
  border-radius: 0.6667vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.2vw;
  font-family: Roboto;
  font-weight: 500;
  color: #F2F8F1;
  margin-left: 1.3333vw;
}



.Popup_nav-item_m{
  width: 100%;
  font-size: 22px;
  font-family: Roboto;
  color: #A8ADAA;
  height: 40px;
  display: flex;
}


.web_search{
  width: 20.7292vw;
  height: 1.6667vw;
  padding-left: 0.7813vw;
  border: 1px solid #A8ADAA;
  border-radius: 0.8333vw;
  display: flex;
  align-items: center;
  color: #000000;
}


.web_search_img {
  width: 0.8333vw;
  height: 0.8333vw;
}

.mweb_search_img {
  width: 28px;
  height: 28px;
  margin-right: 0.3604vw;
}

.web_search_Description {
  width: 14.7292vw;
  text-overflow: ellipsis;  
  white-space: nowrap;    
  overflow: hidden;
}


.web_search_header_img {
  width: 2.5vw !important;
  height: 2.4479vw !important;
  border-radius: 100%;
  object-fit: contain;
  background: linear-gradient(-45deg, #E8F4F2 0%, #DFEBD9 100%);
}

.web_search_name {
  font-size: 0.8333vw;
  font-weight: bold;
  color: #222222;
  width: 12.7292vw;
  text-overflow: ellipsis;
  white-space: nowrap;   
  overflow: hidden; 
}



.mweb_search{
  width: 80.6667vw;
  height: 34px;
  padding-left: 0.7813vw;
  border: 1px solid #A8ADAA;
  border-radius: 45px;
  display: flex;
  align-items: center;
  color: #000000;
}


.navBar_box{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  height:50px;
}


.navBar_box_name{
  display: flex;
  align-items: center;
  width: 180px;
  justify-content: center;
}


.mweb_search_header_img {
  width:  10vw!important;
  height: 10vw!important;
  border-radius: 100%;
  object-fit: contain;
  background: linear-gradient(-45deg, #E8F4F2 0%, #DFEBD9 100%);
}

.mweb_search_name {
  font-size: 12px;
  font-weight: bold;
  color: #222222;
  width: 80vw;
  text-overflow: ellipsis;
  white-space: nowrap;   
  overflow: hidden; 
}

.mweb_search_item {
  display: flex;
  width: 100%;
  align-items: center;
  height: 20vw;
  margin-bottom: 10px;
}

.mweb_search_Description {
  width: 80vw;
  text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 12px;
}


.mweb_search_box{
  display: flex;
  justify-content: center;
  padding-top: 40px;
  flex-wrap: wrap;
}

.mweb_search_div{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 10px;padding: 10px;
}