.tiding_body{
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  background: #FFFFFF ;
  padding-top: 2.4479vw;
}

.tiding_left{
  width: 58.7813vw;
  padding-left: 13.5937vw;
}

.tiding_right{
  width: 34.2188vw;
  padding-left: 2.0833vw;
}

.tiding_title{
  font-size: 1.25vw;
font-family: Roboto;
font-weight: bold;
color: #222222;
}

.tiding_text_desc{
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  color: #222222;
  margin-top: 1.9792vw;
}


.tiding_comment_box{
  width: 49.4792vw;
  height: 41.4583vw;
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 2px;
  margin-top: 5.0521vw;
  padding-top: 1.3542vw;
  padding-left: 1.3542vw;
}


.tiding_feedback{
font-size: 1.875vw;
font-family: Roboto;
font-weight: 900;
text-decoration: underline;
color: #222222;
}

.tiding_comment_msg{
  height: 28.125vw;
  overflow: auto;
}

.tiding_comment_input{
  border: 1px solid #E0E0E0;
  border-radius: 2px;
  width: 47.3958vw;
  height: 8.6458vw;
  background: #F9FAF8;
  display: flex;
}

.tiding_send_but_box{
  display: flex;
  align-items: flex-end;
  padding-bottom: 10px;
  padding-left: 30px;
}


.tiding_send_but{
  width: 80px;
  height: 30px;
  background: #52A553;
  border-radius: 2px;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  color: #F2F8F1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


.tiding_comment_item{
  /* border-bottom: 1px solid #E0E0E0; */
  width: 47.4792vw;
  padding-top: 0.5208vw;
  padding-bottom: 0.5208vw;
}

.tiding_comment_item_1{
  display: flex;
  align-items: center;
}

.tiding_comment_item_1_1{
  margin-left: 10px;
}

.tiding_comment_item_2{
  margin-left: 50px;
  width: 700px;
  word-break: break-all;
}

.tiding_comment_item_3{
  margin-right: 50px;

}

.tiding_content_item_sx{
  display: flex;
  align-items: center;
  margin-top: 0.5208vw;
  margin-left: 50px;
  cursor: pointer;
  margin-bottom: 10px;
}