.cookie{
    position: fixed;
    z-index: 800;
    padding: 1.6rem;
    bottom:3.8vw;
    margin: auto;
    display: flex;
    align-items: center;
    background-color: #fff;
    justify-content: center;
    width: 100%;
    border: 0.0521vw solid #DFEBD9;
    cursor: pointer;
}



.Button_button__MJ5pb {
    display: inline-flex;
    align-items: center;
    gap: 0.8rem;
    vertical-align: middle;
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    border-radius: 5px;
    padding: 0px 7px 0px 7px ;
    white-space: nowrap;
    cursor: pointer;
}

.buttonThemes_theme-tertiary__v7VoN {
    background: #e9ebee;
    color: #1d1d20;
    margin-left: 20px;
}


.buttonThemes_theme-primary__C0pSI {
    background: #1e1870;
    color: #fff;
    margin-left: 20px;
}


.cookie_text{
    font-size: 16px;
    font-weight: bold;
}

.policy{
    color: #f4603e;
    text-decoration: underline;
}


.ModalContent_titleRow__8rPYU {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #1d1d20;
}


.section {
    border-radius: 1.2rem;
    background: #f3f5f7;
    padding: 1rem;
    width: 100%;
}