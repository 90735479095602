
.trendingTrending {
  display: flex;
  flex-wrap: wrap;
}

.trendingContent{
  border-right: 1px solid #DFEBD9;
  width: 28.4583vw;
}



.trendingflex{
  display: flex;
}

.trending_status_active{
  font-size: 1.875vw;
  font-family: Roboto;
  font-weight: 900;
  text-decoration: underline;
  color: #222222;
}

 .trendingTitle{
  font-size: 2.5vw;
font-family: Impact;
font-weight: 400;
text-decoration: underline;
color: #222222;
}

.trending_tren_title{
  font-size: 1.1458vw;
font-family: Roboto;
font-weight: 400;
color: #222222;
}




.trending_tren_text{
  font-size: 0.8208vw;
  font-family: Roboto;
  font-weight: 400;
  color: #595959;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}


 .trending_Reading{
  font-family: Roboto;
font-weight: 400;
text-decoration: underline;
color: #419D41;
}




.trending_tren_titlem{
  font-size: 4vw;
  font-family: Roboto;
  font-weight: bold;
  color: #222222;
}