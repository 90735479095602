
.user_not_sign{
  margin-left: 0.5208vw;
  padding-left: 0.5208vw;
  padding-right: 00.5208vw;
  background: #1A1A1A;
  border-radius: 0.3125vw;
  font-size: 0.8333vw;
  font-family: Roboto;
  font-weight: 400;
  color: #F2F8F1 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}


.user_menu{
  display: flex;
  width: 10.5417vw;
  height: 2.042vw;
  padding-left: 0.3125vw;
  align-items: center;
}


.muser_menu{
  display: flex;
  width: 130px;
  height: 30px;
  align-items: center;
}



.user_menu_img{
  height: 1.0417vw;
  width: 1.0417vw;
}

.user_menu_name{
  font-size: 0.9375vw;
  font-family: Roboto;
  font-weight: 400;
  color: #595959;
  opacity: 0.6;
  margin-left: 0.5208vw;
}


.user_menu_name_m{
  font-size: 2.375vw;
  font-family: Roboto;
  font-weight: 400;
  color: #595959;
  opacity: 0.6;
  margin-left: 0.5208vw;
}
