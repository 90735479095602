.my_post_body{
  width: 100%;

}


.dis_list_name{
  font-size: 1.25vw;
  font-family: Roboto;
  font-weight: bold;
  color: #222222 !important;
  width: 49.8438vw;

}

.dis_Reading{
  font-family: Roboto;
font-weight: 400;
text-decoration: underline;
color: #419D41;
}

.dis_list_item{
  min-height: 10.2083vw;
  width: 100%;
  border-bottom:  0.0521vw solid #DFEBD9;
  padding-top: 1.0938vw;
  padding-bottom: 0.5208vw;
  padding-right: 2.6667vw;
}


.my_post_item{
  margin-top: 0.5208vw;
  width: 60.4167vw;
  height: 6.4688vw;
  background: #FFFFFF;
  border: 1px solid #F5F5F5;
  box-shadow: 0px 4px 6px 0px rgba(207,209,208,0.35);
  border-radius: 0.5208vw;
  padding-left: 1.1458vw;
  padding-right: 5.2083vw;
}



.my_post_item_1{
  width: 100%;
  height: 3.1771vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.my_post_item_1_name{
  width: 30.4479vw;
  font-size: 1.25vw;
  font-family: Roboto;
  font-weight: bold;
  color: #222222;


}

.my_post_item_1_date{
  font-size: 0.7292vw;
  font-family: Roboto;
  font-weight: 400;
  color: #4D4D4D;
}




.my_post_item_end{
  width: 100%;
  height: 2.2396vw;
  display:flex;
  align-items: center;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  color: #F9BF6D;
  margin-bottom: 0.5208vw;


}

.my_post_item_end_text{
  color: #4D4D4D;
  margin-left: 10px;
}

.options_box{
  width: 8.0729vw;
  height: 1.4583vw;
  background: rgba(240, 240, 240,0.5);
  border-radius: 0.2083vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.options_box_flex{
  width: 8.0729vw;
  height: 1.0583vw;
  align-items: center;
  display: flex;
  align-items: center;
  color: rgb(107, 104, 104);
  font-size: 0.7292vw;
  font-family: DIN;
  justify-content: center;
}
.options_box_flex_border{
  cursor: pointer;
  border-right: 1px solid rgba(240, 240, 240,1);
}
.options_box_num{
  margin-left: 0.204vw;

}
