
#discover[mode="web"] {
  width: 100%;
  display: flex;
  background: #FFFFFF;
  padding: 20px 0px 30px 0px ;
}

@media (min-width: 1920px) {
  #discover[mode=web] .disContent {
     width: 1920px !important;
     display: flex;
     flex-wrap: wrap;
      margin-left: 179px;
  }
}
@media (max-width: 1920px) {
  #discover[mode=web] .disContent {
     width: 1920px !important;
     display: flex;
     flex-wrap: wrap;
      color: #3F4140;
      margin-left: 179px;
  }
}



#discover[mode="web"] .disTitle{
  font-size: 48px;
font-family: Impact;
font-weight: 400;
text-decoration: underline;
color: #222222;
}

#discover[mode="web"] .disflex{
  display: flex;
  width: 100%;
}

#discover[mode="web"] .disflex-left{
  width: 50%;
  font-size: 16px;
  font-family: DIN;
  font-weight: 300;
  color: #3F4140;
  line-height: 30px;
  margin-left: 100px;
}


#discover[mode="web"] .disText{
  max-width: 610px;
  font-size: 18px;
  font-family: Roboto;
  font-weight: 400;
  color: #3F4140;
  line-height: 36px;
  margin-left: 75px;
}



#discover[mode="web"] .dis_status{
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  color: #ADADAD;
}


#discover[mode="web"] .dis_status_active{
  font-size: 16px;
font-family: Roboto;
font-weight: 400;
text-decoration: underline;
color: #222222;
}



#discover[mode="web"] .dis_list_one{
  width: 50%;
  margin-top: 40px;
}


#discover[mode="web"] .dis_but{
  background: #419D41;
  border: 1px solid #54A554;
  border-radius: 4px;
  width: 34px;
  height: 16px;
  font-size: 12px;
  font-family: Roboto;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

#discover[mode="web"] .dis_but_y{
  background: #D79A2E;
  border: 1px solid #D79A2E;
  border-radius: 4px;
  width: 34px;
  height: 16px;
  font-size: 12px;
  font-family: Roboto;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
}


#discover[mode="web"] .dis_time{
  font-size: 0.625vw;
font-family: DIN;
font-weight: 400;
color: #B3B3B3;
margin-left: 20px;
}



#discover[mode="web"] .dis_content{
  font-size: 0.925vw;
  font-family: Roboto;
  font-weight: 300;
  color: #595959;
  width: 680px;
  overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
}

#discover[mode="web"] .dis_sc{
  cursor: pointer;
  width: 123px;
  height: 28px;
  background: #F0F0F0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#discover[mode="web"] .dis_Reading{
  font-family: Roboto;
font-weight: 400;
text-decoration: underline;
color: #419D41;
cursor: pointer;
}



#discover[mode=web] .options_box{
  width: 8.0729vw;
  height: 1.4583vw;
  background: rgba(240, 240, 240,0.5);
  border-radius: 0.2083vw;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

#discover[mode=web] .options_box_flex{
  cursor: pointer;
  width: 8.0729vw;
  height: 1.0583vw;
  align-items: center;
  display: flex;
  align-items: center;
  color: rgb(107, 104, 104);
  font-size: 0.7292vw;
  font-family: DIN;
  justify-content: center;
}

#discover[mode=web] .options_box_num{
  margin-left: 0.204vw;

}

#discover[mode=web] .options_box_flex_border{
  cursor: pointer;
  border-right: 1px solid rgba(240, 240, 240,1);
}

#discover[mode=web] .disForum{
  width: 66.6146vw;
  padding-left: 3.0729vw ;
  padding-right: 13.6458vw;
}

#discover[mode=web] .dis_list_item{
  min-height: 10.2083vw;
  width: 100%;
  border-bottom:  0.0521vw solid #DFEBD9;
  padding-top: 1.0938vw;
  padding-bottom: 0.5208vw;

}

#discover[mode=mobile] .dis_list_item{
  width: 93.6vw;
  min-height: 33.3333vw;
  background: #FFFFFF;
  border: 0.1333vw solid #F3E7CA;
  border-radius: 1.6vw;
  padding-left: 3.3333vw;
  padding-top: 2.6667vw;
  margin-top: 10px;
}



#discover[mode="mobile"] .dis_list_name{
  font-size: 4vw;
  font-family: Roboto;
  font-weight: bold;
  color: #222222 !important;

}
#discover[mode="mobile"] .disflex{
  display: flex;
}

#discover[mode="mobile"]  .forum_but{
  border-radius: 0.5333vw;
  font-size: 2.9333vw;
  font-family: Roboto;
  font-weight: 400;

  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.3333vw;
}

#discover[mode="mobile"] .dis_list_content{
  font-size: 1.05vw;
  font-family: Roboto;
  font-weight: 300;
  color: #595959;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

#discover[mode="mobile"] .options_box{
  width: 8.0729vw;
  height: 1.4583vw;
  background: rgba(240, 240, 240,0.5);
  border-radius: 0.2083vw;
  display: flex;
  align-items: center;
  justify-content: space-between;

}



#discover[mode="mobile"] .discover_bg{
  width: 87.2vw;
  height: 27.2vw;
}


.discover_seach{
  width: 95%;
  height: 8vw;
  border-radius: 4vw;
  background: #FFFFFF;
  border: 0.1042vw solid #F5F5F5;
  display: flex;
  align-items: center;
  padding-left: 4vw;
}

.all_tags_but{
  padding: 2vw 1.3333vw 2vw 1.3333vw;
  border-radius: 0.6667vw;
  margin-right: 0.6667vw;
  font-size: 3.2vw;
  font-family: Roboto;
  font-weight: 400;
  color: #FFFFFF;
}

.call_tags_but{
  padding: 0.4167vw;
  border-radius: 0.2667vw;
  margin-right: 0.3125vw;
  font-size:0.625vw;
  font-family: Roboto;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
  margin-top: 0.5208vw;
  display:inline-grid;
}






.mdiscover_seach_img{
  width: 4.2667vw;
  height: 4.2667vw;
}


