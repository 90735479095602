#nft[mode="web"] {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (min-width: 1920px) {
  #nft[mode=web] .disContent {
     width: 1920px !important;
     display: flex;
     margin-left: 7.1rem;
     margin-top: 3.0625rem;
  }
}
@media (max-width: 1920px) {
  #nft[mode=web] .disContent {
     width: 1920px !important;
     display: flex;
      color: #3F4140;
      margin-left: 7.1rem;
     margin-top: 3.0625rem;
  }
}

#nft[mode=web] .text-bule-nft{
  font-size: 16px;
  font-family: DIN;
  font-weight: 600;
  color: #419F41;
}



#nft[mode=web] .text-bule-nft-2{
  font-size: 16px;
  font-family: DIN;
  font-weight: 400;
  color: #419F41;
  text-decoration: underline;
}


.flex-width{
  width: 100%;
  display: flex;
}


.flex-center{
  width: 100%;
  display: flex;
  justify-content: center;
}


#nft[mode=web] .img-cut{
  width: 54px;
  height: 54px;
}


.ant-modal-content {
  position: relative;
  background-color:transparent;
  background-clip: padding-box;
  border: 0;
  
  border-radius: 2px;
 box-shadow: none; 
  pointer-events: auto;
}


#nft[mode=web] .title-bg {
  background-image: url("../../assets/impactopia/black.png"); 
  background-size:100% 100%;  
  width: 310px;
  height: 98px;
  display: flex;
  justify-content:center;
  align-items: center;
  cursor: pointer;
}


#nft[mode=web] .title-bg-no {
  background-image: url("../../assets/impactopia/grey.png");
  background-size:100% 100%;  
  width: 310px;
  height: 98px;
  display: flex;justify-content:center;
  align-items: center;
  cursor: pointer;
}




#nft[mode=web] .card-black {
  background-image: url("../../assets/impactopia/card-black.png");
  background-size:100% 100%;  
  width: 300px;
  height: 220px;
  display: flex;justify-content:center;
  align-items: center;
  font-size: .75rem;
font-family: Roboto;
font-weight: 900;
color: #B5BDB3;
}





#nft[mode=web] .title-text {
  font-size: 42px;
  font-family: Impact;
  text-decoration: underline;
  color: #F7F7F7;
  }

  #nft[mode=web] .nft-bg-text {
    font-size:20px;
    font-family: DIN;
    font-weight: normal;
    color: #1E1F1E;
    margin-top: 11px;

   }


   #nft[mode=web] .badge-text {
    font-size: 24px;
    font-family: DIN;
    font-weight: bold;
    color: #419F41;
   }


   #nft[mode=web] .badge-text_2 {
    font-size: 18px;
    font-family: DIN;
    font-weight: bold;
    color: rgba(30, 31, 30, 1);
   }

   #nft[mode=web] .nft-list {
    margin-top: 40px;
    display: flex;
   }


   #nft[mode=web] .my-nft-list {
    margin-top: 85px;
    display: flex;
    flex-wrap: wrap;
   }



   #nft[mode=web] .nft-item {
    width: 260px;
    margin: 15px;
  }


  
  #nft[mode=web] .nft-img {
    width: 260px;
    height: 381px;
   }



 

  #nft[mode=web] .nft-item-big {
    width: 46.6rem;
    height: 656px;
    background: #E4EBE4;
    border: 4px solid #1A1A1A;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  #nft[mode=web] .nft-text-2-big {
    width: 28.125rem;
    font-size: 20px;
    font-family: Roboto;
    font-weight: 400;
    color: #2B2F29;
    line-height: 36px;
    padding: 10px;
    margin-top: 10px;
    background: #E0EDDC;
    border: 1px solid #C1E1C1;
    border-radius: 6px;
  }


  #nft[mode=web] .nft-text-2-big-other {
    width: 298px;
    height: 98px;
    background: #E0EDDC;
    border: 1px solid #C1E1C1;
    border-radius: 6px;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 400;
    color: #2B2F29;
    line-height: 36px;
    padding: 10px;
    margin-top: 10px;
  }

  #nft[mode=web] .nft-text-legend {
    background-image: url("../../assets/impactopia/box@2x.png");
    background-size:100% 100%;  
    width: 450px;
    height: 202px;
    border: 1px solid #C1E1C1;
    border-radius: 6px;
    font-size: 15px;
    font-family: Roboto;
    font-weight: 400;
    color: #2B2F29;
    line-height: 32px;
    padding: 10px;
    margin-top: 10px;
  }

  #nft[mode=web] .nft-text-legend-one {
    display:block;
    font-size: 18px;
    color: #419F41;
  }

  #nft[mode=web] .nft-text-legend-two {
    font-size: 15px;
  }

  #nft[mode=web] .nft-text-legend-three {
    font-size: 16px;
    color: #419F41;
    text-decoration: underline;
    cursor: pointer;
  }

  #nft[mode=web] .nft-text-legend-four {
    display:block;
    font-size: 15px;
  }








   #nft[mode=web] .nft-img-big {
    width: 26.25rem;
    height: 38.125rem
   }



   #nft[mode=web] .nft-img-modal {
    width: 45.65rem;
    height: 40rem;
   }


   


   #nft[mode=web] .nft-text-1-big {
    display: flex;
    margin-top: 5px;
    font-size: 20px;
    font-family: Roboto;
    font-weight: 600;
    text-decoration: underline;
    color: #2C2C2C;
  }

  #nft[mode=web] .nft-text-1-big-other {
    display: flex;
    margin-top: 5px;
    font-size: 20px;
    font-family: Roboto;
    font-weight: 600;
    text-decoration: underline;
    color: #2C2C2C;
  }


 


   #nft[mode=web] .nft-text-1 {
     display: flex;
     margin-top: 5px;
     justify-content: center;
     font-size: 18px;
     font-family: Roboto;
     font-weight: 900;
     text-decoration: underline;
     color: #2C2C2C;
   }

   

   #nft[mode=web] .nft-text-2 {
    width: 300px;
    height: 56px;
    background: #E0EDDC;
    border: 1px solid #C1E1C1;
    border-radius: 6px;
    font-size: 12px;
    color: #2B2F29;
    padding: 10px;
    margin-top: 10px;
  }




   #nft[mode=web] .nft-text-other {
    display: flex;
    margin-top: 5px;
     font-size: 18px;
     font-family: Roboto;
     font-weight: 900;
     text-decoration: underline;
     color: #2C2C2C;
  }

  .flex-wap{
     flex-wrap: wrap;
  }
  

  #nft[mode=web]  .nft-mint-bt-20{
      margin-top: 10px !important;
  }
  
  
   #nft[mode=web] .nft-mint-bt{
      width: 480px;
      height: 46px;
      background: #CDE4C6;
      border: 1px solid #419F41;
      border-radius: 6px;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;    
      margin-top: 40px;
      cursor: pointer;
  }

  #nft[mode=web] .nft-mint-bt-tw2{
    width: 480px;
    height: 46px;
    background: #419F41;
    border: 1px solid #CDE4C6;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;    
    cursor: pointer;
}


  #nft[mode=web] .nft-mint-bt-tw{
    width: 260px;
    height: 46px;
    border: 1px solid #419F41;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;    
    cursor: pointer;
}


#nft[mode=web] .nft-name-mint-tw{
  font-size: 20px;
  font-family: Roboto;
  font-weight: 400;
  color: #419F41;
  line-height: 30px;
  display: flex;
  align-items: center;
}

#nft[mode=web] .nft-name-mint-tw2{
  font-size: 20px;
  font-family: Roboto;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 30px;
  display: flex;
  align-items: center;
}

  
  
   
  #nft[mode=web] .polyogn-icon{
    width: 1.75rem;
    height: 1.5rem;
    margin-right: 20px;
  }

   
  #nft[mode=web] .nft-name-mint{
    font-size: 16px;
    font-family: Roboto;
    font-weight: 400;
    border-radius: 6px;
    color: #419F41;
    display: flex;
    align-items: center;
  }
  




  #nft[mode=web] .bule{
   color:  #419F41 !important
  }



  #nft[mode=web] .nft-line {
    margin-top: 35px;
    width: 1180px;
    display: flex;
    background: #E0EDDC;
    border: 1px solid rgba(0, 0, 0, 0.158);
   }

   #nft[mode=web] .nft-top-60 {
      margin-top: 60px;
  }

  #nft[mode=web] .nft-top-30 {
    margin-top: 60px;
}

  
  
  #nft[mode=web] .nft-top-2 {
    margin-top: 4px;
}

   #nft[mode=web] .nft-box1 {
    background-image: url("../../assets/impactopia/box.png");
    background-size:100% 100%;  
    width: 1178px;
    height: 108px;
    padding: 15px 0px 0px 27px;
    font-size: 18px;
    font-family: DIN;
    font-weight: 500;
    color: #1E1F1E;
    line-height: 36px;
 
  }


  #nft[mode=web] .nft-box2 {
    background-image: url("../../assets/impactopia/box.png");
    background-size:100% 100%;  
    width: 1178px;
    height:230px;
    display: flex;align-items:flex-start;
    margin-left: 520px;
    margin-top: 2px;
    padding: 0px 0px 0px 27px;
    font-size: 18px;
    font-family: DIN;
    font-weight: 500;
    color: #1E1F1E;
    line-height: 36px;
  }


  #nft[mode=web] .nft-box2-text {
    font-size: 24px;
font-family: DIN;
font-weight: 400;
color: #419F41;
line-height: 36px;
  }

  #nft[mode=web] .nft-box2-text-2 {
    font-size: 24px;
    font-family: DIN;
    font-weight: 400;
    color: #419F41;
    line-height: 36px;
  }









 