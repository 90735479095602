

.badge_detail_body{
    width: 100%;
    padding-left: 5.2083vw;
    padding-right: 5.2083vw;
    padding-top: 1.5625vw;
}

.badge_detail_current{
    width: 89.5833vw;
    height: 15.625vw;
    background: #FAFAFA;
    border: 1px solid #EBEBEB;
    border-radius: 0.3125vw;
    display: flex;
    align-content:center;
    padding-top: 1.5625vw;
}


.badge_detail_current_img{
    width:11.125vw ;
    height: 11.125vw;
    object-fit: cover;

}

.badge_detail_current_img_box{
    width:12.8125vw ;
    height: 12.8125vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.badge_detail_current_info_box{
    width: 35vw;
}

.badge_detail_current_info_box_top{
    height: 10.4167vw;
    width: 100%;
    border-bottom: 1px solid #EBEBEB;
    border-right: 1px solid #EBEBEB;
}

.badge_detail_current_info_box_top_1_name{
    font-size: 1.0417vw;
    font-family: Roboto;
    font-weight: bold; 

}

.badge_detail_current_info_box_top_1_content{
    font-size: 0.625vw;
    font-family: Roboto;
    font-weight: 400;
    color: #4D4D4D;
    height: 4.4271vw;
    margin-top: 0.7813vw;
}


.badge_detail_current_token{
    font-size: 0.8333vw;
    font-family: Roboto;
    font-weight: bold;
    color: #279D8E;
}


.badge_detail_current_mint{
height: 1.5625vw;
background: #279D8E;
border-radius: 0.3125vw;
display: flex;
align-items: center;
font-size: 0.625vw;
justify-content: center;
font-family: Roboto;
color: #FAFAFA;
margin-right: 0.5208vw;
cursor: pointer;
padding: 0.2604vw;
}

.badge_detail_current_tw{
    height: 1.5625vw;
    border: 0.0521vw solid #279D8E;
    border-radius: 0.3125vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.625vw;
    font-family: Roboto;
    font-weight: 400;
    color: #279D8E;
    padding: 0.2604vw;
    cursor: pointer;
   
}

.badge_detail_current_but{
    display: flex;
}


.badge_detail_current_top2{
    display: flex;
}

.badge_detail_current_line{
    width: 19.6875vw;
}

.badge_detail_current_info_box_bottom{
    padding-top: 1.0417vw;
}

.badge_detail_current_info_list{
    width: 40.3125vw;
    padding-top: 1.5625vw;
}

.badge_detail_current_info_list_1{
    width: 40.3125vw;
    display: flex;
    height: 6.08vw;
}

.badge_detail_current_info_list_text{
    width: 40.3125vw;
    display: flex;
    padding-left: 5vw;
    font-family: Roboto;
font-weight: 500;
color: #4D4D4D;
}


.badge_detail_current_info_list_img{
    width: 33.8542vw;
    padding-left: 5vw;
    cursor: pointer;
}



.badge_detail_list{
    width: 89.5833vw;
    height: 28.6458vw;
    background: #FAFAFA;
    border: 1px solid #EBEBEB;
    border-radius: 0.3125vw;
    padding-top: 1.0417vw;
    padding-left: 1.0417vw;
}

.badge_detail_list_name{
    font-size: 0.8333vw;
    font-family: Roboto;
    font-weight: 900;
    color: #2B2F29;
}

.badge_detail_user_list{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.badge_detail_user_list_member{
    width: 4.1667vw;
    margin-top: 2.2917vw;
    margin-left: 3.2813vw;
    text-align: center;
}

.badge_detail_user_list_member_img{
    width: 1.875vw;
    height: 1.8229vw;
    background: #1A1A1A;
    border-radius: 0.9375vw;
   
}

.badge_detail_user_list_member_name{
    font-size: 0.625vw;
    font-family: Roboto;
    font-weight: 500;
    color: #222222;
    margin-top: 0.2208vw;
}


.mbadge_detail_name{
    font-size: 4.8vw;
    font-family: Roboto;
    font-weight: bold;
    color: #222222 
}



.mbadge_detail_dec{
    font-size: 3.2vw;
font-family: Roboto;
font-weight: 400;
color: #4D4D4D;
width: 46.9333vw;
height: 21.8667vw;

}

.mbadge_detail_img{
    width:40vw;
    height: 40vw;
    font-size: 3.2vw;
    font-family: Roboto;
    font-weight: 400;
    color: #4D4D4D;
    object-fit: contain;
}

.mbadge_deail_but_box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 4.2667vw;
    padding-right: 4.2667vw;
    margin-top: 4vw;
}

.mbadge_detail_current_mint{
    width: 44.8vw;
    height: 8.5333vw;
    background: #279D8E;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4vw;
    font-family: Roboto;
    font-weight: 400;
    color: #F6F8F8;

}

.mbadge_detail_current_tw{
    width: 44.8vw;
    height: 8.5333vw;
    border: 2px solid #279D8E;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
     font-size: 4vw;
    font-family: Roboto;
    font-weight: 400;
    color: #279D8E;
}


.mbadge_detail_current {
    width: 100%;
    border-right: 1px solid #EBEBEB;
    display: flex;
    padding-left: 3.2vw;
    cursor: pointer;
}

.mbadge_detail_user_list{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-left: 3.3333vw;
    padding-right: 3.3333vw;
    margin-top: 9.2vw;
}

.mbadge_detail_user_list_member{
    width: 21.3333vw;
    justify-content:center;
    display: flex;
    flex-wrap: wrap;
}

.mbadge_detail_user_list_member_name{
    width: 100%;
    justify-content:center;
    display: flex;
}
