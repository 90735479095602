.my_post_body{
  width: 100%;

}


.my_post_item{
  margin-top: 0.5208vw;
  width: 60.4167vw;
  height: 5.4688vw;
  background: #FFFFFF;
  border: 1px solid #F5F5F5;
  box-shadow: 0px 4px 6px 0px rgba(207,209,208,0.35);
  border-radius: 0.5208vw;
  padding-left: 1.1458vw;
  padding-right: 5.2083vw;
}

.my_post_item_1{
  width: 100%;
  height: 3.1771vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.my_post_item_1_name{
  width: 30.4479vw;
  font-size: 1.25vw;
  font-family: Roboto;
  font-weight: bold;
  color: #222222;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}





.my_post_item_1_date{
  font-size: 0.7292vw;
  font-family: Roboto;
  font-weight: 400;
  color: #4D4D4D;
}




.my_post_item_end{
  width: 100%;
  height: 2.2396vw;
  display:flex;
  align-items: center;
  font-size: 14px;
font-family: Roboto;
font-weight: 400;
color: #F9BF6D; 


}

.my_post_item_end_text{
  color: #4D4D4D;
  margin-left: 10px;
}

.my_post_status{
  width: 3.9583vw;
  height: 1.25vw;
  border-radius: 0.3125vw;
}

.my_post_status_Rejected{
  background: #E87050;
  width: 3.9583vw;
  height: 1.25vw;
  border-radius: 0.3125vw;
  font-size: 0.7292vw;
  font-family: Roboto;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;

}

.my_post_status_Passed{
  background: #279D5C;
  width: 3.9583vw;
  height: 1.25vw;
  border-radius: 0.3125vw;
  font-size: 0.7292vw;
  font-family: Roboto;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my_post_status_Reviewing{
  background: #279D8E;
  width: 3.9583vw;
  height: 1.25vw;
  border-radius: 0.3125vw;
  font-size: 0.7292vw;
  font-family: Roboto;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my_post_status_Finished{
  background: #8CA6A3;
  width: 3.9583vw;
  height: 1.25vw;
  border-radius: 0.3125vw;
  font-size: 0.7292vw;
  font-family: Roboto;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my_post_status_Removed{
  background: #EBEBEB;
  width: 3.9583vw;
  height: 1.25vw;
  border-radius: 0.3125vw;
  font-size: 0.7292vw;
  font-family: Roboto;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mmy_post_body{
  width: 100%;
}


.mmy_post_item{
  width: 100%;
  height: 32vw;
  background: #FFFFFF;
  border-radius: 3.2vw;
  padding-left: 2.6667vw;
  margin-top: 2vw;
}

.mmy_post_item_1_name{
  font-size: 4vw;
  font-family: Roboto;
  font-weight: bold;
  color: #222222;
}



.mmy_post_status_Rejected{
  background: #E87050;
  width: 22.1333vw;
  height: 4.8vw;
  border-radius: 0.8vw;

  font-size: 0.7292vw;
  font-family: Roboto;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;

}

.mmy_post_status_Passed{
  background: #279D5C;
  width: 22.1333vw;
  height: 4.8vw;
  border-radius: 0.8vw;
  font-size: 0.7292vw;
  font-family: Roboto;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mmy_post_status_Reviewing{
  background: #279D8E;
  width: 22.1333vw;
  height: 4.8vw;
  border-radius: 0.8vw;
  font-size: 0.7292vw;
  font-family: Roboto;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mmy_post_status_Finished{
  background: #8CA6A3;
  width: 22.1333vw;
  height: 4.8vw;
  border-radius: 0.8vw;
  font-size: 0.7292vw;
  font-family: Roboto;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mmy_post_status_Removed{
  background: #EBEBEB;
  width: 22.1333vw;
  height: 4.8vw;
  border-radius: 0.8vw;
  font-size: 0.7292vw;
  font-family: Roboto;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}


.mdonate_num_detail{
  width: 100%;
  display: flex;
  align-items: center;
  height: 8.6667vw;
}


.mdonate_num{
  width: 26.6667vw;
  font-size: 3.2vw;
  font-family: Roboto;
  font-weight: bold;
  color: #222222;
}
.mdonate_step{
  width: 42.6667vw;
  height: 1.3333vw;
  background: #E0E3E0;
  border-radius: 0.6667vw;
}


.mdonate_step_bule{
  height: 1.3333vw;
background: #52A553;
border-radius: 0.6667vw;
}

.mmy_post_but{
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: space-between;
 padding-right: 10.9333vw;
}

.mmy_post_delist{
  width: 3.2vw;
  height: 3.2vw;
}


.mmy_post_left_but{
  width: 26px;
  height: 1.3333vw;
}

.mmy_post_item_REASON{
  font-size: 3.2vw;
  color: rgba(249, 191, 109, 1);
}

.mmy_post_item_end_text{
  font-size: 3.2vw;
  font-family: Roboto;
  font-weight: 400;
  color: #F9BF6D;
}



