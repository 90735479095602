
.SendForum_ch_body{
  width: 100%;
  min-height: 2.5vw;
  background: #FAFAFA;
  border: 1px solid #EBEBEB;
  border-radius: 1.25vw;
  padding-left: 1.0417vw;
 
}

.SendForum_ch_input{
  width:100%;
  font-size: 16px;
font-family: Roboto;
font-weight: bold;
color: #222222;

}

.SendForum_ch_content{
border-radius: 2px;
margin-top: 0.5208vw;
}

.SendForum_ch_img{
  margin-top: 10px;
}

.SendForum_ch_tags{
  display: flex;
  align-items: center;
  margin-top: 0.3208vw;
}

.SendForum_ch_submit{
  width: 3.4375vw;
  height: 1.6667vw;
  background: #419D41;
  border-radius: 0.3125vw;
  font-size: 0.8333vw;
  font-family: Roboto;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1vw;
  cursor: pointer;
  margin-bottom: 2.0833vw;
}


.send_write_c{
width: 1.25vw;
height: 1.1979vw;
}