
.donateContent{
  width: 100%;
  background: #FFFFFF;
  padding-bottom: 1.5625vw;
}


.flex_width100_center{
  display: flex;
  width: 100%;
  justify-content: center;
}

.flex_width100{
  display: flex;
  width: 100%;
  align-items: center;
}



.donate_banner{
height: 420px;
width: 100%;
}


.m_top_32{
  margin-top: 1.6667vw;
}

.donate_list_item_cld_num{
  margin-top: 0.8333vw;
  padding-left:0.5208vw;
  padding-right:0.5208vw;
}




.donate_search{
  display:flex;
  align-items: center;
  width: 68.75vw;
  height: 2.2917vw;
  background: #FFFFFF;
  border: 1px solid #F5F5F5;
  box-shadow: 0px 6px 8px 0px rgba(207,209,208,0.35);
  border-radius: 1.6667vw;
  font-size: #000000;
  padding-right: 0.5208vw;
  }

  .donate_list_box{
    margin-top: 2.1875vw;
    width: 100%;
    padding-left: 5.2083vw;
    padding-right: 5.2083vw;
  }

  .donate_list{
   width: 100%;
   display: flex;
   flex-wrap: wrap;
   margin-top: 0.5208vw;
  }




.donate_list_item{
  width: 13.5417vw;
  background: #FFFFFF;
  box-shadow: 0px 4px 6px 0px rgba(207,209,208,0.35);
  border-radius: 0.5208vw;
  margin-top: 2.0833vw;
  padding-bottom: 0.5208vw;
}
  .donate_list_title{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .donate_list_title_1{
    font-size: 1.875vw;
    font-family: Roboto;
    font-weight: 900;
    text-decoration: underline;
    color: #222222;
  }

  .donate_list_title_2{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5.2083vw;
    height: 1.875vw;
    border: 1px solid #EBEBEB;
    border-radius: 0.3125vw;
    font-size: 0.8333vw;
    font-family: Roboto;
    font-weight: 500;
    color: #222222;
  }


  .donate_list_item_cld_img{
    width: 13.5417vw;
    height: 9.5313vw;
  }

  .donate_list_item_cld_name{
    padding-left: 0.3333vw;
    font-size:  0.8333vw;
    font-family: Roboto;
    font-weight: bold;
    color: #222222;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 10.2083vw;
    margin-top: 0.5333vw;
    margin-bottom: 0.3646vw;
    
  }

  .donate_list_item_cld_name_desc{
    font-size: 0.65vw;
    font-family: Roboto;
    color: #595959;
    width: 100%;
    min-height: 3.1667vw;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    padding-left: 10px;
    padding-right: 10px;
    
  }


  .mdonate_list_item_cld_name_desc{
    font-size: 16px;
    font-family: Roboto;
    color: #595959;
    width: 100%;
    min-height: 14vw;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    padding-left: 10px;
    padding-right: 10px;
    
  }



.donate_num{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 10.5vw;
  color: black;
}


.cdonate_num{
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
  width: 40vw;
  color: #52A553;
}


  .donate_step{
    width: 6.25vw;
    height: 0.5208vw;
    background: #cfc8c8;
    border-radius: 0.2604vw;
    padding-left: 0.0667vw;
  }

  .donate_step_bule{
    height: 0.5208vw;
    background: #52A553;
    border-radius: 0.2604vw;
  }
  
  .donate_search_img{
    width: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .donate_banner_box{
    background-size:100% 100%;  
    width: 100%;
    height: 400px;
  }



  .donate_box{
    padding-top: 8vw;
    padding-left: 3.3333vw;
    width: 100%;
    padding-right: 3.3333vw;
  }

  .mdonate_banner{
    width: 100%;
  }

  
  .mdonate_banner_box{
    background-size:100% 100%; 
    background-repeat: repeat;
    width: 100%;
    height: 40vw
  }


  .mdonate_list_box{
    width: 100%;


  }

  .mdonate_list{
    width: 100%;
    margin-top: 1vw;
  
  }

  .cdonate_list_item{
    width: 100%;
    background: #FFFFFF;
    border: 2px solid #EBEBEB;
    box-shadow: 0px 2px 6px 0px rgba(246,248,248,0.19);
    border-radius: 2.25vw;
    padding-bottom: 2.6667vw;
    margin-top: 1.3333vw;
  }

  .cdonate_list_item_cld_img{
    width: 100% !important;
    height: 65.3333vw !important; 
    object-fit: contain;
    border-radius: 6.0667vw;
  }

  .cdonate_list_item_cld_num{
    width: 100%;
    padding-left: 2.6667vw;
  }


  .cdonate_list_title{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  .cdonate_list_title_1{
    font-size: 3.4667vw;
    font-family: Roboto;
    font-weight: 900;
    text-decoration: underline;
    color: #222222;
  }

  .cdonate_list_title_2{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18vw;
    height: 8.6667vw;
    border-radius: 1.5125vw;
    font-size: 3.4667vw;
    font-family: Roboto;
    font-weight: 500;
    color: #222222;
    border: 1px solid #D1D1D1;

  }


  .cdonate_list_item_cld_name{
    font-size:16px;
    font-family: Roboto;
    font-weight: bold;
    color: #222222;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 40vw;
    margin-top: 0.5333vw;
    padding-left: 2.6667vw;
    
    
  }


 

  
/* 
  .mproject_box_item_step{
    width: 42.6667vw;
  height: 1.3333vw;
  background: #F5F5F5;
  border-radius: 0.6667vw;
  padding-left: 2vw;
  }
  
  .mproject_box_item_step_bule{
    height: 1.3333vw;
    background: #52A553;
    border-radius: 0.6667vw;
  } */

  .mdonate_list_box{
    margin-top: 2.1875vw;
    width: 100%;
    padding-right: 5.2083vw;
    background: #FFFFFF;
  }


