.pmint_body{
width: 100%;
padding-left: 23.9583vw;
background: linear-gradient(-45deg, #F4F7F3 0%, #F5F6F4 100%);
padding-bottom: 1.0417vw;
}


.pmint_box{
    width: 52.0833vw;
    background: #FFFFFF;
    border-radius: 1.4583vw; 
    padding-top: 2.5vw;
    padding-left: 5.6771vw;
    padding-bottom: 1.0417vw;
}


.pmint_flex{
   display: flex;
    align-items: center;
margin-bottom: 1.6667vw;
}


.pmint_lable{
    width: 8.4896vw;
    margin-right: 1.1458vw;
    font-size: 0.8333vw;
font-family: Roboto;
font-weight: 400;
color: #222222;
}


.pmint_preview{
    width: 17.7083vw;
    height: 2.5vw;
    border: 1px solid #3DA16B;
    border-radius: 0.3125vw;  
    font-size: 1.25vw;
    font-family: Roboto;
    font-weight: bold;
    color: #3DA16B;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}


.pmint_pint{
    width: 17.7083vw;
    height: 2.5vw;
    background: #3DA16B;
    border-radius: 0.3125vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25vw;
    font-family: Roboto;
    font-weight: bold;
    color: #FFFFFF;
    margin-left: 2.0833vw
}


.pmint_name{
    font-size: 1.875vw;
    font-family: Roboto;
    font-weight: 900;
    text-decoration: underline;
    color: #222222;
}

.pmint_ming_name{
    margin-top: 2.6042vw;
    height: 2.125vw;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}



.lable_red{
 color: red;
 margin-right: 0.2208vw;
 font-size: 0.8333vw;
 font-weight: bold;
}