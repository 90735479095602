.project_body{
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    background: #FFFFFF ;
    padding-top: 1.0417vw;
}

.project_left{
    width: 65.7813vw;
    padding-left: 13.5937vw;
}

.project_right{
    width: 34.2188vw;
    padding-left: 2.0833vw;
    max-height: 68.5vw;
    overflow: auto;
}



.project_step{
    width: 19.7917vw;
    height: 0.9896vw;
    background: #F1F5F4;
    border-radius: 0.5208vw;
    margin-left: 1.0417vw;
}


.project_step_blue{
    height: 0.9896vw;
    background: #52A553;
    border-radius: 0.5208vw;
}



.project_title{
    font-size: 1.875vw;
    font-family: Roboto;
    font-weight: 900;
    color: #222222;
    width: 48.7813vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

}


.mpersoner_detail{
  font-size: 2.333vw;
  font-family: Roboto;
  font-weight: 400;
  color: #222222;
  display: flex;
  align-items: center;
  margin-top: 1.0417vw;
}

.personer_detail{
    font-size: 0.8333vw;
    font-family: Roboto;
    font-weight: 400;
    color: #222222;
    display: flex;
    align-items: center;
    margin-top: 1.0417vw;
}

.project_share_img{
    width: 1.25vw;
    height: 1.25vw;
    margin-left: 0.8854vw;
    cursor: pointer;
}

.project_box{
    width: 2.4479vw;
    height: 1.4583vw;
    background: #f0eeee;
    border-radius: 0.2083vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.7292vw;
    font-family: DIN;
    font-weight: 400;
    color: #292929 !important;

}

.project_box_2{
    width: 1.25vw;
    height: 1.4583vw;
    background:  #f0eeee;
    border-radius: 0.2083vw;
    display: flex;
    justify-content: center;
    align-items: center;
}



.project_donate{
    height: 1.4583vw;
    background: rgba(191, 253, 192, 0.3);
    border-radius: 0.2083vw;
    margin-left: 0.7813vw;
    display: flex;align-items: center;
    cursor: pointer;
    font-size: 0.9375vw;
    font-family: Roboto;
    font-weight: bold;
    color: #52A553;
    padding-left: 0.5208vw;
    padding-right: 0.5208vw;
}


.personer_detail_time{
    margin-left: 2.6042vw;
}

.doination_drewer{
    width: 100%;
    display: flex;
    justify-content: center;
}

.donation_box{
    width: 18.75vw;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    padding-bottom: 1.5625vw;
    margin-top: 2.1042vw;
    font-size: 1.25vw;
    font-family: Roboto;
    font-weight: bold;
    color: #222222;
}

.donaton_amont{
    font-size: 12px;
font-family: Roboto;
font-weight: 400
}
.donaton_name{
display: flex;
justify-content: center;
width: 100%;
margin-bottom: 4.0625vw;

}

.donaton_enter{
    font-size: 0.8333vw;
    font-family: Roboto;
    font-weight: 400;
    color: #1A1A1A;
    margin-bottom: 0.5208vw;
}


.donation_but{
    width: 18.75vw;
    height: 2.5vw;
    background: #52A553;
    border-radius: 0.3125vw;
    font-size: 1.25vw;
    font-family: Roboto;
    font-weight: 900;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.0417vw;
    cursor: pointer;
}





.project_text_desc{
    font-size: 1.0333vw;
font-family: Roboto;
font-weight: 400;
color: #222222;
margin-top: 1.9792vw;
}


.project_options{
    display: flex;
    align-items: center;
    font-size: 1.0417vw;
    font-family: Roboto;
    font-weight: bold;
    color: #222222;
    margin-top: 1.5625vw;
    width: 100%;
    justify-content: space-between;
}

.project_options_1{
    width: 70%;
    display: flex;
    align-items: center;
}



.project_options_2{
    width: 30%;
    display: flex;
    align-items: center;
}


.project_comment_box{
    width: 100%;
    min-height: 41.4583vw;
    background: #FFFFFF;
    border: 0.0521vw solid #E0E0E0;
    border-radius: 0.1042vw;
    margin-top: 5.0521vw;
    padding: 1.3542vw;
    margin-bottom: 1.5625vw;
}

.project_remove{
  cursor: pointer;
    font-size: 0.7292vw;
font-family: Roboto;
font-weight: 400;
color: #D1D1D1;
display: flex;
align-items: center;
width: 12.5vw;
height: 2.3438vw;
background: #FFFFFF;
border-right: 1px solid #F5F5F5;
border-left: 1px solid #F5F5F5;

justify-content: center;
}


.project_feedback{
font-size: 1.875vw;
font-family: Roboto;
font-weight: 900;
text-decoration: underline;
color: #222222;
}

.project_comment_msg{
    height: 28.125vw;
    overflow: auto;
}

.project_comment_input{
    border: 0.0521vw solid #E0E0E0;
    border-radius: 0.1042vw;
    width: 47.3958vw;
    height: 8.6458vw;
    background: #F9FAF8;
    display: flex;
}

.send_but_box{
    display: flex;
    align-items: flex-end;
    padding-bottom: 0.5208vw;
    padding-left: 1.5625vw;
}


.send_but{
    width: 4.1667vw;
    height: 30px;
    background: #52A553;
    border-radius: 0.1042vw;
    font-size: 0.8333vw;
    font-family: Roboto;
    font-weight: 400;
    color: #F2F8F1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}


.member_logo{
    width: 2.1823vw;
    height: 2.1823vw;
}

.comment_item{
    border-bottom: 0.0521vw solid #E0E0E0;
    width: 47.4792vw;
    padding-top: 0.5208vw;
    padding-bottom: 0.5208vw;
}

.comment_item_1{
    display: flex;
    align-items: center;
}

.comment_item_1_1{
    margin-left: 0.5208vw;
}

.comment_item_2{
    margin-left: 2.6042vw;
    width: 36.4583vw;
    word-break: break-all;
}

.comment_item_3{
    margin-left: 2.6042vw;
}

.mcomment_item_1_name{
  margin-left: 2vw;
}

















.projectflex{
    display: flex;
    width: 100%;
}

.project_tren_text{
    font-size: 0.5208vw;
    font-family: Roboto;
    font-weight: 300;
    color: #595959;
}


.project_status{
    font-size: 0.8333vw;
    font-family: Roboto;
    font-weight: 400;
    color: #ADADAD;
}

.project_status_active{
    font-size: 1.875vw;
    font-family: Roboto;
    font-weight: 900;
    text-decoration: underline;
    color: #222222;
}


.project_Reading{
    font-family: Roboto;
  font-weight: 400;
  text-decoration: underline;
  color: #419D41;
  }


  .project_token{
    display: flex;
    font-size: 1.0417vw;
    font-family: Roboto;
    font-weight: bold;
    color: #222222;
  }


  .donation_receivingToken{
    width: 5.7292vw;
    background: #FFFFFF;
    border-radius: 0.3125vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .p_user_detail_1{
    display: flex;
    width: 100%;
    align-items: center;
  }

  /* .p_user_detail_Follow{
    font-size: 0.8333vw;
    font-family: Roboto;
    font-weight: 500;
    color: #222222;
    margin-left: 2.0646vw;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 2.125vw;
  }
  .p_user_detail_chat{
    height: 2.125vw;
    font-size: 0.8333vw;
    font-family: Roboto;
    font-weight: 500;
    color: #222222;
    margin-left: 2.0646vw;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 2.125vw;
  } */




  .p_user_detail_2{
    min-height: 4.7396vw;
     width: 15.625vw ;
     font-size: 0.6292vw;
    font-family: Roboto;
    font-weight: 400;
    color: #222222;
    cursor: pointer;
    padding: 0.2604vw;
  }

.project_relevant{
    padding-top: 20px;

}
  .project_reading_item{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1.5625vw;
    padding-right: 3.6458vw;
    margin-bottom: 0.5208vw;
    height: 4.2188vw;

    border-bottom: 1px  dashed #cdd1cb ;
  }

  .project_reading_item_img{
    width: 2.1875vw;
    height: 2.1875vw;
    border-radius: 100%;
  }


  .project_reading_item_2{
    width: 6.0417vw;
    font-size: 0.8333vw;
    font-family: Roboto;
    font-weight: 400;
    color: #222222;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .project_reading_item_3{
    width: 12.4167vw;
    font-size: 0.8333vw;
    font-family: Roboto;
    font-weight: 400;
    color: #222222;
  }

.project_reading_item_4{
    width: 8.4167vw;
    font-size: 0.625vw;
    font-family: Roboto;
    font-weight: 400;
    color: #222222;
}

.project_reading_item_5{
    width: 8.125vw;
    font-size: 0.625vw;
    font-family: Roboto;
    font-weight: 400;
    color: #0784c3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
        cursor: pointer;
}

.project_right_list{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}
.project_tw_list{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    overflow: auto;
}

.project_tw{
    width: 100%;
    font-size: 0.625vw;
    margin-top: 0.5208vw;
    padding-right: 6.125vw

}


.project_rate{
    width: 48.2292vw;
height: 1.25vw;
background: #FAFAFA;
border-radius: 0.625vw;
font-size: 0.625vw;
font-family: Roboto;
font-weight: 400;
color: #808080;
display: flex;
justify-content: center;
margin-top: 1.1979vw;
align-items: center;

}

.success-img{
    background-image: url("../../assets/v2/success.png");
    background-size:100% 100%;
    width: 33.3333vw;
    height: 33.3333vw;
    display: flex;justify-content: center;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 400;
    flex-wrap: wrap;
}


.failed-img{
    background-image: url("../../assets/v2/failed.png");
    background-size:100% 100%;
    width: 33.3333vw;
    height: 33.3333vw;
    display: flex;justify-content: center;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 400;
    flex-wrap: wrap;
}










.donationMoney{
    margin-top: 15.625vw;
}
.donationMoney_num{
    color: rgba(39, 157, 92, 1);
}

.donationMoney_but{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-family: Roboto;
    font-weight: 400;
    width: 14.5833vw;
    height: 2.5vw;
    background: #279D5C;
    border-radius: 0.8333vw;
    cursor: pointer;
    color: #F1F5F4;
}



.donationMoney_fail_but{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-family: Roboto;
    font-weight: 400;
    width: 14.5833vw;
    height: 2.5vw;
    background: rgba(232, 112, 80, 1);
    border-radius: 0.8333vw;
    cursor: pointer;
    color: #F1F5F4;
}






#project[mode=web] .options_box{
    width: 8.0729vw;
    height: 1.4583vw;
    background: rgba(240, 240, 240,0.5);
    border-radius: 0.2083vw;
    display: flex;
    align-items: center;
    justify-content: space-between;

  }


  #project[mode="web"] .dis_sc{
    cursor: pointer;
    width: 123px;
    height: 28px;
    background: #F0F0F0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }


  #project[mode=web] .options_box_flex{
    cursor: pointer;
    width: 8.0729vw;
    height: 1.0583vw;
    align-items: center;
    display: flex;
    align-items: center;
    color: rgb(107, 104, 104);
    font-size: 0.7292vw;
    font-family: DIN;
    justify-content: center;
  }

  #project[mode=web] .options_box_num{
    margin-left: 0.204vw;

  }
  #project[mode=web] .options_box_flex_border{
    cursor: pointer;
    border-right: 1px solid rgba(240, 240, 240,1);
  }




  #project[mode="web"] .dis_list_content{
    font-size: 1.05vw;
    font-family: Roboto;
    font-weight: 300;
    color: #595959;
    width: 49.8438vw;
    min-height: 3.1667vw;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }


  .mproject_box{
    padding-top: 8vw;
    padding-left: 3.3333vw;
    width: 100%;
    padding-right: 3.3333vw;
  }

  .mproject_name{
    width: 100%;
    font-size: 4vw;
    font-family: Roboto;
    font-weight: 900;
    color: #2B2F29;
  }

  .mproject_text_desc{
    width: 100%;
    font-size: 3.7333vw;
    font-family: Roboto;
    font-weight: 400;
    color: #222222;
  }


  .mproject_img{
    display: flex;
    flex-wrap: wrap;
    margin-top: 6.6667vw;
  }

  .mproject_donate{
    width: 100%;
    display: flex;
    align-items: center;
    height: 10.6667vw;
    flex-wrap: wrap;
  }



  .moptions_box{
    display: flex;
    align-items: center;
    width: 43.7333vw;
    align-items: center;
    height: 8vw;
  }


  .moptions_box_flex{
    display: flex;
    justify-content: center;
    margin-left: 2.6667vw;
    align-items: center;
  }

  .moptions_box_num{
    font-size: 3.2vw;
    font-family: DIN;
    font-weight: 500;
    color: #292929
  }

  .moptions_box_img{
    width: 4.8vw;
    height: 4.2667vw;
    margin-right: 0.6667vw;
  }


  .mproject_donate_but{
    width: 17.6vw;
    height: 6.4vw;
    background: #52A553;
    border-radius: 2.4vw;
    font-size: 3.2vw;
    font-family: Roboto;
    font-weight: 500;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .mproject_comment_input_text{
    width: 100%;
    height: 23.7333vw !important;
    padding-left: 0.6667vw;
    padding-right: 0.6667vw;
  }



  .mproject_comment_input{
    width: 100%;
    height: 32.8vw;
    background: #FAFAFA;
    border: 2px solid #EBEBEB;
    border-radius: 1.0667vw;
  }


  .msend_but_box{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-right: 0.6667vw;
  }

  .msend_but{
    width: 11.7333vw;
    height: 6.4vw;
    background: #279D8E;
    border-radius: 3.2vw;
    font-size: 3.4667vw;
    font-family: Roboto;
    font-weight: 400;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mproject_comment_msg{
    width: 100%;
  }

  .mcomment_item{
    width: 100%;
    padding-left: 1.3333vw;
  }

  .mcomment_item_1,.mcomment_item_3{
    width: 100%;
    display: flex;
    align-items: center;
  }


  .mcomment_item_3{
    padding-left: 11.2vw;
  }

  .mmember_logo{
    width: 6.4vw;
    height: 6.4vw;
    border-radius: 50%;
  }


  .mcomment_item_3_tag{
    font-size: 3.2vw;
    font-family: Roboto;
    font-weight: 400;
    color: #4D4D4D;
    margin-left: 4vw;
  }

  .mcomment_item_content{
    height: 12vw;
    width: 100%;
    font-weight: 500;
    font-size: 3.4667vw;
    color: #4D4D4D;
    padding-left: 11.2vw;
  }

.mproject_nft_img{
   width: 40vw;
   height: 40vw;
   object-fit: contain;

}


