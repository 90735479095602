

.badge_body{
    width: 100%;
    padding-left: 5.2083vw;
    padding-right: 5.2083vw;
    padding-top: 1.5625vw;
}

.badge_your{
    width: 89.5833vw;
    height: 6.25vw;
    background: #FAFAFA;
    border: 1px solid #EBEBEB;
    border-radius: 0.3125vw; 
    display: flex;
    align-items: center;
}

.badge_options{
  width: 90%;
  margin-top: 0.5208vw;

}

.badge_options_tags_but{
    width: 100%;
    display: flex;
    align-items: center;
  }


.badge_box_group{
    margin-top: 1.5625vw;
}

.badge_box{
    width: 89.5833vw;
    border-top: 0.0521vw solid #EBEBEB;
    min-height: 41.6667vw;
    padding-bottom: 1.0417vw;
    margin-top: 0.5208vw;
}

.badge_box_group_text{
    font-size: 0.8333vw;
    font-family: Roboto;
    color: #2B2F29;
}

.badge_box_group_item{
    display: flex;
    width: 12.5vw;
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-radius: 0.3125vw;
    flex-wrap: wrap;
    cursor: pointer;
    margin-right: 1.0417vw;
  
}

.badge_box_group_item_img{
    width: 100%;
    height:10.4167vw;
    object-fit: cover;
}


.badge_box_group_item_content{
    width: 100%;
    padding-left: 0.625vw;
    margin-bottom: 0.5208vw;
}

.badge_box_group_item_content_name{
    font-size: 1.0417vw;
font-family: Roboto;
font-weight: 500;
color: #222222;
}

.badge_box_group_item_content_text{
    font-size: 0.625vw;
    font-family: Roboto;
    color: #808080;
    height: 2.0833vw;
}
.badge_box_group_item_but{
    display: flex;
    width: 100%;

}

.badge_box_group_item_polygon{
 width: 65%;
 border-right: 0.0521vw solid #EBEBEB;;
}

.badge_box_group_item_tw{
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
   }

   .badge_box_group_item_tw_img{
    width: 1.0417vw;
height: 0.8854vw
   }

.badge_box_group_item_polygon_img{
width: 6.25vw;
height: 1.3542vw;
}


.badge_box_group_item_list{
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.1979vw;
}

.badge_options_seacher_all{
    width: 2.8667vw;
    height: 1.708vw;
    background: #EBEBEB;
    border-radius: 0.3125vw;
    margin-right: 0.5208vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.625vw;
    font-family: Roboto;
    font-weight: 500;
    color: #222222;
    cursor: pointer;
}



.badge_options_seacher{
    width: 50%;
    display: flex;
    align-items: center;
}

.badge_options_seacher_box{
    width: 33.3333vw;
    height: 1.875vw;
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-radius: 0.3125vw;
    display: flex;
    align-items: center;
}

.badge_box_group_item_filters{
   width: 1.0417vw;
   height: 0.625vw;  
   margin-right: 0.5208vw;

}

.badge_options_seacher_filters{
    width: 6.0417vw;
    height: 1.875vw;
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-radius: 0.3125vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1.0417vw;
    cursor: pointer;
    padding-left: 1.3333vw;
    padding-right: 1.3333vw;
}

.badge_your_current_box{
    width: 18.75vw;
    height: 4.6875vw;
    background: #FFFFFF;
    border-radius: 0.3125vw;
    
}

.badge_your_carousel{
    width: 29.1667vw ;
}

.badge_your_current{
    width: 29.1667vw ;
    height: 4.6875vw;
    border-right: 1px solid #EBEBEB;
    display: flex;
    padding-left: 1.5625vw;
    cursor: pointer;
}

.badge_your_list{
    width: 60.3646vw;
    padding-left: 10.0521vw;
    display: flex;
    align-items: center;
}

.badge_your_list_img{
    width: 4.2188vw;
    height: 3.2292vw;
    object-fit: cover;
    margin-right: 0.5208vw;
    cursor: pointer;
}


.badge_your_current_left{
  width: 6.25vw;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.06);
}

.badge_your_current_right{
    width: 100%;
    padding-left: 0.5208vw;
}

.badge_your_current_left_img{
    width: 6.25vw;
    height: 3.125vw;
    object-fit: contain;
}

.badge_your_current_left_name{
    font-size: 0.7292vw;
    font-family: Roboto;
    font-weight: 500;
    color: #222222;
    margin-left: 0.3646vw;
    display: flex;
    justify-content: center;
    width: 100%;
}

.badge_your_current_right_token{
    font-size: 0.8333vw;
    font-family: Roboto;
    font-weight: bold;
    color: #279D8E;
    margin-top: 0.4625vw;
}


.badge_your_img_list{
    width: 26.0417vw; 
    display: flex;
    margin-left: 0.7813vw;
}

.badge_your_list_view{
    margin-left: 4.1667vw;
    width: 3.75vw;
    height: 3.75vw;
    background: #FFFFFF;
    box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.06);
    border-radius: 0.625vw;
    font-size: 0.8333vw;
    font-family: Roboto;
    font-weight: 500;
    color: #222222;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    cursor: pointer;
}
.badge_your_list_view_1{
    width: 3.0417vw;
    text-align: center;
    color: #222222;
}

.badge_your_current_right_go{
    width: 3.125vw;
    height: 1.25vw;
    background: #EDF5F4;
    border-radius: 0.3125vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 500;
    color: #279D8E;
    margin-right: 2.5625vw;
    cursor: pointer;
}


.badge_your_current_right_go_img{
    width: 0.4167vw;
    height: 0.4167vw;
    margin-left: 0.5417vw;
}
.badge_your_current_but{
    display: flex;
}




.mbadge_your_carousel{
width: 100%;
margin-top: 9.3333vw;
}


.mbadge_your_current{
    width: 100% ;
    height: 30.1333vw;
    border-right: 1px solid #EBEBEB;
    display: flex;
    padding-left: 3.2vw;
    cursor: pointer;
}

.mbadge_your_current_left{
    width: 40vw;
    height: 30.1333vw;
    background: #FFFFFF;
    border: 2px solid #EBEBEB;
    box-shadow: 0px 2px 6px 0px rgba(246,248,248,0.19);
    border-radius: 3.2vw;
  }



  .mbadge_your_current_left_img{
    width: 40vw;
    height: 20vw;
    border-radius: 24px 24px 0px 0px;
}


.mbadge_your_current_left_name{
    font-size: 3.8vw;
    height: 10.1333vw;
    font-family: Roboto;
    font-weight: bold;
    color: #222222;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.mbadge_your_current_right{
    width: 56.8vw;
    height: 30.1333vw;
    padding-left: 2.6667vw;
}

.mbadge_your_current_right_text{
    font-size: 3.2vw;
font-family: Roboto;
font-weight: 400;
color: #222222;
}
.mbadge_your_current_right_token{
    font-size: 4vw;
font-family: Roboto;
font-weight: bold;
color: #279D8E;
}

.mbadge_your_current_step{
    width: 100%;
    margin-top: 1.3333vw;
    padding-left: 0.2604vw;
}

.donate_list_item_cld_num{
    width: 60%;
}

.mdonate_list_item_cld_num{
    width: 42.6667vw;
    height: 1.6vw;
    background: #E4EBEA;
    border-radius: 0.8vw;
}

.mdonate_list_item_cld_num_step{
    height: 1.6vw;
    background: #279D8E;
    border-radius: 0.8vw;
}

.mbadge_your_current_but{
    margin-top: 4vw;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-right: 8.4vw;
}

.mbadge_your_current_right_go{
    width: 20.2667vw;
    height: 8.5333vw;
    background: #FAFDFD;
    border: 2px solid #CFE5E4;
    border-radius: 3.2vw;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.mbadge_your_current_right_go_img{
    width: 2.6667vw;
    height: 2.6667vw;
    margin-left: 1.3333vw;
}


.mbadge_box_group_item_list{
    width: 100%;
}

.mbadge_box_group_item{
    width: 100%;

    background: #FFFFFF;
border: 2px solid #EBEBEB;
box-shadow: 0px 2px 6px 0px rgba(246,248,248,0.19);
border-radius: 3.2vw;
padding-bottom: 2.6667vw;
margin-top: 2.6667vw;
}

.mbadge_box_group_item_img{
    height: 90.2667vw;
    width: 100%;
    object-fit:contain
}

.mbadge_box_group_item_content{
    width: 100%;
    padding-left: 2.6667vw;
}

.mbadge_box_group_item_content_name{
    font-size: 4.8vw;
font-family: Roboto;
font-weight: bold;
color: #222222;
}

.mbadge_box_group_item_content_text{
    font-size: 3.4667vw;
    font-family: Roboto;
    font-weight: 400;
    color: #808080;
}

.mbadge_box_group_item_but{
    width: 100%;
    display: flex;
}

.mbadge_box_group_item_polygon{
    width: 60%;
}

.mbadge_box_group_item_tw{
    width: 4.6667vw;
    height: 1.5625vw;
    border-radius: 0.3125vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.625vw;
    font-family: Roboto;
    font-weight: 400;
    color: #279D8E;
    margin-right: 0.5208vw;
    border: 0.0521vw solid #279D8E;
}

.mbadge_box_group_item_tw_img{
    width: 6.4vw;
    height: 5.4667vw;
}

.mbadge_claim{
    width: 4.6667vw;
    height: 1.5625vw;
    background: #279D8E;
    border-radius: 0.3125vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.625vw;
    font-family: Roboto;
    font-weight: 400;
    color: #FAFAFA;
    margin-right: 0.5208vw;
}

.mbadge_your_current_claim{
    width: 20.2667vw;
    height: 8.5333vw;
    background: #279D8E;
    border: 2px solid #CFE5E4;
    border-radius: 3.2vw;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #FAFAFA;
}

.badgemint_only{
    width: 18.75vw;
    height: 2.5vw;
    background: #F9F9F9;
    border: 1px solid #EBEBEB;
    border-radius: 0.3125vw;  
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.0417vw;
    font-family: Roboto;
    font-weight: 400;
    color: #595959;
    margin-top: 1.0417vw;
    cursor: pointer;
 }


 .badgemint_only_active{
    background: #F9F9F9;
    border: 1px solid #279D8E;
    font-size: 1.0417vw;
    font-family: Roboto;
    font-weight: 400;
    color: #279D8E;
 }



 .badgemintBut{
    width: 18.75vw;
    height: 2.5vw;
    background: #279D8E;
    border-radius: 0.3125vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.0417vw;
    font-family: Roboto;
    font-weight: 400;
    cursor: pointer;
    color: #FFFFFF;
 }


 .mint_success-img{
    background-image: url("../../assets/v2/mint_nft_success.png");
    background-size:100% 100%;
    width: 33.3333vw;
    height: 33.3333vw;
    display: flex;justify-content: center;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 400;
    flex-wrap: wrap;
    padding-top: 23.4167vw;
}


.mint_failed-img{
    background-image: url("../../assets/v2/mint_nft_fail.png");
    background-size:100% 100%;
    width: 33.3333vw;
    height: 33.3333vw;
    display: flex;justify-content: center;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 400;
    flex-wrap: wrap;
    padding-top: 23.4167vw;
}

.m_mint_success-img{
    background-image: url("../../assets/v2/m_min_nft_success.png");
    background-size:100% 100%;
    width: 100%;
    height: 100.8667vw;
    display: flex;justify-content: center;
    font-family: Roboto;
    font-weight: 400;
    flex-wrap: wrap;
    padding-top: 60.6667vw;
}


.m_mint_failed-img{
    background-image: url("../../assets/v2/m_mint_nft_fail.png");
    background-size:100% 100%;
    width: 100%;
    height: 100.8667vw;
    display: flex;justify-content: center;
    font-family: Roboto;
    font-weight: 400;
    flex-wrap: wrap;
    padding-top: 60.6667vw;
}






.mbadgemint_popup{
    width: 100%;
    height: 100.8667vw;
    background: #FFFFFF;
    border-radius: 3.2vw 3.2vw 0px 0px;
    overflow: auto;
  }






  .mbadgemint_only{
    width: 85.3333vw;
    height: 11.7333vw;
    background: #F9F9F9;
    border: 1px solid #EBEBEB;
    border-radius: 1.3125vw;  
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4vw;
    font-family: Roboto;
    font-weight: 400;
    color: #595959;
    margin-top: 1.0417vw;
    cursor: pointer;
 }


 .mbadgemint_only_active{
    background: #F9F9F9;
    border: 1px solid #279D8E;
    font-family: Roboto;
    font-weight: 400;
    color: #279D8E;
 }



 
 .mbadgemintBut{
    width: 85.3333vw;
    height: 11.7333vw;
    background: #279D8E;
    border-radius: 1.3125vw;  
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4vw;
    font-family: Roboto;
    font-weight: 400;
    cursor: pointer;
    color: #FFFFFF;
 }