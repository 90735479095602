#footer[mode=web]  {
  width: 100%;
  display: flex;
  justify-content: center;
  opacity: 0.78;
  background: linear-gradient(-45deg, #C4CFD2 0%, #C4CFD2 100%);
  position: fixed;
  bottom: 0px;
}


#footer[mode=mobile]  {
  width: 100%;
  display: flex;
  opacity: 0.78;
  align-items: center;
  height: 12vw;
  background: linear-gradient(-45deg, #C4CFD2 0%, #C4CFD2 100%);
  position: fixed;
  bottom: 0vw;
}


@media (min-width: 1920px) {
  #footer[mode=web] .footer {
     width: 1920px !important;
     display: flex;
     flex-wrap: wrap;
     margin-left: 152px;
  }
}

@media (max-width: 1920px) {
  #footer[mode=web] .footer {
     width: 1920px !important;
     display: flex;
    flex-wrap: wrap;
    color: #3F4140;
    margin-left: 152px;
  }
}





#footer[mode=web]  .impactopia {
  width: 134px;
  height: 27px;
  font-size: 26px;
  font-family: Impact;
  font-weight: 400;
  text-decoration: underline;
  color: #647577;

}


#footer[mode=mobile] .footer {
 display: flex;
 align-items: center;
 padding-left: 4.2667vw;
}


#footer[mode=mobile] .impactopia {
  width: 134px;
  font-size: 26px;
  font-family: Impact;
  font-weight: 400;
  text-decoration: underline;
  color: #647577;
  border-right: 1px solid  #647577;
}

#footer[mode=mobile]  .footer-img {
  display: flex;
  align-items: center;
  margin-left: 1.3333vw;
}


#footer[mode=web]  .footer-img {
  cursor: pointer;
  margin-top: 18px;
  display: flex;
  align-items: center;
}



.img-tg {
  width: 28px;
  height: 28px;
}



#footer[mode=web]  .img-tg {
  width: 28px;
  height: 28px;
}

#footer[mode=web]  .img-marg {
  margin-left: 14px;
}

#footer[mode=mobile]  .img-marg {
  margin-left: 3.6667vw;
}



#footer[mode=web]  .footer-line {
  width: 2px;
height: 84px;
background: #718183;
margin-left: 70px;
}

