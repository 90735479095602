
.donateViewContent{
  width: 100%;
  background: #FFFFFF;
}


.donate_view_list_box{
  margin-top: 2.1875vw;
  width: 100%;
  padding-left: 5.2083vw;
  padding-right: 5.2083vw;
}

.donate_view_list{
 width: 100%;
 display: flex;
 flex-wrap: wrap;
}

.donate_view_list_item{
width: 13.5417vw;
height: 13.8542vw;
background: #FFFFFF;
box-shadow: 0px 4px 6px 0px rgba(207,209,208,0.35);
border-radius: 0.5208vw;
margin-top: 2.0833vw;
margin-left: 1.6667vw;
}
.donate_view_list_title{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.donate_view_list_title_1{
  font-size: 1.875vw;
  font-family: Roboto;
  font-weight: 900;
  text-decoration: underline;
  color: #222222;
}

.donate_view_list_title_2{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5.2083vw;
  height: 1.875vw;
  border: 1px solid #EBEBEB;
  border-radius: 0.3125vw;
  font-size: 0.8333vw;
  font-family: Roboto;
  font-weight: 500;
  color: #222222;
}


.donate_view_list_item_cld_img{
  width: 13.5417vw;
  height: 9.5313vw;
}

.donate_view_list_item_cld_name{
  font-size:16px;
  font-family: Roboto;
  font-weight: bold;
  color: #222222;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 10.2083vw;
  margin-top: 0.5333vw;
  padding-left:0.5333vw;
}


.donate_view_step{
  width: 6.25vw;
  height: 0.5208vw;
  background: #F5F5F5;
  border-radius: 0.2604vw;
  margin-left: 0.5208vw;
}

.donate_view_step_bule{
  height: 0.5208vw;
  background: #52A553;
  border-radius: 0.2604vw;
 
}


.donate_view_list_item_cld_num{
  margin-top: 0.5333vw;
  padding-left:0.5333vw;

}


