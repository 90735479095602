
 .ch_content{
  display: flex;
  flex-wrap: wrap;
  padding-left: 4.6875vw;
  padding-right: 6.25vw;
 }

 .ch_title{
  font-size: 1.5625vw;
  font-family: Roboto;
  font-weight: 400;
  text-decoration: underline;
  color: #222222;
 }

 .ch_banner{
  width: 89.5833vw;
  height: 16.6667vw;
  display: flex;

 }


 .mchannel_box{
  width: 100%;
 }


.ch_banner_left{
  width: 25.5208vw;
  height: 16.6667vw;
  background-image: url("../../assets/v2/channel_left.png");
  background-size:100% 100%;
  padding-left: 0.8854vw;
  padding-top: 2.4479vw;
}


.mch_banner_left{
  width: 100%;
  height: 39.6667vw;
  background-image: url("../../assets/v2/channel_left.png");
  background-size:100% 100%;
  padding-left: 0.8854vw;
  padding-top: 2.4479vw;
}

.mch_banner_left_box{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 2.6667vw;
  }



.ch_banner_left_box{
display: flex;
flex-wrap: wrap;
width: 100%;
}


 .ch_banner_right{
  width: 63.5417vw;
  height: 16.6667vw;
  background: #F9F9F9;
  padding-top: 2.0833vw;
  padding-left: 2.1354vw;
  padding-right: 4.2188vw;
 }

 .ch_search{
  margin-top: 1.0417vw;
  width: 89.5833vw;
  height: 2.5vw;
  display: flex;
 }

 .ch_live_img{
  width: 18.75vw;
  height: 9.375vw;
  background: #E74747;
  border-radius: 0.5208vw;
 }

 .ch_live_header{
  width: 42px;
  height: 42px;
  background: #E74747;
  border-radius: 0.5208vw;
 }

 .ch_filter{
  width: 1.0417vw;
  height: 0.625vw;
  margin-right: 0.5208vw;
 }

 .ch_live_renshu{
  width: 0.8333vw;
  height: 0.5729vw;
 }

 .ch_channel_img{
  border: 0.0521vw solid #EBEBEB;
  border-radius: 2.2917vw;
  width: 4.5833vw;
height: 4.5833vw;
 }

 .ch_channel_member_img{
width: 0.9375vw;
height: 0.9375vw;

 }



 .ch_trending_box{
  width: 65.1042vw;
 }


 .ch_trending_news{
   width: 29.6875vw;
 }

 .ch_w100_flex{
  display: flex;
  align-items: center;
  width: 100%;
 }


 .ch_w100_content{
  display: flex;
  width: 100%;
 }


 .channel_img{
  width: 18.75vw;
height: 9.375vw;
border-radius: 1.0417vw;
 }


 .ch_flex{
  display: flex;
  align-items: center;
 }


 .ch_trending_list{
  width: 100%;
  min-height: 31.25vw;
  border: 1px solid #EBEBEB;
  border-radius: 10px;
  padding-left: 0.5208vw;
  padding-top: 0.5208vw;
  padding-right: 0.5208vw;
 }



 .ch_live{
   width: 100%;
   height: 30.625vw;
   border: 1px solid #EBEBEB;
   border-radius: 0.5208vw;
   padding-left: 1.8229vw;
   padding-top: 2.0833vw;
 }
 .ch_live_0{
  margin-left: 0.5208vw;
 }

 .ch_live_1{
  display: flex;
  margin-top: 0.5208vw;
 }

 .ch_live_1_1{
  font-size: 0.8333vw;
font-family: Roboto;
font-weight: 500;
color: #222222;
 }

 .ch_live_1_2{
  display: flex;
  justify-content: space-between;
  color: #808080;
 }

 .ch_live_1_2_1{
  display: flex;
 }

 .ch_live_team{
  width: 2.3438vw;
height: 0.8333vw;
background: #D1D1D1;
border-radius: 0.3125vw;
display: flex;
align-content: center;
font-size: 0.625vw;
font-family: Roboto;
font-weight: 500;
color: #FFFFFF;
justify-content: center;
 }

 .ch_live_1{
  display:flex;
 }

 .ch_live_1_1{
  display: flex;
  width: 100%;
 }

 .ch_live_1_2{
  display: flex;
  justify-content: flex-start;
 }

 .ch_live_0{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
 }

 .ch_trending{
  display: flex;
  width: 100%;
  margin-top: 1.0417vw;
  margin-bottom: 2.6042vw;
 }

 .ch_trending_tile{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
 }


 .ch_list_name{
  font-size: 1.25vw;
  font-family: Roboto;
  font-weight: 500;
  color: #222222;
 }



 .ch_trending_news{
  padding-left: 1.5104vw;
 }


 .ch_search_img{
  width: 1.2396vw;
  height: 1.2396vw;
 }



 .ch_search_1{
  width: 12.5vw;
  height: 2.5vw;
  background: #FAFAFA;
  border: 0.0521vw solid #EBEBEB;
  border-radius: 1.25vw;
  display: flex;
  align-items: center;
  padding-left: 1.5625vw;
 }

 .ch_search_2{
  margin-left: 0.5208vw;
  width: 58vw;
  height: 2.5vw;
  background: #FAFAFA;
  border: 0.0521vw solid #EBEBEB;
  border-radius: 1.25vw;
 }


 .ch_content_left{
  width: 12.5vw;
  margin-right: 0.8208vw;
 }
 .ch_content_center{
  width: 55vw;
  margin-right: 0.8208vw;
 }
 .ch_content_right{
   width: 25vw;
 }


.ch_tab{
  display: flex;
  height: 3.0208vw;
  align-items: flex-end;
}



.mch_tab{
  display: flex;
  padding-left: 4vw;
  align-items: flex-end;
}




.mch_title_tab{
  display: flex;
  align-items: flex-end;
  font-size: 4.8vw;
  font-family: Roboto;
  font-weight: 500;
  text-decoration: underline;
  color: #808080;
}
.ch_status_active{
  font-size: 4.8vw;
  font-family: Roboto;
  font-weight: 500;
  text-decoration: underline;
  color: #222222 !important;
 }


.mch_status_active{
  font-size: 4.8vw;
  font-family: Roboto;
  font-weight: 500;
  text-decoration: underline;
  color: #222222 !important;
 }




.ch_title_tab{
  display: flex;
  align-items: flex-end;
  font-size: 1.25vw;
  font-family: Roboto;
  font-weight: 500;
  text-decoration: underline;
  color: #808080;
}

.ch_content_left_tools{
  display: flex;
  width: 100%;
  align-items: center;
  cursor: pointer;
}
.ch_content_left_ALL{
  height: 30px;
  width: 30px;
  border-radius: 0.2604vw;
  display: flex;
  font-size: 12px;
  margin-right: 0.625vw;
  align-items: center;
  justify-content: center;
  background-color: #D1D1D1;
}


.ch_content_left_1{
  width: 12.5vw;
  background: #FFFFFF;
  border: 0.0521vw solid #F5F5F5;
  box-shadow: 0vw 0.2083vw 0.3125vw 0vw rgba(207,209,208,0.35);
  border-radius: 0.5208vw;
  margin-top: 2.1354vw;
  padding-top: 0.5208vw;
  padding-left: 0.2604vw;
  padding-right: 0.2604vw;

}

.ch_midea{
  width: 1vw;
height: 1vw;
background: #52A553;
border: 0.0521vw solid #EBEBEB;
border-radius: 0.625vw;
}

.ch_content_left_2{
  display: flex;
  align-items: center;
  height: 1.25vw;
  justify-content: space-between;
  padding-left: 0.2604vw;
  padding-right: 0.2604vw;
  margin-bottom: 0.8854vw;
  cursor: pointer;
}

.ch_content_left_3{
  width: 7.8646vw;
  font-size: 0.625vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .ch_content_left_3{
  font-size: 0.625vw;
  font-family: Roboto;
  font-weight: 400;
  color: #4D4D4D;
  width: 7.8646vw;
  display: flex;
  align-items: center;
} */


.ch_content_right_member{
  margin-top: 2.0833vw;
  padding-top: 1.0417vw;
  padding-left: 0.5604vw;
  width: 25vw;
  height: 15.8333vw;
  border: 0.0521vw solid #F5F5F5;
  border-radius: 0.5208vw;
  overflow: auto;
}

.ch_content_right_member_box{
  display: flex;
  flex-wrap: wrap;
}




.ch_content_right_member_1{
   width:48%;
   display: flex;
   align-items: center;
   margin-bottom: 0.8854vw;
}

.ch_content_right_member_1_name{
  width: 4.2458vw;
  margin-left: 0.2042vw;
  font-size: 0.825vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.ch_member_img{
  width: 0.825vw;
  height: 0.825vw;
  margin-left: 0.7292vw;
}

.mch_banner_header{
  width: 11.7333vw;
  height: 11.7333vw;
  background: #52A553;
  border: 2px solid #EBEBEB;
  border-radius: 5.8667vw;
}



.ch_banner_header{
  width: 4.5833vw;
height: 4.5833vw;
border: 2px solid #EBEBEB;
border-radius: 100%;
}


.mch_banner_name{
  height: 10.1333vw;
  font-size: 4vw;
  font-family: Roboto;
  font-weight: bold;
  color: #FFFFFF;
  margin-left: 2.9333vw;
  
}





.ch_banner_name{
  font-size: 1.25vw;
font-family: Roboto;
font-weight: bold;
color: #FFFFFF;
padding-left: 0.5208vw;
width: 18.0521vw;
}

.ch_banner_member{
  width: 0.8333vw;
height: 0.8333vw;
background: #279D8E;
border-radius: 1.0417vw;
}


.ch_banner_line{
  width: 23.9583vw;
  height: 0.1vw;
  background: #EBEBEB;
  border-radius: 0.0521vw;
  margin-top: 0.6667vw;
}


.ch_channelStory{
  margin-top: 0.5625vw;
  width: 22.3958vw;
   height: 3.0813vw;
  font-size: 0.7292vw;
  font-family: Roboto;
  font-weight: 400;
  color: #FFFFFF;
}


.mch_banner_mgs{
  font-size: 1.625vw;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  margin-right: 2.3333vw;

}



.ch_media_img_bage{
  width: 66px;
height: 66px;
margin-right: 0.5208vw;
margin-top: 0.3333vw;
border-radius: 0.3333vw;
cursor: pointer;
}


.mch_media_img_bage{
  width: 4.9333vw;
height: 4.9333vw;
margin-right: 0.5208vw;
border-radius: 0.6667vw;
}

.mch_media_img{
  width: 2.9333vw;
height: 2.9333vw;
margin-right: 0.5208vw;
}


.ch_media_img{
  width: 0.625vw;
height: 0.5729vw;
margin-right: 0.5208vw;
}

.ch_banner_mgs{
  font-size: 0.625vw;
  color: #FFFFFF;
  width: 3.6042vw;
  height: 0.725vw;
  display: flex;
  align-items: center;
  border-right: 1px solid #FFFFFF;
}


.ch_banner_member_2{
  font-size: 0.625vw;
  color: #FFFFFF;
  width: 3.6042vw;
  height: 0.725vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #FFFFFF;
}

.ch_banner_media{
  font-size: 0.625vw;
  color: #FFFFFF;
 margin-left: .3125rem;
  height: 0.725vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ch_channelNftList{
  height: 66px;
  display: flex;
  align-items: center;
  margin-top: 0.2333vw;
}

.ch_banner_footter{
  display: flex;
  align-items: center;
  margin-top: 0.7625vw;
}

.ch_banner_right_1{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ch_banner_right_1_but_box{
  display: flex;
  align-items: center;
}

.ch_banner_right_1_but{
  display:flex;
  align-items: center;
  cursor: pointer;
}

.ch_banner_right_1_but{
  width: 3.3333vw;
height: 2.0833vw;
background: #EBEBEB;
border-radius: 0.3125vw;
font-size: 0.9375vw;
font-family: Roboto;
font-weight: 500;
color: #FFFFFF;
display: flex;
align-items: center;
justify-content: center;
margin-right: 0.5208vw;

}

.ch_banner_right_2_but_0{
  width: 5.5833vw;
  height: 1.7708vw;
  background:rgba(232, 112, 80, 0.30);
  border: 1px solid #FFFFFF;
  box-shadow: 3px 3px 4px 0px rgba(232,112,80,0.3);
    border-radius: 0.8854vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.625vw ;
    color: #FFFFFF;
    cursor: pointer;
}




.ch_banner_right_2_but{
  width: 5.5833vw;
  height: 1.7708vw;
  background: #E87050;
  box-shadow: 0.1563vw 0.1563vw 0.2083vw 0vw rgba(232,112,80,0.3);
  border-radius: 0.8854vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.625vw ;
  color: #FFFFFF;
  cursor: pointer;
}

.mch_banner_right_2_but{
  width: 18.8667vw;
  height: 6.4vw;
  background: #E87050;
  box-shadow: 0.2667vw 0.6563vw 0.4083vw 0vw rgba(232,112,80,0.3);
  border:2px solid #FFFFFF;
  border-radius:6.4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.4vw ;
  color: #FFFFFF;
  cursor: pointer;
}





.ch_banner_all_story{
  font-size: 0.625vw;
font-family: Roboto;
font-weight: 400;
color: #333333;
padding-top: 1.5625vw;
}




.ch_banner_right_1_but_active{
  background: #279D8E;
  border-radius: 0.3125vw;
}


.ch_banner_all_live{
  display: flex;
  align-items: center;
  padding-top: 1.0417vw;
  width: 63.5417vw;

}

.ch_banner_all_image{
  width: 18vw;
  height: 10.375vw;
  display: flex;
  padding-top: 1vw;
}

.ch_banner_all_image_item_box{
  width: 18vw;
  height: 9.35vw;
  display: flex;
  justify-content: center;
  box-shadow: 0px 4px 6px 0px rgba(207,209,208,0.35);
}

.ch_banner_all_image_item{
  object-fit: contain !important;
}

.ch_live_but{
height: 2.5vw;
background: #FFFFFF;
border: 0.0521vw solid #EBEBEB;
box-shadow: 0px 4px 6px 0px rgba(207,209,208,0.35);
border-radius: 0.3125vw;
margin-left: 1.5625vw;
display: flex;
align-items: center;
padding-left: 0.5208vw;
padding-right: 0.5625vw;
}


.mch_live_03{
 margin-left: 2.6667vw;
}

.ch_live_03_add{
  width: 0.7292vw;
  height: 0.7292vw;
}

.ch_live_01_live{
  width: 1.9792vw;
color: #818181;
margin-left: 0.7813vw;
border-right: 1px solid  #EBEBEB;  ;
}


.mch_live_01_live{
  width: 12.9333vw;
  font-size: 4vw;
color: #818181;
margin-left: 2.9333vw;
border-right: 1px solid  #EBEBEB;
}




.ch_live_01{
  width: 0.3125vw;
height: 0.3125vw;
background: #F44F53;
border-radius: 50%;
}


.mch_live_01{
  width: 2.4vw;
  height: 2.4vw;
  background: #F44F53;
  border-radius: 50%
}


.mch_live_02{
  width: 51.7333vw;
  height: 3.8667vw;
  font-size: 4vw;
  font-family: Roboto;
  font-weight: 500;
  color: #222222;
}



.ch_live_02{
  width: 9.0625vw;
  font-size: 0.7292vw;
  font-family: Roboto;
  font-weight: 500;
  color: #222222;
  border-right: 1px solid  #EBEBEB;  ;
   margin-left: 0.5208vw;
}


.mlive_right{
  width: 3.2vw;
  height: 4.8vw;
}





.live_right{
  width: 0.5729vw;
  height: 0.8854vw;
  margin-left: 0.5208vw;
}





.live_add{
  width: 0.7292vw;
  height: 0.7292vw;
  margin-left: 0.5208vw;
}


.dis_list_im_set{
  width: 0.9375vw;
  height: 0.9375vw;
  margin-right: 1.0417vw;
}




#channel[mode=web] .options_box{
  width: 8.0729vw;
  height: 1.4583vw;
  background: rgba(240, 240, 240,0.5);
  border-radius: 0.2083vw;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

#channel[mode=web] .options_box_flex{
  cursor: pointer;
  width: 8.0729vw;
  height: 1.0583vw;
  align-items: center;
  display: flex;
  align-items: center;
  color: rgb(107, 104, 104);
  font-size: 0.7292vw;
  font-family: DIN;
  justify-content: center;
}

#channel[mode=web] .options_box_num{
  margin-left: 0.204vw;

}
#channel[mode=web] .options_box_flex_border{
  cursor: pointer;
  border-right: 1px solid rgba(240, 240, 240,1);
}




#channel[mode="web"] .disflex{
  display: flex;
  width: 100%;
}

#channel[mode="web"] .dis_list_content{
  font-size: 1.05vw;
  font-family: Roboto;
  font-weight: 300;
  color: #595959;
  width: 49.8438vw;
  min-height: 3.1667vw;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-top: 0.3333vw;
  padding-left: 0.3333vw;
}


.mch_banner_live{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 4.8vw;
}

.mch_banner_live_but{
  width: 93.6vw;
  height: 18.6667vw;
  background: #FFFFFF;
  border: 2px solid #EBEBEB;
  border-radius: 4.8vw;
  display: flex;
  align-items: center;
  padding-left: 20px;
}


.mch_banner_left_box_left{
  width: 76%;
  display: flex;
}

.mch_banner_left_box_right{
  width: 20%;
  display: flex;
  justify-content: right;

}


.ch_banner_right_1_but_box_m{
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 1.3333vw;
  padding-left: 2.6vw;

}

.ch_banner_right_1_but_m{
  font-size: 4.8vw;
font-family: Roboto;
font-weight: 500;
color: #818181;
margin-right: 8vw;
}

.ch_banner_right_1_but_active_m{
  color: #000101;
}


.ch_banner_all_live_m{
  display: flex;
  align-items: center;
  padding-top: 1.0417vw;
  width: 100%;
}



.ch_banner_all_image_item_box_m{
  width: 58vw;
  height: 28.6667vw;
  border: 2px solid #FFFFFF;
  border-radius: 10px;
}



.ch_banner_all_story_m{
  font-size: 3.2vw;
  font-family: Roboto;
  font-weight: 400;
  color: #333333;
  padding-top: 1.5625vw;
  padding-left: 3.6vw;
  padding-right: 3.6vw;
  }
