.push_donate_box{
  display: flex;
  justify-content:center;
  flex-wrap: wrap;
  padding-top: 3.2813vw;
}


.push_donate{
  width: 19.2708vw;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height:15.625vw ;
}



.push_donate_wallet{
  width: 19.2708vw;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height:10.625vw ;
}

.push_donate_your{
  font-size: 1.0417vw;
  font-family: Roboto;
  font-weight: bold;
  text-decoration: underline;
  color: #222222;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}


.push_donate_list{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 3.3333vw;
}

.push_donate_but{
  width: 8.8542vw;
  height: 2.5vw;
  border: 1px solid #E0E0E0;
  border-radius: 0.3646vw;
  margin-top: 0.5208vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.625vw !important;
 color: rgba(128, 128, 128, 1);
 cursor: pointer;
}


.push_donate_but_active{
  width: 8.8542vw;
  height: 2.5vw;
  border: 1px solid #279D8E;
  border-radius: 0.3646vw;
  margin-top: 0.5208vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.625vw !important;
  color: #279D8E;
 cursor: pointer;
}



.push_donate_but_min{
  width: 18.75vw;
  height: 2.5vw;
  border-radius: 0.3646vw;
  margin-top: 0.5208vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.625vw !important;
 color: rgba(128, 128, 128, 1);
}

.push_donate_but_money{
  font-size: 1.0417vw !important;
  font-family: Roboto;
  font-weight: bold;
  margin-right: 0.2604vw;
}

.push_donaton_amont{
  font-size: 0.625vw;
font-family: Roboto;
font-weight: 400;
color: #808080;
margin-top: 1.0625vw;
}

.push_donaton_ok{
  cursor: pointer;
  margin-top: 3.125vw;
  width: 19.2708vw;
  height: 2.5vw;
  background: #52A553;
  border-radius: 0.3125vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25vw;
  font-family: Roboto;
  font-weight: bold;
  color: #FFFFFF;
}

.min_sign_right{
  width: 100%;
}

.min_sign_other{
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.min_sign_or{
  display: flex;
  justify-content: center;
  font-size: 1.875vw;
font-family: Roboto;
font-weight: 400;
color: #ADADAD;
margin-top: 1.5625vw;
}

.min_sign_full_name{
  border-bottom: 1px solid #E0E0E0;

}

.min_sign_to_login{
  font-size: 0.625vw;
font-family: Roboto;
font-weight: 400;
color:rgba(173, 173, 173, 1)
}

.min_sign_but{
  margin-top: 2.9167vw;
  width: 19.2708vw;
height: 2.5vw;
background: #52A553;
border-radius: 0.3125vw;
display: flex;
align-items: center;
justify-content: center;
font-size: 1.25vw;
font-family: Roboto;
font-weight: bold;
color: #FFFFFF;
cursor: pointer;
}

.min_sign_to_login_1{
  color: rgba(82, 165, 83, 1);
  margin-left: 1.3333vw;
}

.push_donate_will{
  width: 100%;
  display: flex;
  font-size: 1.5vw;
  justify-content: center;

}



.mproject_donate_popup{
  width: 100%;
  height: 147.8667vw;
  background: #FFFFFF;
  border-radius: 3.2vw 3.2vw 0px 0px;
  overflow: auto;
}

.mproject_donate_popup2{
  width: 100%;
  height: 127.8667vw;
  background: #FFFFFF;
  border-radius: 3.2vw 3.2vw 0px 0px;
}



.mproject_donate_popup_box{
  width: 100%;
  height: 25.6vw;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E0E0E0;
  flex-wrap: wrap;
}

.mproject_donate_popup_box_but{
  display: flex;
  width: 100%;
  padding-left: 4vw;
}

.mproject_donate_popup_box_but_img{
  width: 33.0667vw;
  height:11.7333vw ;
}


.project_donate_popup_box_but_img{
  cursor: pointer;
  width: 148px;
  height:43px;
}


.mproject_donate_name{
  font-size: 4.8vw;
  font-family: Roboto;
  font-weight: bold;
  color: #222222;
  padding-left: 10.6667vw;
  margin-top: 5.3333vw;
}


.mproject_donate_item_amonts{
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.mproject_donate_item_box{
  padding-left: 5.6667vw;
  padding-right: 5.6667vw;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2.6667vw;

}

.mpush_donate_but{
  width: 22.4vw;
  height: 10.6667vw;
  border-radius: 1.6vw;
  display: flex;
 align-items: center;
 justify-content: center;
 font-size: 3.8vw;
  font-family: Roboto;
  font-weight: bold;
  color: #1A1A1A;
  opacity: 0.99;
  margin-top: 2.3333vw;
  border: 1px solid #E0E0E0;
}

.mpush_donate_but_active{
    width: 22.4vw;
    height: 10.6667vw;
    border: 1px solid #279D8E;
    border-radius: 1.6vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.8vw;
    font-family: Roboto;
    font-weight: bold;
    color: #279D8E;
    opacity: 0.99;
    margin-top: 2.3333vw;
}

.mpush_donate_input{
  width: 50.4vw;
  border-radius: 1.6vw;
  margin-top: 2.3333vw;
}


.mproject_donate_item_but{
  width: 85.3333vw;
  height: 11.7333vw;
  background: #279D8E;
  border-radius: 3.2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4.8vw;
  font-family: Roboto;
  font-weight: bold;
  color: #FFFFFF;
  margin-top: 20vw;
}


.mproject_donate_popup_box{
  width: 100%;
}

.mproject_donate_popup_box_success{
  width: 36.1333vw;
  height: 38vw;
}


.mproject_donate_success{
  font-size: 8.9333vw;
font-family: Roboto;
font-weight: bold;
color: #279D8E;

}

.mdonationMoney{
  font-size: 3.2vw;
  font-family: Roboto;
  font-weight: 400;
  color: #4D4D4D;
}

.mdonationMoney_but{
  margin-top: 7.4667vw;
  width: 85.3333vw;
  height: 11.7333vw;
  background: #279D8E;
  border-radius: 3.2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4.8vw;
  font-family: Roboto;
  font-weight: 500;
  color: #FFFFFF
}

.mproject_donate_failed{
  font-size: 9.6vw;
font-family: Roboto;
font-weight: bold;
color: #E87050;
}


.mdonationMoney_fail_but{
  margin-top: 7.4667vw;
  width: 85.3333vw;
  height: 11.7333vw;
  background: #E87050;
  border-radius: 3.2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4.8vw;
  font-family: Roboto;
  font-weight: 500;
  color: #FFFFFF;
}



.push_donate_metamask{
  width: 17.0313vw;
  height: 3.2813vw;
  border-radius: 0.3125vw;
  cursor: pointer;
}


.mpush_donate_metamask{
  width: 75.6vw;
  height: 12.6667vw;
}