.mpmint_body{
	width:100%;
}



.mpmint_box {
    width: 100%;
    background: #FFFFFF;
	overflow: auto;
}




.mpmint_input{
	display: flex;
	align-items: center;
}


.mpmint_flex{
	width: 100%;
	margin-top: 2vw;
}


.mpmint_lable{
	display: flex;
	align-items: center;
	height: 5.8667vw
}


.mpmint_but_box{
	width: 100%;
}

.mpmint_preview{
	width: 100%;
	height: 12vw;
	border: 2px solid #279D8E;
	border-radius: 3.2vw;
	font-size: 4.8vw;
	font-family: Roboto;
	font-weight: bold;
	color: #279D8E;
	display: flex;
	align-items: center;
	justify-content: center;
}

.mpmint_pint{
	margin-top: 1.3333vw;
	width: 100%;
	height: 11.7333vw;
	background: #279D8E;
	border-radius: 3.2vw;
	font-size: 4.8vw;
	font-family: Roboto;
	font-weight: bold;
	color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
}


.mpint_pay_but{
	width: 46%;
	height: 8.5333vw;
	background: #F0F0F0;
	border-radius: 1.6vw;
	font-size: 3.4667vw;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 3.4667vw;
	font-family: Roboto;
	font-weight: 400;
	border-radius: 1.6vw;
}

.mpint_pay_but_active{
	background-image: url("../../assets/mobile/back.png");
	background-size: 100% 100%;  
	width: 46%;
	height: 8.5333vw;
	 color: #279D8E;
	 font-size: 3.4667vw;
	 font-family: Roboto;
	 font-weight: 400;
	 border-radius: 1.6vw;
	 display: flex;
	 align-items: center;
	 justify-content: center;
}


.pint_pay_but{
	width: 10.5208vw;
	height: 2.5vw;
	background: #F0F0F0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.8333vw;
	font-family: Roboto;
	font-weight: 400;
	border-radius: 0.5208vw;
}


.pint_pay_but_active{
	background-image: url("../../assets/mobile/back.png");
	background-size: 100% 100%;  
	width: 10.5208vw;
	height: 2.5vw;
	 color: #279D8E;
	 font-size: 0.8333vw;
	font-family: Roboto;
	font-weight: 400;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 border-radius: 0.5208vw;
}