.pserson_follow {
  width: 5.625vw;
  height: 1.4583vw;
  background: #f5f5f5;
  border-radius: 0.2083vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8333vw;
  font-family: Roboto;
  font-weight: 500;
  color: #222222;
}

.person_show_name {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5625vw;
  cursor: pointer;
}

.pserson_send_msg {
  width: 5.625vw;
  height: 1.4583vw;
  background: #f5f5f5;
  border-radius: 0.2083vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8333vw;
  font-family: Roboto;
  font-weight: 500;
  color: #222222;
}

.person_step {
  width: 9.375vw;
  height: 0.5208vw;
  background: #f5f5f5;
  border-radius: 0.2604vw;
  margin-left: 1.0417vw;
}

.person_step_blue {
  height: 10px;
  background: #52a553;
  border-radius: 0.2604vw;
}

#person_show[mode='web'] .options_box {
  width: 8.0729vw;
  height: 1.4583vw;
  background: rgba(240, 240, 240, 0.5);
  border-radius: 0.2083vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#person_show[mode='web'] .dis_sc {
  cursor: pointer;
  width: 123px;
  height: 28px;
  background: #f0f0f0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#person_show[mode='web'] .options_box_flex {
  cursor: pointer;
  width: 8.0729vw;
  height: 1.0583vw;
  align-items: center;
  display: flex;
  align-items: center;
  color: rgb(107, 104, 104);
  font-size: 0.7292vw;
  font-family: DIN;
  justify-content: center;
}

#person_show[mode='web'] .options_box_num {
  margin-left: 0.204vw;
}
#person_show[mode='web'] .options_box_flex_border {
  cursor: pointer;
  border-right: 1px solid rgba(240, 240, 240, 1);
}



#person_show[mode='web'] .dis_list_content {
  font-size: 1.05vw;
  font-family: Roboto;
  font-weight: 300;
  color: #595959;
  width: 49.8438vw;
  min-height: 3.1667vw;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.personHead {
  height: 48vw;
  background: linear-gradient(180deg, #ffffff 0%, #dff4f3 100%);
  padding: 3.3333vw;
}
.personHeadTop {
  display: flex;
  margin-bottom: 4.2667vw;
}

.personHeadImg {
  width: 17.0667vw;
  height: 17.0667vw;
  border-radius: 17.0667vw;
}
.personHeadName {
  font-size: 4.8vw;
  font-family: Roboto;
  font-weight: 500;
  color: #212121;
  margin: 0 1.3333vw;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.personHeadISFollowNo {
  width: 18.1333vw;
  height: 4.8vw;
  background: #279d8e;
  opacity: 0.2;
  border-radius: 0.5333vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.4667vw;
  font-weight: 500;
  color: #ffffff;
}
.personHeadISFollowOK {
  width: 18.1333vw;
  height: 4.8vw;
  background: #279d8e;
  border-radius: 0.5333vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.4667vw;
  font-weight: 500;
  color: #ffffff;
}

.personHeadMessage {
  width: 18.1333vw;
  height: 4.8vw;
  background: #dff4f3;
  border-radius: 0.5333vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3.2vw;
}

.personHeadContent {
  padding: 2.6667vw;
  background: #ffffff;
  border: 2px solid #cfe5e4;
  border-radius: 1.6vw;
}

.personHeadContentItem {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.personTabs {
  height: 11.4667vw;
  border-bottom: 0.2667vw solid #edf1f0;
  display: flex;
}

.personTabsItemOK {
  height: 100%;
  width: 25%;
  font-size: 4vw;
  font-family: Roboto;
  font-weight: 500;
  color: #212121;
  display: flex;
  align-items: center;
  justify-content: center;
}
.personTabsItem {
  height: 100%;
  width: 25%;
  font-size: 4vw;
  font-family: Roboto;
  font-weight: 400;
  color: #595959;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Thoughts {
  padding: 3.2vw;
}

.ThoughtsItem {
  padding: 2.5333vw;
  border-radius: 3.2vw;
  background: #ffffff;
}

.ThoughtsItemTitle {
  font-size: 4vw;
  font-family: Roboto;
  font-weight: bold;
  color: #222222;
}
.ThoughtsItemH {
  height: 5.6vw;
  display: flex;
  align-items: center;
  margin: 2.6667vw 0;
}
.ThoughtsItemHLeft {
  height: 5.6vw;
  width: 5.6vw;
  background-color: #279d8e;
  border-radius: 5.6vw;
}
.ThoughtsItemHName {
  font-size: 3.4667vw;
  font-family: Roboto;
  font-weight: 500;
  color: #595959;
  margin-left: 1.4667vw;
}
.ThoughtsItemHDate {
  font-size: 2.9333vw;
  font-family: DIN;
  font-weight: 400;
  color: #808080;
  margin-left: 2.9333vw;
  margin-right: 5.6vw;
}

.ThoughtsItemHLook {
  font-size: 2.9333vw;
  font-family: DIN;
  font-weight: 400;
  color: #808080;
}
.ThoughtsItemContent {
  font-size: 2.9333vw;
  font-family: Roboto;
  font-weight: 300;
  color: #595959;
  padding-bottom: 2.6667vw;
  border-bottom: 0.2667vw solid #edf1f0;
}

.ThoughtsItemtool {
  height: 12vw;
  padding: 0 5vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ThoughtsItemZ {
  width: 6vw;
  height: 6vw;
}
.ThoughtsItemS {
  width: 5.7333vw;
  height: 6vw;
}

.Donation {
  padding: 3.2vw;
}

.DonationItem {
  padding: 2.5333vw;
  border-radius: 3.2vw;
  background: #ffffff;
}

.DonationItemViewAll {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 14.9333vw;
}

.DonationItemViewAllNameT {
  font-size: 4vw;
  font-family: Roboto;
  font-weight: bold;
  color: #222222;
  margin-bottom: 1.3333vw;
}
.DonationItemViewAllNameE {
  font-size: 2.4vw;
  font-family: Roboto;
  font-weight: bold;
  color: #222222;
}
.DonationItemViewAllBut {
  width: 18.1333vw;
  height: 8.5333vw;
  background: #279d8e;
  border-radius: 1.7333vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.4667vw;
  font-family: Roboto;
  font-weight: 400;
  color: #ffffff;
}
.DonationDontedListitem {
  border-top: 0.2667vw dashed #f5f5f5;
  display: flex;
  padding-top: 3.8667vw;
  padding-bottom: 1.3333vw;
}
.wallect{
  /*超出省略号 */
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
  width: 75%;
}
.DonationDontedListitemIndex {
  display: flex;
}

.DonationDontedListitemIndexI {
  font-size: 3.4667vw;
  font-family: DIN;
  font-weight: 500;
  color: #000101;
  margin-right: 4.6667vw;
}
.DonationDontedListitemIndexN {
  font-size: 3.4667vw;
  font-family: Roboto;
  font-weight: 400;
  color: #222222;
}
.DonationDontedListitemIndexNNum {
  color: #52a553;
}
.DonationItemDate {
  margin-left: 4.6667vw;
}
.DonationItemDateT {
  color: #808080;
}

.Channel {
  padding: 3.6vw;
}

.ChannelItem {
  height: 11.7333vw;
  display: flex;
  align-items: center;
  margin-bottom: 4vw;
}
.ChannelItemImg {
  width: 11.7333vw;
  height: 11.7333vw;
  border-radius: 11.7333vw;
  margin-right: 1.6vw;
}

.ChannelContentname {
  font-size: 4.2667vw;
  font-family: Roboto;
  font-weight: 500;
  color: #222222;
  display: flex;
  align-items: center;
}
.ChannelContentnameTM{
  width: 3.2vw;
  height: 3.2vw;
  margin-left: 1.3333vw;
}
.ChannelContentT{
  display: flex;
  align-items: center;
}
.memberNumImg{
  width: 4vw;
  height:3.5vw;
}
.ChannelContentTMy{
  margin-right: 2.6667vw;
}

.Badges{
  padding: 3.6vw;
}
.mperson_nft_item1{
  padding: 1.3333vw 0;
  width: 100%;
  background: #FFFFFF;
  border: 2px solid #EBEBEB;
  box-shadow: 0px 2px 6px 0px rgba(246,248,248,0.19);
  border-radius: 3.2vw;
  margin-bottom: 2vw;
}

.mperson_nft_item_img{
  width: 100%;
  height: 78vw;
  object-fit: contain;
}

.mperson_nft_name{
  width: 100%;
  font-size: 4.8vw;
  font-family: Roboto;
  font-weight: bold;
  color: #222222;
  padding-left: 4.8vw;
  margin-top: 2.6667vw;
}

.mperson_nft_description{
  width: 100%;
  font-size: 3.4667vw;
  font-family: Roboto;
  font-weight: 400;
  color: #808080;
  padding-left: 4.8vw;
  margin-top: 2.6667vw;
}


.mperson_nft_tw{
  width: 6.4vw;
  height: 5.4667vw;
}

.mperson_nft_but{
  margin-top: 5.7333vw;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5.6vw;
  padding-right: 13.2vw;
}

.mperson_nft_project{
  width: 44.8vw;
height: 8.5333vw;
background: #F7FAFA;
border: 2px solid #EBEBEB;
border-radius: 1.6vw;
font-size: 4vw;
font-family: Roboto;
font-weight: 400;
color: #279D8E;
display: flex;
align-items: center;
justify-content: center;
}

.mpersion_share_but{
  width: 66px;
  height: 22px;
  background: #3EBBAB;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-family: Roboto;
  font-weight: 400;
  color: #FFFFFF;
  margin-left: 20px;
}


