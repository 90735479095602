#weare[mode="web"] {
  width: 100%;
  display: flex;
  justify-content: center;
  background: #FFFFFF;
  padding-bottom: 80px;
}

@media (min-width: 1920px) {
  #weare[mode=web] .weareContent {
     width: 1920px !important;
     display: flex;
     flex-wrap: wrap;
     margin-left: 152px;
  }
}
@media (max-width: 1920px) {
  #weare[mode=web] .weareContent {
     width: 1920px !important;
     display: flex;
     flex-wrap: wrap;
      color: #3F4140;
      margin-left: 152px;
  }
}


#weare[mode=web] .title-bg {
  background-image: url("../../assets/impactopia/black.png");
  background-size:100% 100%;  
  width: 310px;
  height: 98px;
  background: #1F1F1F;
  border: 4px solid #000000;
  border-radius: 16px;
  margin-top: 30px;
  display: flex;justify-content:center;
  align-items: center;
}

#weare[mode=web] .weare-top-80 {
  margin-top: 60px;
}


#weare[mode=web] .weare-letf-227 {
  margin-left: 147px;
}


#weare[mode=web] .weare-letf-367 {
  margin-left: 207px;
}




#weare[mode=web] .weare-box {
  width: 1400px;
  height: 776px;
  background: #FFFFFF;
  box-shadow: 0px 10px 20px 0px rgba(201,218,196,0.77);
  display: flex;
}


#weare[mode=web] .goals-content {
  margin-top: 124px;
  margin-left: 64px;
}



#weare[mode=web] .title-bg-text {
  font-size: 48px;
  font-family:Impact;
  font-weight: 400;
  text-decoration: underline;
  color: #F7F7F7
}



#weare[mode=web] .goals-bg {
  background-image: url("../../assets/impactopia/goals.png");
  background-size:100% 100%;  
  width: 298px;
  height: 664px;
  margin-top: 60px;
  margin-left: 70px;
}


#weare[mode=web] .goals-bg {
  background-image: url("../../assets/impactopia/goals.png");
  background-size:100% 100%;  
  width: 298px;
  height: 664px;
  margin-top: 60px;
  margin-left: 70px;
}

#weare[mode=web] .goals-content-text {
  width: 826px;
  height: 89px;
  font-size: 26px;
  font-family: Roboto;
  font-weight: 500;
  color: #3F4140;
  line-height: 60px;
  
}


#weare[mode=web] .goals-content-box {
  margin-top: 43px;
  padding: 60px 0px 0px 92px;
  width: 900px;
  height: 468px;
  background: #FFFFFF;
  box-shadow: 0px 6px 12px 0px rgba(201,218,196,0.77);
}

#weare[mode=web] .goals-content-box-img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

#weare[mode=web] .goals-content-box-text{
  font-size: 24px;
  font-family: Roboto;
  margin-top: 40px;
  color: #1F1F1F;
}

#weare[mode=web] .goals-content-box-title{
  font-family: Roboto;
  font-weight: 600;
  color: #1F1F1F;
  font-size: 24px;
}


#weare[mode=web] .logopart-bg {
  background-image: url("../../assets/impactopia/logopart.png");
  background-size:100% 100%;  
  width: 1170px;
  height: 110px;
}














