.sendContent{
    width: 47.3958vw;
    height: 6.875vw;
    background: #FFFFFF;
    border: 1px solid #419D41;
    border-radius: 2px; 

}


.sendContent_post{
    width: 3.4375vw;
    height: 1.6667vw;
    background: #419D41;
    border-radius: 0.3125vw;
    font-size: 0.8333vw;
    font-family: Roboto;
    font-weight: 400;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;
}

.contentlist_item{
    margin-top: 10px;
}

.contentList{
    width: 100%;
    padding-left: 10px;
}

.content_item_but{
    display: flex;
    align-items: center;
    margin-top: 0.5208vw;
    margin-left: 2vw;
}
.content_item_but_1{
    margin-right: 0.5208vw;
    cursor: pointer;
}

.content_1{
    display: flex;
    align-items: center;
}

.content_1_2{
    margin-left: 7px;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 900;
    color: #222222;
    margin-left: 10px;
}

.content_1_type{
    width: 3.125vw;
    display: flex;
    justify-content: center;
}

.content_1_3{
    margin-top: 0.5208vw;
   font-size: 0.625vw;   
   width: 400px;
   margin-left: 2vw;
}



.msendContent_box{
    width: 100%;
}

.msendContent_box{
    width: 100%;
    height: 53.3333vw;
    background: #F5F5F5;
    border-radius: 3.2vw;
}

.msendContent_post{
    width: 11.7333vw;
    height: 6.4vw;
    background: #279D8E;
    border-radius: 3.2vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.4667vw;
    font-family: Roboto;
    font-weight: 400;
    color: #FFFFFF;
}

.mcontentList{
  width: 100%;
  margin-top: 1.3333vw;
}

.mcontentlist_item{
    width: 100%;
    min-height: 26vw ;
    padding-top: 2.6667vw;
}

.mcontent_1{
 width: 100%;
 display: flex;
 align-items: center;
}

.mcontent_img{
    width: 5.6vw;
    height: 5.6vw;
    border-radius: 100%;
}

.mcontent_1_2{
    font-size: 2.9333vw;
    font-family: Roboto;
    font-weight: 400;  
}

.mcontent_1_3{
    width: 100%;
    padding-left: 5.6vw;
    font-size: 4vw;
    font-family: Roboto;
    font-weight: 400;
    min-height: 9.3333vw;
}

.mcontent_1_2_name{
 color: #279D8E;
 font-size: 4vw;
font-family: Roboto;
font-weight: 400;
margin-right: 0.6667vw;
}

.mcontent_item_but{
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 5.6vw;
}

.mcontent_item_but_1{
    font-size: 4vw;
    font-family: Roboto;
    font-weight: 400;
    color: #B3B3B3;
}


