.chat_body{
  width: 100%;
  display: flex;
}


.chat_left{
    width: 15.625vw;
    display: flex;
    justify-content: flex-end;
 }



 .chat_left_01{
    width: 5.1667vw;
    height: 2.6042vw;
    display: flex;
    align-items: center;
    font-size: 0.8333vw;
    font-family: Roboto;
    font-weight: 500;
    cursor: pointer;
 }

.chat_center{
     width:50.1042vw;
    height: 45.3646vw;
    display: flex;
    background: #FAFAFA;
}

.chat_center_member{
    width: 13.9063vw;
}


.chat_center_msg{
    width: 36.2063vw;
}

.chat_center_msg_top{
    display: flex;
    width: 100%;
    justify-items: center;
    height: 2.2917vw;
    align-items: center;
    background: #EAF5F4;

}

.chat_center_msg_top_name{
    width: 40.8125vw;
    display: flex;
    justify-content: center;
}

.chat_center_msg_options_input{
    width: 29.0417vw;
    height: 8vw;
    display: flex;
    padding-left: 0.5208vw;
    padding-right: 0.5208vw;
}

.chat_center_msg_options_but{
    width: 3.4375vw;
height: 1.6667vw;
background: #279D8E;
border-radius: 0.3125vw;
font-size: 0.8333vw;
font-family: Roboto;
font-weight: 400;
color: #FFFFFF;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
}

.chat_right{
    width: 35.4688vw;
}

.chat_center_msg_options_img{
    cursor: pointer;
    width: 1.4583vw;
    height: 1.4583vw;
}


.chat_center_member_list{
    width: 100%;
    height: 42.6667vw ;
    background:rgb(250, 250, 250);
}

.chat_center_member_list_item_active{
    background-color: #E8EBEA ;
}

.chat_center_member_list_item{
    width: 100%;
    display: flex;
    height: 3.75vw ;
    align-items: center;
}

.chat_center_member_list_item_img{
    width: 2.1875vw;
    height: 2.1875vw;
    object-fit: cover;
    border-radius: 100%;
}

.chat_center_member_list_item_badge{
    width: 0.9896vw;
    height: 1.1458vw;
    object-fit: cover;
    margin-right: 0.5208vw;
}

.chat_center_member_list_item_img_box{
    width: 3.3333vw;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.chat_center_member_list_item_right_box{
    width: 11.1979vw;
    height: 2.2917vw ;
    padding-right: 0.904vw;
    padding-left: 0.6604vw;
    cursor: pointer;

}

.chat_center_member_list_item_right_box_name{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

}

.chat_center_member_list_item_right_box_name_1{
    font-size: 0.7292vw;
    font-family: Roboto;
    font-weight: 900;
    color: #222222;
}

.chat_center_member_list_item_right_box_name_2{
    font-size: 12px;
    color: #B3B3B3;
}

.chat_center_member_list_item_right_imge{
    width: 100%;
    display: flex;
    margin-top:  0.2208vw;
}



.chat_center_member_seacher{
   width: 100%;
   height: 2.8646vw;
   display: flex;
   justify-content: center;
   align-items: center;
}






.chat_center_msg_centent{
width: 100%;
height: 31.5208vw;
background-color: #FFFFFF;
padding-left: 1.0417vw;
padding-right: 1.0417vw;
overflow: auto;
padding-bottom: 1.0417vw;
}

.chat_center_msg_options{
    width: 100%;
    display: flex;
    background: #EAF5F4;
    height: 10.125vw;
    align-items: center;
    padding-left: 1.5625vw;
    padding-right: 1.5625vw;
    justify-content: space-between;
}




.caht_msg_member_left{
    display: flex;
    align-items: flex-end;
    margin-top: 1.5625vw;
}

.caht_msg_member_right{
    display: flex;
    align-items: flex-end;
    margin-top: 1.5625vw;
    justify-content: flex-end;
}


.caht_msg_member_logo{
    width: 1.6667vw;
    height: 1.6667vw;
    object-fit: cover;
    border-radius: 100%;
}

.caht_msg_member_content{
    min-height: 3.2292vw;
    display: flex;

}



.caht_msg_member_content_img_right{
    background-image: url("../../assets/v2/right.png");
    background-size:100% 100%;
    width: 1.2396vw;
    min-height: 3.2292vw;
}




.caht_msg_member_content_img_left{
    background-image: url("../../assets/v2/left.png");
    background-size:100% 100%;
    width: 1.2396vw;
    min-height: 3.2292vw;
}


.caht_msg_member_content_content{
    background: #F5F5F5;
    min-height: 3.2292vw;
    padding-left: 0.4167vw;
    padding-right: 1.188vw;
    padding-top: 0.5208vw;
    border-radius:0px  1vw 1vw 0px;
    padding-bottom: 0.5208vw;
}
.caht_msg_member_content_text_img{
    width: 10.6667vw;
    height: 10.6667vw;
    object-fit: cover;
}

.chat_center_msg_top_tools_img{
    width: 0.3125vw;
    height: 0.3125vw;
    margin-right: 0.2604vw;
    cursor: pointer;
}


.caht_msg_member_content_content_right{
    background: #DAEEEC;
    min-height: 3.2292vw;
    padding-left: 1.188vw;
    padding-right: 0.4167vw;
    padding-top: 0.5208vw;
    border-radius:1vw  0vw 0vw 1vw;
    padding-bottom: 0.5417vw;
}

.caht_msg_member_content_text{
    min-height: 2.0833vw;
    min-width: 10vw;
    border-radius:0px  1vw 1vw 0px;
    width: 100%;
    font-size: 1.033vw;
    font-family: Roboto;
    font-weight: 400;
    color: #212121;

}

.caht_msg_member_content_date{
    display: flex;
    justify-content: flex-end;
    font-size: 0.5208vw;
}

.mcaht_msg_member_content_date{
    display: flex;
    justify-content: flex-end;
    font-size: 2.5208vw;
}




.chat_center_msg_top_tools{
    width: 6.25vw;
    display: flex;
    justify-content: center;
    align-items: center;
}


.chat_user_menu_img{
    width: 0.9375vw;
    height: 1.0938vw;
    margin-right: 0.7813vw;
}


.chat_sys_msg{
    margin-top: 1.0417vw;
    width: 47.8125vw;
    min-height: 8.3333vw;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px 0px rgba(207,209,208,0.35);
    padding: 1.1458vw 1.1458vw 1.1458vw 1.1458vw;
}
.chat_center_box{
    width: 96%;
}

.chat_sys_msg_name{
    font-size: 1.25vw;
    font-family: Roboto;
    font-weight: bold;
    color: #212121;
}
.chat_sys_msg_centent{
    font-size: 0.8333vw;
    font-family: Roboto;
    font-weight: 400;
    color: #4D4D4D;
}





.chat_input{
    width: 100vw;
    height: 11.7333vw;
    background-color: #279D8E;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat_input_search{
    width: 96vw;
    height: 8.5333vw;
    background: #F6F8F8;
    border: 2px solid #EAF5F4;
    border-radius: 3.2vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.3333vw;
}

.msgList{
    padding: 0 3.2vw;
    background-color: #FFFFFF;
}
.magList-item{
    width: 100%;
    height: 19.4667vw;
    border-bottom: 0.1333vw solid #ccc;
    display: flex;
    align-items: center;
}

.magList-item-img{
    width: 11.7333vw;
    height: 11.7333vw;
    border-radius: 11.7333vw;
    margin-right: 2.1333vw;
}

.magList-item-name{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.magList-item-namel{
    display: flex;
    align-items: center;
    font-size: 4vw;
    font-family: Roboto;
    font-weight: 900;
    color: #222222;
}
.magList-item-namer{
    font-size: 3.2vw;
    font-family: Roboto;
    font-weight: 100;
    color: #B3B3B3;
    text-align: right;
}

.magList-item-content {
    width: 90%;
    overflow:hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	-o-text-overflow:ellipsis;
    font-size: 3.2vw;
    font-family: Roboto;
    font-weight: 400;
    color: #808080;
}
.magList-item-name-name{
    margin-right: 2vw;
}
.imgList{
    display: flex;
    align-items: center;
}
.imgList-item{
    width: 3.2vw;
    height: 3.6vw;
    margin-right: 1.0667vw;
}

.msgHead {
    width: 100vw;
    height: 11.7333vw;
    background-color: #279D8E;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #FFFFFF;
    padding: 0 2.6667vw;
}
.msgHead-name{
    font-size: 4.8vw;
    font-family: Roboto;
    font-weight: 500;
    color: #FFFFFF;
}
.caht_msg_member_content_textH5{
    font-size: 3.4667vw;
    font-family: Roboto;
    font-weight: 400;
    color: #212121;

}
.chatRecords{
    padding-bottom: 11.7333vw;
}

.chatTools{
    height: 11.7333vw;
    width: 100vw;
    position: fixed;
    bottom: 0;
    border-top: 0.2667vw solid #EAF5F4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2.6667vw;
    background-color: #fff;
}
.chatTools_but{
    width: 11.7333vw;
    height: 6.4vw;
    background: #279D8E;
    border-radius: 0.8vw;
    line-height: 6.4vw;
    text-align: center;
    font-size: 3.4667vw;
    font-family: Roboto;
    font-weight: 400;
    color: #FFFFFF;
}

.chatToolsInput{
    flex: 1;
    border: 1px solid #ccc;
    margin: 0 1.3333vw;
    border-radius: 1.3333vw;
    padding: 0 1.3333vw;
}

.user_menu_h5{
    width: 100%;
    padding: 0 3.3333vw;
    height: 11.7333vw;
    display: flex;
    align-items: center;
}
.user_menu_h5_img{
    width: 3.4667vw;
    height: 3.4667vw;
    margin-right: 15.6vw;
}
.user_menu_h5_text{
    font-size: 3.4667vw;
    font-family: Roboto;
    font-weight: 400;
    color: #595959;
}
