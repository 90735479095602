
 .com_content{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-left: 4.6875vw;
  padding-right: 6.25vw;
 }

 .com_title{
font-size: 1.875vw;
font-family: Roboto;
font-weight: 900;
text-decoration: underline;
color: #222222;
margin-right: 2vw;
 }

 .CreateChannel{
  cursor: pointer;
  width: 9.8vw;
  height: 2vw;
  background: #279D8E;
  border-radius: 6px;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 500;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2vw;
 }

 .com_banner{
  width: 89.5833vw;
  height: 21.875vw;
  display: flex;
 }

.com_banner_left{
  width: 18.75vw;
  height: 21.875vw;
}

 .com_banner_center{
    width: 48.9583vw;
 }

 .com_banner_right{
  width: 21.875vw;
  height: 21.875vw;

 }

 .com_search{
  margin-top: 1.0417vw;
  display: flex;
  align-items: center;
  width: 89.5833vw;
  height: 2.5vw;
  background: #F9F9F9;
  border: 0.0521vw solid #EBEBEB;
  border-radius: 1.25vw;
  padding-left: 2.0833vw;
 }

 .com_live_img{
  width: 100%;
  height: 8.175vw;
  object-fit: contain !important;
  border-radius: 0.5208vw;
 }

 .com_searchLive{
  display: flex;
  align-items: center;
  width: 39.5833vw;
  height: 2vw;
  background: #F9F9F9;
  border: 0.0521vw solid #EBEBEB;
  border-radius: 1.25vw;
  padding-left: 1vw;
 }

 .com_live_imgLive{
  width:5%;
  height: 1.175vw;
  object-fit: contain !important;
  border-radius: 0.5208vw;
 }

 .com_live_header{
  width: 2.1875vw;
  height: 2.1875vw;

  border-radius: 100%;
 }

 .com_filter{
  width: 1.0417vw;
  height: 0.625vw;
  margin-right: 0.5208vw;
 }
 .com_filterAd{
  width: 1.0417vw;
  height: 1.625vw;
  margin-right: 0.5208vw;
 }
 .com_live_renshu{
  width: 0.8333vw;
  height: 0.5729vw;
  margin-left: 0.2604vw;
  margin-right: 0.5208vw;
 }

 .com_channel_img{
  border-radius: 2.2917vw;
  width: 8.5833vw;
  height: 4.5833vw;
  object-fit: contain;
  font-size: 0.7292vw;
 }

 .com_channel_member_img{
width: 0.9375vw;
height: 0.9375vw;
margin-right: 0.5208vw;
 }




 .com_trending_box{
  width: 63.1042vw;
 }


 .com_trending_news{

 }

 .com_w100_flex{
  display: flex;
  align-items: center;
  width: 100%;
 }


 .com_flex{
  display: flex;
  align-items: center;
 }


 .com_trending_list{
  width: 65.1042vw;
  border: 0.0521vw solid #EBEBEB;
  border-radius: 0.5208vw;
  padding-left: 0.5208vw;
  padding-top: 0.5208vw;
  padding-right: 0.5208vw;
 }



 .com_live{
   width: 100%;
   min-height: 15.625vw;
   border: 1px solid #EBEBEB;
   border-radius: 0.5208vw;
   padding-left: 1.8229vw;
   padding-right:1.8229vw;
   padding-top: 2.0833vw;
   padding-bottom: 1.8vw;
   margin-right: 2.6042vw;
 }

 .com_live_box{
     cursor: pointer;
   margin-right: 1.0458vw;
   width: 18.75vw;
   height: 12.5vw;
   box-shadow: 0px 4px 6px 0px rgba(207,209,208,0.35);
   margin-bottom: 1.3333vw;
 }

 .com_live_0{
  margin-left: 0.5208vw;
 }

 .com_live_1{
  display: flex;
  margin-top: 0.5208vw;
  height: 2.1875vw;
  padding-left: 1vw;
 }


 .com_live_1_1{
  font-size: 0.8333vw;
font-family: Roboto;
font-weight: 500;
color: #222222;
 }

 .com_live_1_2{
  width: 15vw;
  display: flex;
  color: #808080;
 }

 .com_live_1_2_1{
  display: flex;
  font-size: 0.625vw;
  width: 80%;
 }

 .com_live_team{
  width: 2.3438vw;
height: 0.8333vw;
background: #D1D1D1;
border-radius: 0.3125vw;
display: flex;
align-content: center;
font-size: 0.625vw;
font-family: Roboto;
font-weight: 500;
color: #FFFFFF;
justify-content: center;
 }

 .com_live_1{
  display:flex;
 }

 .com_live_1_1{
  display: flex;
  width: 100%;
 }



 .com_live_0{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
 }

 .com_trending{
  display: flex;
  width: 100%;
  margin-top: 1.0417vw;
  margin-bottom: 2.6042vw;
 }

 .com_trending_tile{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
 }


 .com_list_name{
  font-size: 1.25vw;
  font-family: Roboto;
  font-weight: 500;
  color: #222222;
  cursor:pointer;
 }



 .com_trending_news{
  padding-left: 1.5104vw;
 }



 .com_channel_1{
  display: flex;
  width: 26.0833vw;
  height: 4.5833vw;
  margin-top: 1.208vw;
  cursor: pointer;
  box-shadow: 0px 4px 6px 0px rgba(207,209,208,0.35);
  margin-right: 0.5vw;

 }




 .com_channel_0{
  width: 16.3125vw;
  display: flex;
  flex-wrap: wrap;
  margin-right: 0.5208vw;
 }

 .com_channel_box{
 width: 100%;
  display: flex;
  flex-wrap: wrap;
 }

 .com_channel_join_img{
  width: 3.2292vw;
  height: 0.9375vw;
  cursor: pointer;
 }


 .com_banner_center_img{
  width: 48.9583vw;
  height: 21.875vw;
  object-fit: cover;
  cursor: pointer;
 }

 .com_banner_center_live{
  background-image: url("../../assets/v2/live.png");
  background-size:100% 100%;
  width: 21.875vw;
  height: 21.875vw;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
 }

.com_banner_center_live_1{

  width: 10.8333vw;
  height: 5.1563vw;
}
 .com_banner_left_num{
  font-size: 3.75vw;
  font-family: DIN;
  font-weight: 500;
  color: #FFFFFF;

 }

 .com_banner_left_date{
  font-size: 1.25vw;
  font-family: DIN;
  font-weight: 500;
  color: #FFFFFF;
 }

 .com_banner_left_but{
  width: 2.5vw;
  height: 1.4583vw;
  background: rgba(235, 235, 235, 0.2);
  border-radius: 0.3125vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.6375vw;
  font-family: Roboto;
  font-weight: 500;
  color: #FFFFFF;
  margin-right: 0.2604vw;
 }

 .com_banner_left_tags{
  display: flex;
  align-items: center;
  margin-top: 1.875vw;
 }

 .com_live_1_1_name{
   width: 12.9375vw;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
  font-size: 1.0417vw;
  font-family: Roboto;
  font-weight: 500;
  color: #222222;
 }




 .com_search_img{
  width: 1.25vw;
  height: 1.25vw;
  margin-right: 1.0417vw;
 }



 .com_channel_plateName{
  width: 6.25vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


#community[mode=web] .options_box{
  width: 8.0729vw;
  height: 1.4583vw;
  background: rgba(240, 240, 240,0.5);
  border-radius: 0.2083vw;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

#community[mode=web] .options_box_flex{
  cursor: pointer;
  width: 8.0729vw;
  height: 1.0583vw;
  align-items: center;
  display: flex;
  align-items: center;
  color: rgb(107, 104, 104);
  font-size: 0.7292vw;
  font-family: DIN;
  justify-content: center;
}

#community[mode=web] .options_box_num{
  margin-left: 0.204vw;

}
#community[mode=web] .options_box_flex_border{
  cursor: pointer;
  border-right: 1px solid rgba(240, 240, 240,1);
}


#community[mode="web"] .dis_list_content{
  font-size: 1.05vw;
  font-family: Roboto;
  font-weight: 300;
  color: #595959;
  width: 49.8438vw;
  min-height: 3.1667vw;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}




.mcom_top_box{
  background-image: url("../../assets/mobile/com_bg.png");
  background-size:100% 100%;
  width: 100vw;
  height:87.7333vw ;
 }

 .mcom_but_live_box{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 3.3333vw;
  padding-right: 3.3333vw;
 }



.mcom_live{
  font-size: 4vw;
font-family: Roboto;
font-weight: bold;
color: #222222;
}

.mcom_view{
  width: 17.0667vw;
  height: 6.4vw;
  border: 2px solid #D1D1D1;
  border-radius: 0.8vw;
  font-size: 3.2vw;
font-family: Roboto;
font-weight: bold;
color: #000101;
display: flex;
justify-content: center;
align-items: center;
}


.mcom_live_box{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 3.3333vw;
  padding-right: 3.3333vw;flex-wrap: wrap;
  margin-top: 2.6667vw;

}

.mcom_live_box_name{
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 3.3333vw;
  padding-right: 3.3333vw;
  padding-top: 2.6667vw;
  padding-bottom:4.6667vw;
  border-bottom: 1px  solid #EBEBEB ;
}




.mcom_live_img{
  width: 93.6vw;
  height: 46.8vw;
  border-radius: 1.3333vw
}
.mcom_live_header{
  width: 8.5333vw;
  height:  8.5333vw;
}
.mcom_live_0{
  display: flex;
  flex-wrap: wrap;
  margin-left: 2.6667vw;
}
.mcom_live_1_1{
  font-size: 4.2667vw;
  font-family: Roboto;
  font-weight: bold;
  color: #222222;
  margin-right: 10px;
}

.mcom_live_1_2{
  font-size: 2.9333vw;
  font-family: Roboto;
  font-weight: 400;
  color: #808080;
}



.mcom_but_live_box_but{
  width: 93.8667vw;
  height: 8.8vw;
  background: #EBEBEB;
  border: 2px solid #D1D1D1;
  border-radius: 3.2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.2vw;
  font-family: Roboto;
  font-weight: 500;
  color: #808080;
}


.com_banner_left_img{
  width: 18.75vw;
  height: 21.875vw;
  cursor: pointer;
}