.mperson_donation_box{
  width: 100%;
}

.mcperson_reading{
  width: 100%;
}

.mperson_view_but{
  width: 18.1333vw;
  height: 8.5333vw;
  background: #279D8E;
  border-radius: 1.7333vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.4667vw;
  font-family: Roboto;
  font-weight: 400;
  color: #FFFFFF;
}

.mperson_reading_text{
  font-size: 4vw;
font-family: Roboto;
font-weight: bold;
color: #222222;
}


.mperson_reading_eth{
  font-size: 2.4vw;
  font-family: Roboto;
  font-weight: bold;
  color: #222222;
}


.mperson_reading_list{
  width: 100%;
}

.mperson_reading_item{
  width: 100%;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding-top: 3.7333vw;
  padding-bottom: 2.8vw;
  border-bottom: 1px dotted rgba(235, 235, 235, 1);
}



.mperson_reading_item_line{
  width: 100%;
  display: flex;
  align-items: center;
}

.mperson_reading_item_3{
  font-size: 3.4667vw;
  font-family: Roboto;
  font-weight: 400;
  color: #222222;

}


.mperson_reading_item_5 {
  font-size: 1.625vw;
  font-family: Roboto;
  font-weight: 400;
  width: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  margin-left: 5.3333vw;
}


.mperson_donation_projcet{
  height: 16.1333vw;
  align-items: center;
  border-bottom: 1px solid rgba(235, 235, 235, 1);
}
