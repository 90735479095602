.sign_body{
    width: 100%;
    display: flex;
    background-image: url("../../assets/v2/sign_bg.png");
    background-size:100% 100%; 
    height:1080px ;
}

.sign_left{
    width: 37.5vw;
    cursor: pointer;
}

.sign_right{
    width: 62.5vw;
    padding-top: 7.6042vw;
    padding-left: 7.8125vw;
}

.sign_sign{
    padding-left: 0.5208vw;
    margin-top: 5.4688vw;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 29.1667vw;
    height: 2.5vw;
    border: 1px solid #E0E0E0;
    border-radius: 6px;
    margin-bottom: 1vw;
}
.sign_sign_text{
    font-size: 0.8333vw;
font-family: Roboto;
font-weight: 400;
color: #1A1A1A;
opacity: 0.99;
margin-left: 1.7188vw;
}
.sign_other{
    display: flex;
    margin-top: 4.1667vw;
    cursor: pointer;
}

.sign_create{
    font-size: 1.875vw;
    font-family: Roboto;
    font-weight: 900;
    text-decoration: underline;
    color: #222222;
}

.sign_input{
    display: flex;
    align-items: flex-end;
    background-color: transparent !important;
}




.sign_ds_1{
margin-left: 0.8854vw;
}

.sign_send_but{
    width: 12.1667vw;
    height: 1.8vw;
    background: #3DA16B;
    border-radius: 0.3125vw;
    font-size: 1vw;
    font-family: Roboto;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
     margin-bottom:5px;
    cursor: pointer;

}




.sign_send_f_but_m{
    width: 12vw;
    height: 6.6667vw;
    background: #3DA16B;
    border-radius: 1vw;
    font-family: Roboto;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom:5px;
}



.sign_send_but_m{
    width: 43vw;
    height: 9.6667vw;
    background: #3DA16B;
    border-radius: 1vw;
    font-family: Roboto;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.0833vw;
    cursor: pointer;
    margin-bottom: 1.3333vw;
}


.sign_but{
    width: 29.1667vw;
    height: 2.5vw;
    background: #3DA16B;
    border-radius: 0.3125vw;
    font-size: 1.25vw;
    font-family: Roboto;
    font-weight: bold;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.0833vw;
    cursor: pointer;
}


.sign_or{
    display: flex;
    font-size: 1.875vw;
    font-family: Roboto;
    font-weight: 400;
    color: #ADADAD;
    margin-left: 11.0938vw;
    margin-top: 3.4375vw;
}
.sign_full_name{
    margin-top: 2.4479vw;
}
.sign_full_name,.sign_full_email, .sign_full_password{
  border-bottom: 1px solid #E0E0E0;
  width: 29.1667vw;
  height: 45px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}


.sign_to_login{
    font-size: 0.625vw;
    font-family: Roboto;
    font-weight: 400;
    color: #ADADAD  ;
    margin-top: 0.4208vw;
    width: 29.1667vw;
    display: flex;
}

.sign_to_login_1{
    font-size: 0.8333vw;
    color: #419D41;
    cursor: pointer;
}


.sign_to_login_2{
    font-size: 0.625vw;
    color: #419D41;
    margin-left: 10px;
    margin-top: 0.5208vw;
    cursor: pointer;
}

.sign_to_login_2_m{
    font-size: 2.625vw;
    color: #419D41;
    margin-left: 10px;
    margin-top: 0.5208vw;
    cursor: pointer;
}


.terms_boday_m{
    background: #FFFFFF;
    border-radius: 1.25vw; 
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 1.3021vw;
    padding: 20px;
}


.terms_boday{
    height: 25vw;
    background: #FFFFFF;
    border-radius: 1.25vw; 
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 1.3021vw;
}
.terms_boday_un{
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 1.0417vw;
    font-family: Roboto;
    font-weight: bold;
    color: #212121;
    height: 16.6667vw;
    align-items: center;
}


.terms_boday_ok_m{
    width: 69.3333vw;
    height: 11.7333vw;
    background: #279D8E;
    border-radius: 24px;
    font-size: 1.25vw;
    font-family: Roboto;
    font-weight: bold;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.terms_boday_ok{
    width: 13.0208vw;
    height: 2.5vw;
    background: #3DA16B;
    border-radius: 0.3125vw;
    font-size: 1.25vw;
    font-family: Roboto;
    font-weight: bold;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.terms_boday_tile{
    font-size: 1.875vw;
    font-family: Roboto;
    font-weight: 900;
    color: #222222;
    display: flex;
    align-items: center;
}

.terms_boday_tile_m{
    font-size: 4.875vw;
    font-family: Roboto;
    font-weight: 900;
    color: #222222;
    display: flex;
    align-items: center;
}

.terms_boday_content{
  width: 90%;
  height: 72%;
  overflow: auto;
  font-size: 0.625vw;
}

.merms_boday_content{
    width: 90%;
    height: 72vh;
    overflow: auto;
    font-size: 2.025vw;
  }

.sign_sign_name{
    font-size: 0.8333vw;
    font-family: Roboto;
    font-weight: 400;
    color: #808080;
    margin-top: 2.4479vw;
}

.sign_sign_name_m{
    font-size: 0.8333vw;
    font-family: Roboto;
    font-weight: 400;
    color: #808080;
    margin-top: 2.4479vw;
}


.sign_sign_header{
    width: 8.3333vw;
    height: 8.3333vw;
    border: 1px solid  #3DA16B;
    border-radius: 0.7vw;
}


.sign_sign_header_no{
    width: 21.3333vw;
    height: 21.3333vw;
    border-radius: 0.7vw;
    margin-left: 2.6667vw;
}

.sign_sign_header_box_m{
    width: 17.3333vw;
    height: 17.3333vw;
    background: #F5F5F5;
    border: 2px solid #EBEBEB;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sign_sign_header_box{
    width: 8.3333vw;
    height: 8.3333vw;
    border: 1px solid   #b1a7a7;
    border-radius: 0.7vw;
    display: flex;
    justify-content: center;
    align-items: center;
}


.sign_sign_ok{
    width: 18.75vw;
    height: 2.5vw;
    background: #3DA16B;
    border-radius: 0.3125vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25vw;
    font-family: Roboto;
    font-weight: bold;
    color: #FFFFFF;
    cursor: pointer;
}

.person_upload_file_m{
    width: 37.3333vw;
    height: 8.5333vw;
    background: #279D8E;
    border-radius: 1.6vw;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 400;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1.1458vw;
}


.sign_sign_ok_m{
    width: 58.6667vw;
    height: 11.7333vw;
    background: #279D8E;
    border-radius: 3.2vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-family: Roboto;
    font-weight: bold;
    color: #FFFFFF;
}

.sign_sign_over_m{
    width: 32vw;
    height: 11.7333vw;
    background: #D1D1D1;
    border-radius: 3.2vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-family: Roboto;
    font-weight: bold;
    color: #808080;
    margin-left: 10px;
}



.sign_sign_over{
    width: 9.8958vw;
    height: 2.5vw;
    border: 1px solid #D1D1D1;
    border-radius: 0.3125vw;  
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25vw;
    font-family: Roboto;
    font-weight: bold;
    color: #808080;
    margin-left: 10px;
    cursor: pointer;
}


.sign_right_m{
    width:100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.sign_or_m{
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 1.875vw;
    font-family: Roboto;
    font-weight: 400;
    color: #ADADAD;
    cursor: pointer;
}

.sign_full_name_m{
    width: 98%;
    height: 8vw;
    display: flex;
    align-items:center;
    justify-content: space-between;
    background: #F5F5F5;
        border-radius: 3.2vw;
  }
  


  .sign_but_m{
    width: 93.6vw;
    height: 11.7333vw;
    background: #279D8E;
    border-radius: 3.2vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.0833vw;
    cursor: pointer;
    font-size: 6.4vw;
font-family: Roboto;
font-weight: bold;
color: #FFFFFF;
}



.sign_to_login_m{
    font-size: 2.625vw;
    font-family: Roboto;
    font-weight: 400;
    color: #ADADAD  ;
    display: flex;
    margin-top: 1.3333vw;
    width: 100%;
    text-align: center;
    justify-content: center;
    display: flex;
}


.sign_create_m{
    font-size: 4.2667vw;
    font-family: Roboto;
    font-weight: 900;
    text-decoration: underline;
    color: #222222;
    width: 100%;
    padding-left: 3.2vw;
    padding-top: 20px;
}


.sign_create_name_m{
    font-size: 4.2667vw;
    font-family: Roboto;
    font-weight: 900;
    color: #222222;
    width: 100%;
    padding-left: 3.2vw;
    padding-top: 20px;
}