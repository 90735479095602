.my_post_body_box{
  width: 64.5833vw;
  height: 37.5vw;
  background: #FFFFFF;
  border-radius: 0.5208vw;
  padding-top: 1.5625vw;
  padding-left: 1.5625vw;
}

.my_channel_box{
  margin-top: 1.0417vw;
  width: 64.5833vw;
  min-height: 31.9271vw;
  background: #FFFFFF;
  border-radius: 0.5208vw;
  padding-top: 1.5625vw;
  padding-left: 1.5625vw;
}

.my_live{
  display: flex;
  flex-wrap: wrap;
}

.my_live_1{
  display: flex;
  width: 18.8958vw;
  margin-bottom: 2.0833vw;
}

.my_live_0{
  margin-left: 0.625vw;
}




.my_live_header{
  width: 4.5833vw;
  height: 4.5833vw;
  order: 1px solid #EBEBEB;
  border-radius: 2.2917vw;
}
.my_live_1_1{
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1.0417vw;
font-family: Roboto;
font-weight: 500;
color: #222222;

}

.my_live_1_1_name{
  width: 8.0833vw;
}

.my_live_1_2{
  display: flex;
  height: 4vw;
  align-items:center;
}

.my_live_renshu{
  width: 0.625vw;
  height: 0.625vw;
}

.my_flex{
  display: flex;
  align-items: center;
  font-family: DIN;
  font-weight: 400;
  color: #808080;
  font-size: 0.625vw;
  width: 3.1771vw;
}

.my_live_member{
  width: 1vw;
  height: 1vw;
}

.project_remove_img{
 width: 0.8333vw;
 height: 0.8333vw;
 margin-right: 0.5208vw;
}