.projectListContent{
  display: flex;
  width: 100%;
}

.projectListLeft{
  display: flex;
  width: 100%;
}

.projectListQuery{
  width:  15.5729vw;
  min-height: 1444px;
  border-right: 1px solid  #DFEBD9;
  padding-top: 1.5625vw;
  
}

.projectList{
  width: 50vw;
  padding-left: 2.6563vw;
  padding-right: 1.5104vw;
  padding-top:1.7188vw ;
  border-right: 1px solid  #DFEBD9;
}

.projectListItem{
 display: flex;
 min-height: 8.4896vw;
  border-bottom: 1px solid  #DFEBD9;
  padding-top: 10px;
  padding-bottom: 1.0417vw;
}

.projectListTile{
  display: flex;
  align-items: center;
}



.projectListTiletext{
  font-size: 1.25vw;
font-family: Roboto;
font-weight: bold;
color: #222222;
margin-left: 0.5208vw;
}

.projectList_img{
  width: 1.4583vw;
height: 1.4583vw;
}

.projectListContent_1{
  width: 32.3958vw;
  min-height: 5.4167vw;
  font-size: 0.625vw;
  font-family: Roboto;
  font-weight: 300;
  color: #595959;
  margin-left: 1.9792vw;
  margin-top: 10px;
}

.projectList_conf{
  display: flex;
  align-items: center;
  margin-left: 1.9792vw;
  margin-top: 1.3021vw;
}

.projectListToken{
  font-size: 0.625vw;
  font-family: Roboto;
  font-weight: bold;
  color: #222222;
}

.projectListCreate{
  font-size: 0.625vw;
font-family: DIN;
font-weight: 400;
color: #B3B3B3;
}

.projectListDonate{
  font-size: 0.7292vw;
  font-family: Roboto;
  font-weight: 400;
  text-decoration: underline;
  color: #419D41;
  margin-left: 0.8646vw;
  cursor: pointer;
}

.projectListStep{
  width: 7.375vw;
  height: 0.5208vw;
  background: #F5F5F5;
  border-radius: 5px;
  margin-left: 0.3208vw;
}
.projectList_step_blue{
  height: 0.5208vw;
  background: #52A553;
  border-radius: 10px;
}
.projectListTags{
  min-width: 7.4479vw;
}


.projectListImgBox{
  width: 13.0208vw;
  display: flex;
  justify-content: center;
  align-items: center;
}


.projectListImg{
  width: 9.4792vw;
  background: #F9FAF8;
  border-radius: 0.5208vw;;
}
#projectListContent .adm-tabs-tab-active{
  color: #222222;
  font-weight: bold;
}

#projectListContent .projectListBox{
  padding:0 20px 20px;
}

#projectListContent .cdonate_list_item{
  margin-top: 20px;
}