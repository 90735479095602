/* @font-face {
  font-family: 'title';
  src: url("../../assets/Maragsa.otf");
}
@font-face {
  font-family: 'subtitle';
  src: url("../../assets/Mark_Pro_Bold.ttf");
}
@font-face {
  font-family: 'introduction';
  src: url("../../assets/Mark_Pro.ttf");
} */


@font-face {
  font-family:'Impact';
  src: url("../../assets/Impact.ttf");
}


@font-face {
  font-family:'Roboto';
  src: url("../../assets/Roboto-Medium.ttf");
}




.impact_centent{
  height:"86vh";
  overflow-y:"scroll";
  margin-top:"7vh";
  margin-bottom: "7vh";
}





.button {
  position: relative;
  width: 15vw;
  max-width: 160px;
  font-size: 1vmin;
  text-align: center;
  color: #89FFFD;
}
.button .label {
  position: relative;
  transform: translateY(2px) scaleY(0.75);
}
.button .effect {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50%;
  height: 50%;
  background: radial-gradient(closest-side, rgba(137, 255, 253, 0.33), transparent);
  transform: translate(-50%, -50%);
}
.button img {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  object-fit: cover;
  transform: translate(0, -50%);
}
.blue-shine {
  position: absolute;
  width: 100vh;
  height: 100vh;
  background: radial-gradient(closest-side, #5DF5FC, transparent);
  transform: translate(-50%, -50%);
}
.red-shine {
  position: absolute;
  width: 100vh;
  height: 100vh;
  transform: translate(-50%, -50%);
  background: radial-gradient(closest-side, #F354E4, transparent);
}