.mperson_upload_file{
	width: 37.3333vw;
	height: 8.5333vw;
	background: #279D8E;
	border-radius: 1.6vw;
	font-size: 3.2vw;
	font-family: Roboto;
	font-weight: 400;
	color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 2.6667vw;
}


.mperson_sign_but{
	width: 17.0667vw;
	height: 8.5333vw;
	background: #279D8E;
	border-radius: 1.6vw;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 3.4667vw;
	font-family: Roboto;
	font-weight: bold;
	color: #FFFFFF;
	margin-left: 1.3333vw;
}
