.modal_success{
    width: 33.3333vw;
    height: 33.3333vw;
    background-size: 100% 100%;
    background-image: url("../../assets/v2/channel_success.png");
  
}




.modal_fial{
    width: 33.3333vw;
    height: 33.3333vw;
    background-size: 100% 100%;
    background-image: url("../../assets/v2/channel_fail.png");
}

.modal_fial_but{
    width: 16.6667vw;
    height: 2.5vw;
    background: #E87050;
    border-radius: 0.8333vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.0417vw;
    font-family: Roboto;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 1.5625vw;
    cursor: pointer;
}

.modal_channel_join_img{
    width: 3.2292vw;
    height: 0.9375vw;
    cursor: pointer;
   }

.modal_success_but{
    width: 16.6667vw;
    height: 2.5vw;
    background: #279D8E;
    border-radius: 0.8333vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.0417vw;
    font-family: Roboto;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 1.5625vw;
    cursor: pointer;
}

.modal_success_1{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 18.2292vw;

}

.modal_success_1_1{
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 0.8333vw;
    font-family: Roboto;
    font-weight: 400;
    color: #4D4D4D;
}

.mcom_channel_join_img{
    width: 17.8667vw;
    height: 6.4vw;
    cursor: pointer;
}
  


